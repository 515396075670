import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BehaviorSubject, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { InitiateScanButton } from '../../../components';
import { DisplayString, PartnerKeys } from '../../../constants';
import { CircleCheck, LoadingSpinner, WarningCircle } from '../../../icons';
import { networkScanApi } from '../../../services';
import { InitiateScanButtonPropsV2 } from '../../contracts';
import { ButtonState } from './index';

const ResetButtonStateTimer = 3000; // seconds

const getButtonContent = (
  buttonState: ButtonState,
  buttonText?: DisplayString
): JSX.Element => {
  switch (buttonState) {
    case 'click':
      return (
        <span className="icon-left">
          {buttonText ?? DisplayString.VzwPreScanSendScanNow}
        </span>
      );
    case 'sending':
      return (
        <span className="icon-left">
          <LoadingSpinner />
          {DisplayString.Sending}
        </span>
      );
    case 'sent':
      return (
        <span className="icon-left">
          <CircleCheck />
          {DisplayString.Sent}
        </span>
      );
    case 'error':
      return (
        <span className="icon-left">
          <WarningCircle />
          {DisplayString.SendingFailed}
        </span>
      );
  }
};

interface VerizonInitiateScanButtonPropsV2 extends InitiateScanButtonPropsV2 {
  navigationButtonEnabled$?: BehaviorSubject<boolean>;
}
export const VerizonInitiateScanButtonV2 = ({
  clientId,
  buttonText,
  navigationButtonEnabled$,
  variant,
}: VerizonInitiateScanButtonPropsV2) => {
  const [buttonState, setButtonState] = useState<ButtonState>('click');
  if (buttonState === 'error' || buttonState === 'sent') {
    setTimeout(() => {
      setButtonState('click');
    }, ResetButtonStateTimer);
  }
  const { t: translate } = useTranslation();
  const [tooltipMessage, setTooltipMessage] = useState<string | undefined>(
    undefined
  );
  const onClick = useCallback(() => {
    setTooltipMessage(undefined);
    setButtonState('sending');
  }, []);

  const sendScanRequest$ = useMemo(
    () =>
      networkScanApi.sendScanRequestToCustomer(clientId, PartnerKeys.vzw).pipe(
        tap(() => {
          setButtonState('sent');
          navigationButtonEnabled$?.next(true);
        }),
        catchError((error) => {
          setButtonState('error');
          const additionalMessage =
            error.status === 500
              ? `: ${translate('device_subscription_expired')}`
              : '.';
          setTooltipMessage(`
            <strong>
              ${translate('error_initiating_scan_tooltip')}
              ${additionalMessage}
            </strong>
            <br/>${translate('error_notification_was_not_sent')}
          `);
          return throwError(error);
        })
      ),

    [clientId, navigationButtonEnabled$, translate]
  );

  const ScanButton = () => (
    <InitiateScanButton
      triggerScan$={sendScanRequest$}
      onClick={onClick}
      clientId={clientId}
      tooltipMessage={tooltipMessage}
      disabled={true} // TODO: Temporarily disable this feature while negotiating with Verizon
      variant={variant}
    >
      {getButtonContent(buttonState, buttonText)}
    </InitiateScanButton>
  );

  return (
    <div className="vwz-scan-button">
      <ScanButton />
    </div>
  );
};
