import React, { SVGProps } from 'react';

export const GoodBrowsingIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="34"
      height="34"
      viewBox="0 0 34 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M5.23198 24L4.25098 20H5.25098L5.74398 21.951L6.22298 20H7.25098L7.74398 21.951L8.22298 20H9.25098L8.25098 23.958L8.26198 24H7.23198L6.74398 22.008L6.25098 23.958L6.26198 24H5.23198Z"
        fill="black"
      />
      <path
        d="M9.25098 20H10.251L10.743 21.951L11.222 20H12.25L12.743 21.951L13.222 20H14.25L13.251 23.96L13.261 24H12.232L11.743 22.008L11.251 23.96L11.261 24H10.232L9.25098 20Z"
        fill="black"
      />
      <path
        d="M15.232 24L14.25 20H15.25L15.743 21.951L16.222 20H17.25L17.743 21.951L18.222 20H19.25L18.251 23.96L18.261 24H17.232L16.743 22.008L16.251 23.96L16.261 24H15.232Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.978 16H23C23.553 16 24 16.448 24 17V27C24 27.553 23.553 28 23 28H19.978C18.152 30.424 15.262 32 12 32C8.739 32 5.847 30.424 4.022 28H1C0.448 28 0 27.553 0 27V17C0 16.448 0.448 16 1 16H4.022C5.848 13.577 8.739 12 12 12C15.262 12 18.152 13.577 19.978 16ZM16 16H17.263C16.873 15.659 16.451 15.354 16 15.09V16ZM14 16V14.263C13.36 14.097 12.691 14 12 14C11.309 14 10.641 14.097 10 14.263V16H14ZM8 16V15.09C7.549 15.354 7.128 15.659 6.737 16H8ZM8 28H6.737C7.128 28.344 7.549 28.648 8 28.911V28ZM10 28V29.738C10.641 29.904 11.309 30 12 30C12.691 30 13.36 29.904 14 29.738V28H10ZM16 28V28.911C16.451 28.648 16.873 28.344 17.263 28H16ZM2 26H22V18H2V26Z"
        fill="black"
      />
      <circle className="override-color" cx="24" cy="10" r="10" />
      <path
        className="override-icon-good"
        d="M24 0C18.477 0 14 4.477 14 10C14 15.523 18.477 20 24 20C29.523 20 34 15.523 34 10C34 4.477 29.522 0 24 0ZM23.25 15.75L18.25 12L19.75 10L22.75 12.25L28 5.25L30 6.75L23.25 15.75Z"
      />
    </svg>
  );
};
