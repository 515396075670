import React from 'react';
import { XSvg } from '../../../icons/x';

interface WarningBannerProps {
  parentClass: string;
  displayMessage: string;
  onClick?: () => void;
}

export const WarningBanner = ({
  parentClass,
  displayMessage,
  onClick,
}: WarningBannerProps) => {
  return (
    <div className={`${parentClass}__warning_banner`}>
      {displayMessage}
      <div
        className={`${parentClass}__warning_banner__close_button`}
        onClick={onClick}
      >
        <XSvg />
      </div>
    </div>
  );
};
