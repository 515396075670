import React from 'react';
import { IFormattedClassifiedDevice } from '../../../../utils';
import { getIconSrcByType, WiredIcon } from '../display-icons';
import { Tooltip } from '../../../CommonComponents';
import { WifiGray } from '../../../../icons';
import { BaseImage } from '../../../../atomic-ui';

interface DeviceViewProps {
  device: IFormattedClassifiedDevice;
}
export const DeviceView = ({ device }: DeviceViewProps) => {
  const iconSrc = getIconSrcByType(device.kind);
  const deviceName =
    device.ip !== device.name ? device.name : device.nameCommon;
  const isDeviceWired = String(device.operatingParameters?.wired) === 'true';
  return (
    <div className="device-view">
      <div>
        <BaseImage size={24} src={iconSrc} />
      </div>
      <div className="description">
        <Tooltip message={deviceName} conditionalTooltip={true}>
          <span className="device-name">
            <strong>{deviceName}</strong>
          </span>
        </Tooltip>
        {device.make && (
          <Tooltip conditionalTooltip={true} message={device.make}>
            <span className="device-make">{device.make}</span>
          </Tooltip>
        )}
      </div>
      <div>
        {!isDeviceWired ? (
          <WifiGray rssi={device.rssi || undefined} width={36} height={36} />
        ) : (
          <WiredIcon width={36} height={36} />
        )}
      </div>
    </div>
  );
};
