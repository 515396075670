import React, { SVGProps } from 'react';

export const Modem = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="38px"
      height="38px"
      viewBox="0 0 38 38"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <title>Modem</title>
      <desc>Created with Sketch.</desc>
      <g
        id="Expert-Dashboard-v1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="Iconogrpahy"
          transform="translate(-45.000000, -86.000000)"
          fill="#9100da"
        >
          <g id="Modem" transform="translate(45.000000, 86.000000)">
            <g>
              <path
                d="M36.2355485,27.1305988 L9.95190934,27.1305988 L9.95190934,12.3159403 C11.2461869,11.977062 12.2082903,10.8096342 12.2082903,9.41228794 C12.2082903,7.75129768 10.855241,6.4 9.19209767,6.4 C7.52862962,6.4 6.17558044,7.75129768 6.17558044,9.41228794 C6.17558044,10.8096342 7.13768387,11.977062 8.43196131,12.3159403 L8.43196131,27.1305988 L1.76412682,27.1305988 C0.791632885,27.1305988 0,27.9205583 0,28.8924419 L0,36.2378327 C0,37.2097163 0.791632885,38 1.76412682,38 L36.2355485,38 C37.2083672,38 38,37.2097163 38,36.2378327 L38,28.8924419 C38,27.9205583 37.2083672,27.1305988 36.2355485,27.1305988 L36.2355485,27.1305988 Z M7.69585315,9.41228794 C7.69585315,8.58795425 8.36702014,7.91798038 9.19209767,7.91798038 C10.0168505,7.91798038 10.6883422,8.58795425 10.6883422,9.41228794 C10.6883422,10.2366217 10.0168505,10.9065955 9.19209767,10.9065955 C8.36702014,10.9065955 7.69585315,10.2366217 7.69585315,9.41228794 Z M36.4797273,36.2378327 C36.4797273,36.3720868 36.3703014,36.4816954 36.2355485,36.4816954 L1.76412682,36.4816954 C1.62937388,36.4816954 1.51994803,36.3720868 1.51994803,36.2378327 L1.51994803,28.8924419 C1.51994803,28.7581877 1.62937388,28.6485792 1.76412682,28.6485792 L36.2355485,28.6485792 C36.3703014,28.6485792 36.4797273,28.7581877 36.4797273,28.8924419 L36.4797273,36.2378327 Z"
                id="Shape"
                fillRule="nonzero"
              ></path>
              <path
                d="M34.2549673,31.8 L14.2892104,31.8 C13.8693576,31.8 13.5287958,32.1578723 13.5287958,32.600171 C13.5287958,33.0421278 13.8693576,33.4 14.2892104,33.4 L34.2549673,33.4 C34.6751451,33.4 35.0157068,33.0421278 35.0157068,32.600171 C35.0157068,32.1578723 34.6751451,31.8 34.2549673,31.8 L34.2549673,31.8 Z"
                id="Path"
                fillRule="nonzero"
              ></path>
              <path
                d="M3.02432167,32.0234118 C2.87338612,32.1757928 2.78534031,32.3859616 2.78534031,32.5950984 C2.78534031,32.6996668 2.80947635,32.8042353 2.84925,32.9008922 C2.88936348,32.9975492 2.94477456,33.0862947 3.02432167,33.1588735 C3.16777856,33.3112545 3.36664644,33.4 3.58115183,33.4 C3.79599715,33.4 3.99486503,33.3112545 4.1464806,33.1588735 C4.21786903,33.0862947 4.28177872,32.9975492 4.32121235,32.9008922 C4.36132592,32.8042353 4.37696335,32.6996668 4.37696335,32.5950984 C4.37696335,32.3859616 4.29741623,32.1757928 4.1464806,32.0234118 C3.85208808,31.7255294 3.31905412,31.7255294 3.02432167,32.0234118 Z"
                id="Path"
                fillRule="nonzero"
              ></path>
              <path
                d="M6.39841125,32.0234118 C6.32632749,32.103558 6.27090466,32.1847361 6.23078252,32.2893045 C6.19100047,32.3859616 6.16753927,32.49053 6.16753927,32.5950984 C6.16753927,32.6996668 6.19100047,32.8042353 6.23078252,32.9008922 C6.27090466,32.9975492 6.32632749,33.0862947 6.39841125,33.1588735 C6.47763545,33.2390198 6.56535998,33.2954316 6.66056495,33.3356767 C6.75610992,33.3755778 6.85947531,33.4 6.9631807,33.4 C7.17807191,33.4 7.37698236,33.3112545 7.52829023,33.1588735 C7.59969392,33.0862947 7.65545691,32.9975492 7.69523897,32.9008922 C7.73502102,32.8042353 7.7591623,32.6996668 7.7591623,32.5950984 C7.7591623,32.3859616 7.67143768,32.1757928 7.52829023,32.0234118 C7.22567449,31.7255294 6.69252666,31.7255294 6.39841125,32.0234118 Z"
                id="Path"
                fillRule="nonzero"
              ></path>
              <path
                d="M9.78061029,32.0234118 C9.62930233,32.1757928 9.54973822,32.3859616 9.54973822,32.5950984 C9.54973822,32.6996668 9.56571908,32.8042353 9.60516105,32.9008922 C9.64528318,32.9975492 9.7007061,33.0862947 9.78061029,33.1588735 C9.85201397,33.2390198 9.93939851,33.2954316 10.0349435,33.3356767 C10.1304885,33.3755778 10.2338539,33.4 10.3453797,33.4 C10.5521105,33.4 10.7595213,33.3112545 10.9023288,33.1588735 C10.981893,33.0862947 11.0379959,32.9975492 11.0777779,32.9008922 C11.11722,32.8042353 11.1413613,32.6996668 11.1413613,32.5950984 C11.1413613,32.3859616 11.0539767,32.1757928 10.9023288,32.0234118 C10.6082134,31.7255294 10.0747256,31.7255294 9.78061029,32.0234118 Z"
                id="Path"
                fillRule="nonzero"
              ></path>
              <path
                d="M20.596405,9.40004068 C20.596405,12.4629305 19.4360002,15.3399243 17.3277619,17.4993788 C17.0373429,17.7969427 17.0373429,18.2791004 17.3277619,18.5769899 C17.4732891,18.7257719 17.6633007,18.8 17.8536299,18.8 C18.0439592,18.8 18.2342885,18.7257719 18.3794979,18.5769899 C20.7686229,16.1294128 22.0837696,12.8698834 22.0837696,9.40004068 C22.0837696,5.93019808 20.7686229,2.67066866 18.3794979,0.223417121 C18.089079,-0.0744723738 17.6184986,-0.0744723738 17.3277619,0.223417121 C17.0373429,0.520980992 17.0373429,1.00313869 17.3277619,1.30070264 C19.4360002,3.46048268 20.596405,6.33715087 20.596405,9.40004068 L20.596405,9.40004068 Z"
                id="Path"
                fillRule="nonzero"
              ></path>
              <path
                d="M17.5852286,9.40024265 C17.5852286,11.6343935 16.7176336,13.7320039 15.143217,15.3070722 C14.8475489,15.6028019 14.8475489,16.0823112 15.143217,16.378041 C15.2910511,16.5259058 15.4848205,16.6 15.6785898,16.6 C15.8723592,16.6 16.0661285,16.5259058 16.2139627,16.378041 C18.0746662,14.5172732 19.0994764,12.0391614 19.0994764,9.40024265 C19.0994764,6.76164748 18.0746662,4.28321214 16.2139627,2.42179728 C15.917971,2.12606757 15.4388851,2.12606757 15.143217,2.42179728 C14.8475489,2.71752706 14.8475489,3.19703629 15.143217,3.49276608 C16.7176336,5.06783433 17.5852286,7.16609183 17.5852286,9.40024265 Z"
                id="Path"
                fillRule="nonzero"
              ></path>
              <path
                d="M12.9607681,14.3761863 C13.1129562,14.5256129 13.3116648,14.6 13.5107056,14.6 C13.7097465,14.6 13.9087873,14.5256129 14.0606432,14.3761863 C15.3858099,13.0757177 16.1151832,11.3432821 16.1151832,9.49991843 C16.1151832,7.65590224 15.3858099,5.92411917 14.0606432,4.62365061 C13.7569315,4.3254498 13.2648122,4.3254498 12.9607681,4.62365061 C12.6570564,4.92185135 12.6570564,5.40504099 12.9607681,5.70356805 C13.9918594,6.71529736 14.5597406,8.06372611 14.5597406,9.49991843 C14.5597406,10.9354583 13.9918594,12.283887 12.9607681,13.2965951 C12.6570564,13.5947959 12.6570564,14.0779855 12.9607681,14.3761863 Z"
                id="Path"
                fillRule="nonzero"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
