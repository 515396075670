import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { HeaderV2, RoutePaths } from '../../components';
import {
  ResultsPageQuestionSection,
  ResultsPageScanSection,
} from '../../components/v2/ResultsPageSections';
import { Chevron } from '../../icons/Chevron';
import { useEffectOnce, useRouter, useRxApi } from '../../hooks';
import { ScanningPageComponent } from '../../components/v2/ScanningPageComponent/index';
import { BehaviorSubject } from 'rxjs';

export const enum ResultsPageState {
  ScanInstructions = 'scan-instructions',
  Scanning = 'scanning',
  ScanResults = 'scan-results',
  ScanFailed = 'scan-failed',
  Unknown = 'unknown',
}

const resultsPageState$ = new BehaviorSubject<ResultsPageState>(
  ResultsPageState.Unknown
);

export const ResultsPageV2 = React.memo(() => {
  const router = useRouter();

  // TODO: should set resultsPageState to whatever path we cam in on & then
  const { result: resultsPageState } = useRxApi(resultsPageState$, {
    initialValue: ResultsPageState.Unknown,
    onNext: (result) => {
      const loadedState: ResultsPageState =
        (router.pathname.replace('/', '') as ResultsPageState) ||
        ResultsPageState.Unknown;

      if (
        result === ResultsPageState.Unknown &&
        loadedState !== ResultsPageState.Unknown
      ) {
        resultsPageState$.next(loadedState);
      }

      if (
        result !== ResultsPageState.Unknown &&
        router.pathname !== RoutePaths.ScanResults
      ) {
        router.push({ pathname: RoutePaths.ScanResults });
      }
    },
  });

  // Right side either shows ScanResults or Loading
  const RightColumn = () =>
    resultsPageState === ResultsPageState.ScanResults ? (
      <ResultsPageScanSection setResultsPageState={setResultsPageState} />
    ) : (
      <ScanningPageComponent
        resultsPageState={resultsPageState}
        setResultsPageState={setResultsPageState}
      />
    );

  const setResultsPageState = (state: ResultsPageState) =>
    resultsPageState$.next(state);

  return (
    <div className="parent-container">
      <HeaderV2 />
      <div className="grid-container">
        <div className="grid-results-column">
          <ResultsPageQuestionSection
            resultsPageState={resultsPageState}
            setResultsPageState={setResultsPageState}
          />
        </div>
        <div className="grid-results-column scan-results-column">
          <RightColumn />
        </div>
      </div>
    </div>
  );
});
