import React from 'react';
import { PartnerKeyProperties, PartnerKeys } from '../../../constants';
import {
  ContainerSteps,
  PreScanContentContainer,
  RoutePaths,
} from '../../../components';
import { useEffectOnce } from '../../../hooks';
import { ScanInstructionPropsV2 } from '../../contracts';

const VzwScanStartBadge = require('../../../img/default/vzw/start-checkup-badge.png');
const VzwScanStartBanner = require('../../../img/default/vzw/start-checkup-banner.png');

export const VerizonScanInstructionContentV2 = ({
  navigationProps$,
  navigationButtonEnabled$,
}: ScanInstructionPropsV2) => {
  useEffectOnce(() => {
    navigationButtonEnabled$.next(true);
  });

  const contentSteps: ContainerSteps[] = [
    {
      contentHtml: `Confirm the <strong>${
        PartnerKeyProperties[PartnerKeys.vzw].appName
      } app </strong> has been downloaded.`,
    },
    {
      contentHtml: `Direct the customer to open the ${
        PartnerKeyProperties[PartnerKeys.vzw].appName
      } app.`,
      imageSrc: VzwScanStartBanner,
      imageAltText: `Banner displaying a start scan button`,
    },
    {
      contentHtml: `
        Instruct the customer to tap <strong>Check my Wi-Fi</strong>. The scan should take no more than 2 minutes.
      `,
      imageSrc: VzwScanStartBadge,
      imageWidth: '110px',
      imageAltText: `Badge showing a "Check my Wi-Fi" button`,
    },
  ];

  return <PreScanContentContainer v2={true} steps={contentSteps} />;
};
