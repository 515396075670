const broadbandKeys = [
  'altice',
  'at&t',
  'bright house',
  'cable one',
  'centurylink',
  'charter',
  'consolidated',
  'cox',
  'earthink',
  'fairpoint',
  'frontier',
  'mediacom',
  'spectrum',
  'stealth',
  'surewest',
  'tds',
  'verizon',
  'windstream',
  'xfinity',
  'comcast',
] as const;

type BroadbandKey = typeof broadbandKeys[number];

interface IBroadbandLogo {
  darkLogoSrc: string;
  lightLogoSrc: string;
}

class BroadbandUtil {
  private broadbandLogoMap: Record<BroadbandKey, IBroadbandLogo>;

  constructor() {
    this.broadbandLogoMap = {
      altice: {
        darkLogoSrc: require('../../../img/default/isp-logos/altice-logo-dark.png'),
        lightLogoSrc: require('../../../img/default/isp-logos/altice-logo.png'),
      },
      'at&t': {
        darkLogoSrc: require('../../../img/default/isp-logos/att-logo-dark.png'),
        lightLogoSrc: require('../../../img/default/isp-logos/att-logo.png'),
      },
      'bright house': {
        darkLogoSrc: require('../../../img/default/isp-logos/bright-house-networks-logo-dark.png'),
        lightLogoSrc: require('../../../img/default/isp-logos/bright-house-networks-logo.png'),
      },
      'cable one': {
        darkLogoSrc: require('../../../img/default/isp-logos/cable-one-logo-dark.png'),
        lightLogoSrc: require('../../../img/default/isp-logos/cable-one-logo.png'),
      },
      centurylink: {
        darkLogoSrc: require('../../../img/default/isp-logos/century-link-logo-dark.png'),
        lightLogoSrc: require('../../../img/default/isp-logos/century-link-logo.png'),
      },
      charter: {
        darkLogoSrc: require('../../../img/default/isp-logos/charter-communications-logo-dark.png'),
        lightLogoSrc: require('../../../img/default/isp-logos/charter-communications-logo.png'),
      },
      consolidated: {
        darkLogoSrc: require('../../../img/default/isp-logos/consolidated-comm-logo-dark.png'),
        lightLogoSrc: require('../../../img/default/isp-logos/consolidated-comm-logo.png'),
      },
      cox: {
        darkLogoSrc: require('../../../img/default/isp-logos/cox-logo-dark.png'),
        lightLogoSrc: require('../../../img/default/isp-logos/cox-logo.png'),
      },
      earthink: {
        darkLogoSrc: require('../../../img/default/isp-logos/earthink-logo-dark.png'),
        lightLogoSrc: require('../../../img/default/isp-logos/earthink-logo.png'),
      },
      fairpoint: {
        darkLogoSrc: require('../../../img/default/isp-logos/fairpoint-communications-logo-dark.png'),
        lightLogoSrc: require('../../../img/default/isp-logos/fairpoint-communications-logo.png'),
      },
      frontier: {
        darkLogoSrc: require('../../../img/default/isp-logos/frontier-comm-logo-dark.png'),
        lightLogoSrc: require('../../../img/default/isp-logos/frontier-comm-logo.png'),
      },
      mediacom: {
        darkLogoSrc: require('../../../img/default/isp-logos/mediacom-logo-dark.png'),
        lightLogoSrc: require('../../../img/default/isp-logos/mediacom-logo.png'),
      },
      spectrum: {
        darkLogoSrc: require('../../../img/default/isp-logos/spectrum-logo-dark.png'),
        lightLogoSrc: require('../../../img/default/isp-logos/spectrum-logo.png'),
      },
      stealth: {
        darkLogoSrc: require('../../../img/default/isp-logos/stealth-logo-dark.png'),
        lightLogoSrc: require('../../../img/default/isp-logos/stealth-logo.png'),
      },
      surewest: {
        darkLogoSrc: require('../../../img/default/isp-logos/sure-west-logo-dark.png'),
        lightLogoSrc: require('../../../img/default/isp-logos/sure-west-logo.png'),
      },
      tds: {
        darkLogoSrc: require('../../../img/default/isp-logos/tdstelecom-logo-dark.png'),
        lightLogoSrc: require('../../../img/default/isp-logos/tdstelecom-logo.png'),
      },
      verizon: {
        darkLogoSrc: require('../../../img/default/isp-logos/verizon-logo-dark.png'),
        lightLogoSrc: require('../../../img/default/isp-logos/verizon-logo.png'),
      },
      windstream: {
        darkLogoSrc: require('../../../img/default/isp-logos/windstream-logo-dark.png'),
        lightLogoSrc: require('../../../img/default/isp-logos/windstream-logo.png'),
      },
      xfinity: {
        darkLogoSrc: require('../../../img/default/isp-logos/xfinity-logo-dark.png'),
        lightLogoSrc: require('../../../img/default/isp-logos/xfinity-logo.png'),
      },
      comcast: {
        darkLogoSrc: require('../../../img/default/isp-logos/xfinity-logo-dark.png'),
        lightLogoSrc: require('../../../img/default/isp-logos/xfinity-logo.png'),
      },
    };
  }

  /**
   * Attempts to find a matching logo by an ISP Name
   *
   * @param {(string | BroadbandKey)} name Given ISP Name returned from the server
   * @param {boolean} [requestDarkLogo=false] Whether or not the darkmode logo is required.
   * @returns {(string | null)} Either the src for the image or null.
   * @memberof BroadbandUtil
   */
  public getLogo(
    name: string | BroadbandKey,
    requestDarkLogo = false
  ): string | null {
    if (this.broadbandLogoMap[name]) {
      const value: IBroadbandLogo = this.broadbandLogoMap[name];
      return requestDarkLogo ? value.darkLogoSrc : value.lightLogoSrc;
    }
    let imageSrc: string | null = null;
    for (const key of broadbandKeys) {
      if (
        name.replace(/ /g, '').toLowerCase().includes(key.replace(/ /g, ''))
      ) {
        const value: IBroadbandLogo = this.broadbandLogoMap[key];
        imageSrc = requestDarkLogo ? value.darkLogoSrc : value.lightLogoSrc;
        break;
      }
    }
    return imageSrc;
  }
}

export const broadbandUtil = new BroadbandUtil();
