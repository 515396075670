import React from 'react';
import { Accordion } from '../../../atomic-ui';

interface WifiWizardIssueProps {
  title: string;
  description?: string;
}

export const WifiWizardIssue = ({
  title,
  description,
}: WifiWizardIssueProps) => {
  const IssueContent = () => {
    const titleStyle = {
      display: 'block',
      padding: '0.75rem 0',
    };

    if (!description) {
      return (
        <h5 className="wifi-wizard-subheading" style={titleStyle}>
          Likely issue:&nbsp;
          <b>{title}</b>
        </h5>
      );
    }

    return (
      <Accordion
        className="wifi-wizard-accordion"
        label={`Likely issue: ${title}`}
        initiallyExpanded={true}
      >
        <p>{description}</p>
      </Accordion>
    );
  };

  return (
    <div className="wifi-wizard-issue border-bottom">
      <IssueContent />
    </div>
  );
};
