import React from 'react';

export const HuluLogo = () => (
  <svg
    width="64"
    height="21"
    className="device-icons"
    viewBox="0 0 64 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M63.9976 6.49721H58.9013V14.8966C58.9013 15.2759 58.7538 15.5981 58.517 15.8619C58.2789 16.0965 57.9531 16.2134 57.5695 16.2419H54.6072C54.1922 16.2127 53.8664 16.0958 53.629 15.8619C53.3916 15.5989 53.244 15.2773 53.244 14.8966V6.49721H48.1485V15.3358C48.1784 16.5072 48.3859 17.5303 48.8899 18.3495C49.3926 19.1694 50.0442 19.7839 50.9033 20.1932C51.7931 20.6024 52.7998 20.8377 53.9541 20.8377H58.9028V20.7792C59.9693 20.7792 60.8869 20.5454 61.6576 20.1062C62.4048 19.6412 63.0017 18.9703 63.3766 18.1741C63.7901 17.3549 63.9976 16.3881 63.9976 15.3351V6.49721ZM13.0081 7.02408C12.119 6.64407 11.1416 6.46797 10.075 6.49721H6.78609L5.51203 6.64407C5.21543 6.73177 5.06714 6.79023 5.06714 6.79023V0H0.0015564V20.8384H5.06785V12.4091C5.0978 12.0576 5.21615 11.7068 5.48279 11.473C5.74944 11.2391 6.04603 11.0929 6.46026 11.0929H9.42333C9.77909 11.0929 10.1049 11.2405 10.3716 11.473C10.6382 11.7075 10.7566 12.0583 10.7865 12.4091V20.8384H15.8528V11.7653C15.8528 10.5062 15.5862 9.48237 15.0828 8.6917C14.5488 7.93097 13.8672 7.37557 13.0081 7.02408V7.02408ZM30.251 14.8973C30.2211 15.2766 30.1027 15.5981 29.866 15.8626C29.5994 16.0965 29.2736 16.2134 28.8886 16.2419H25.9255C25.5405 16.2127 25.2147 16.0958 24.9773 15.8626C24.7106 15.5989 24.5923 15.2773 24.5623 14.8973V6.49721H19.496V15.3358C19.496 16.5072 19.7334 17.5303 20.2076 18.3495C20.6817 19.1694 21.3626 19.7839 22.2224 20.1932C23.1114 20.6024 24.0889 20.8377 25.2739 20.8377H30.251V20.7792C31.3176 20.7792 32.2366 20.5454 32.9774 20.1062C33.7258 19.6343 34.3302 18.9659 34.7249 18.1741C35.1099 17.3549 35.318 16.3881 35.3473 15.3351V6.49721H30.251V14.8973ZM39.1987 20.8384H44.265V0H39.1987V20.8384V20.8384Z"
      fill="black"
    />
  </svg>
);
