import React, { useState } from 'react';
import { WifiWizardSolution } from './index';
import { IWifiWizardSolution } from '../../../services/index';
import { FlexDirectionProperty } from 'csstype';
import { BaseButton } from '../../index';
import {
  analytics,
  DashboardEventType,
  UserAnalyticEvent,
} from '../../../utils';
import { ArrowHorizontalLeft } from '../../../icons';
import { FeedbackRating } from './WifiWizardSolution';

interface WifiWizardSolutionListProps {
  solutions: IWifiWizardSolution[];
  onFeedbackSubmitted?: () => void;
}

/**
 * Given a list of recommendations, this component allows for scrolling through
 * them.
 */
export const WifiWizardSolutionList = ({
  solutions,
  onFeedbackSubmitted = () => null,
}: WifiWizardSolutionListProps) => {
  const [solutionIndex, setSolutionIndex] = useState(0);

  const shouldScroll = solutions.length > 1;
  const onScroll = (direction: number) => {
    if (solutionIndex + direction >= solutions.length) {
      setSolutionIndex(0);
    } else if (solutionIndex + direction < 0) {
      setSolutionIndex(solutions.length - 1);
    } else {
      setSolutionIndex(solutionIndex + direction);
    }
  };

  // TODO: think about how the height changes as we scroll through
  // the different solutions
  const SolutionScrollButtons = () => {
    const scrollDivStyle = {
      display: 'flex',
      flexDirection: 'row' as FlexDirectionProperty,
      justifyContent: 'space-between',
      alignItems: 'center',
      margin: '0.75rem 0',
    };
    return (
      <div style={scrollDivStyle}>
        <BaseButton
          variant="primary-ghost"
          analyticName={UserAnalyticEvent.PreviousSolution}
          onClick={onScroll.bind(null, -1)}
          className="recommendation-button"
        >
          <ArrowHorizontalLeft />
          &nbsp;Back
        </BaseButton>
        <BaseButton
          variant="primary-ghost"
          analyticName={UserAnalyticEvent.NextSolution}
          onClick={onScroll.bind(null, 1)}
          className="recommendation-button"
        >
          Next&nbsp;
          <ArrowHorizontalLeft style={{ transform: 'rotate(180deg)' }} />
        </BaseButton>
      </div>
    );
  };

  /**
   * Sort solutions based on rank
   */
  const solutionsSortedByRank = () => {
    const solutionsList = solutions.slice();
    solutionsList.sort((a, b) => a.rank - b.rank);
    return solutionsList;
  };

  const solutionsByRank = solutionsSortedByRank();

  const feedbackWasSubmitted = (rating: FeedbackRating) => {
    analytics.dispatch(
      UserAnalyticEvent.RecommendationFeedback,
      DashboardEventType.Business,
      {
        rating: rating,
        recommendationId: solutions[solutionIndex].id,
        recommendationRank: solutions[solutionIndex].rank,
      }
    );
    onFeedbackSubmitted();
  };

  return (
    <>
      <div className="wifi-wizard-solutions-heading">
        <h5 className="wifi-wizard-subheading">
          <b>
            {`Recommended solution ${solutionIndex + 1} of ${
              solutionsByRank.length
            }`}
          </b>
        </h5>
      </div>
      {solutionsByRank.map((solution, i) => (
        <WifiWizardSolution
          key={solution.title}
          title={solution.title}
          description={solution.description}
          helixArticle={solution.helixArticle}
          isShowing={solutionIndex === i}
          onFeedbackSubmitted={feedbackWasSubmitted}
        />
      ))}
      {shouldScroll && <SolutionScrollButtons />}
    </>
  );
};
