import React, { SVGProps } from 'react';

export const Paperplane = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="paper-plane"
      {...props}
    >
      <path d="M21.7383 11C22.2263 11 22.6433 10.648 22.7243 10.166C22.8053 9.68601 22.5263 9.21601 22.0653 9.05701L4.0653 2.90101C3.6683 2.76301 3.2263 2.88901 2.9623 3.21501C2.6973 3.54001 2.6643 3.99901 2.8813 4.35901L6.5743 10.514C6.7553 10.815 7.0803 11 7.4313 11H21.7383Z" />
      <path d="M7.43123 13C7.07923 13 6.75423 13.186 6.57423 13.484L2.88023 19.641C2.66423 20 2.69723 20.459 2.96223 20.785C3.22723 21.111 3.66823 21.236 4.06623 21.099L22.0662 14.945C22.5272 14.785 22.8062 14.315 22.7252 13.834C22.6442 13.351 22.2272 13 21.7382 13H7.43123Z" />
    </svg>
  );
};
