import React, { useMemo } from 'react';
import { of } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { InitiateScanButton } from '../../components';
import { useRouter } from '../../hooks';
import { ResultsPageState } from '../../pages';
import { InitiateScanButtonPropsV2 } from '../contracts';

export const HomeplusInitiateScanButtonV2 = ({
  clientId,
  buttonText,
  setResultsPageState,
}: InitiateScanButtonPropsV2) => {
  const router = useRouter();
  const triggerScanAction$ = useMemo(
    () =>
      of({}).pipe(
        finalize(() => setResultsPageState?.(ResultsPageState.Scanning))
      ),
    [setResultsPageState]
  );
  return (
    <InitiateScanButton
      buttonText={buttonText}
      triggerScan$={triggerScanAction$}
      clientId={clientId}
    />
  );
};
