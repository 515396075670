import React from 'react';
import { map } from 'rxjs/operators';
import { useTranslation } from 'react-i18next';
import { useRxApi } from '../../../hooks';
import { homeGraphApi, themeApi } from '../../../page-api';
import { broadbandUtil } from './broadband.util';
import { FlexDirectionProperty, FontWeightProperty } from 'csstype';

export const InternetServiceProviderWrapper = ({
  style,
}: React.HTMLAttributes<HTMLDivElement>) => {
  // TODO: should we move this to a prop?
  const { result: isDarkMode } = useRxApi(
    themeApi.currentTheme$.pipe(map(() => themeApi.isDarkMode)),
    {
      initialValue: false,
    }
  );

  const { result: ispName } = useRxApi(homeGraphApi.ispData$, {
    initialValue: null,
  });

  return (
    <InternetServiceProvider
      isDarkMode={isDarkMode}
      ispName={ispName}
      style={style}
    />
  );
};

interface InternetServiceProviderProps
  extends React.HTMLAttributes<HTMLDivElement> {
  isDarkMode?: boolean;
  ispName: string | null | undefined;
}

export const InternetServiceProvider = ({
  isDarkMode,
  ispName,
  style,
}: InternetServiceProviderProps) => {
  const { t: translateText } = useTranslation();

  const imageSrc = ispName ? broadbandUtil.getLogo(ispName, isDarkMode) : null;

  const headingStyle = {
    fontSize: `${7 / 8}em`,
    fontWeight: 'bold' as FontWeightProperty,
    margin: '0',
  };

  const ispLogoStyle = {
    backgroundSize: 'contain',
    backgroundPosition: '50% 50%',
    backgroundRepeat: 'no-repeat',
    height: '50px',
    width: '80px',
    marginRight: '1rem',
    backgroundImage: `url(${imageSrc})`,
  };

  const ispNameStyle = {
    color: 'var(--dark-grey)',
    fontSize: `${7 / 8}em`,
    margin: '0',
  };

  const innerDivStyle = {
    display: 'flex',
    flexDirection: 'row' as FlexDirectionProperty,
    justifyContent: 'start',
    alignItems: 'center',
  };

  if (!ispName) {
    return <></>;
  }

  return (
    <div style={style}>
      <h2 style={headingStyle}>{translateText('internet_service_provider')}</h2>
      <div style={innerDivStyle}>
        {imageSrc && <div style={ispLogoStyle} />}
        <span style={ispNameStyle}>{ispName}</span>
      </div>
    </div>
  );
};
