import React, { ReactNode } from 'react';
import { Paragraph as AtomicParagraph } from '@npmsoluto/atomic-ui-library-react';

interface ParagraphProps
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLParagraphElement>,
    HTMLParagraphElement
  > {
  children: ReactNode;
}

export const Paragraph = ({ children, ...props }: ParagraphProps) => {
  return <AtomicParagraph {...props}>{children}</AtomicParagraph>;
};
