import { catchError, tap } from 'rxjs/operators';
import { MonoTypeOperatorFunction, OperatorFunction, throwError } from 'rxjs';
import { DashboardEventType, UserAnalyticEvent } from './analytics-constants';
import { analytics } from './analytic-writers';

export const dispatchAnalyticEvent = <TData>(
  analyticEvent: UserAnalyticEvent,
  eventType: DashboardEventType,
  customPropsCallback?: (result: TData) => Record<string, string>
): MonoTypeOperatorFunction<TData> =>
  tap((result) => {
    analytics.dispatch(
      analyticEvent,
      eventType,
      customPropsCallback?.(result) ?? {}
    );
  });

export const dispatchAnalyticEventOnError = <TData>(
  analyticEvent: UserAnalyticEvent,
  eventType: DashboardEventType,
  customPropsCallback?: (response: TData) => Record<string, string>
): OperatorFunction<TData, TData> =>
  catchError((result) => {
    analytics.dispatch(
      analyticEvent,
      eventType,
      customPropsCallback?.(result) ?? {}
    );
    return throwError(result);
  });
