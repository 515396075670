import { forkJoin, Observable, of } from 'rxjs';
import { first, tap } from 'rxjs/operators';
import { PartnerKeys } from '../constants';
import { ContentfulApi, IContentfulExpertTip, logger } from '../utils';

class ContentCacheApi {
  private contentfulApi: ContentfulApi;
  private dataCache: Record<string, any> = {};

  constructor() {
    this.contentfulApi = new ContentfulApi();
  }

  /**
   * This will front load all the contentful queries and cache the data
   *
   * @param {PartnerKeys} partnerKey The partner to grab content for.
   * @memberof ContentCacheApi
   */
  public loadContentfulDataForDashboardResults(partnerKey: PartnerKeys): void {
    const obs$ = forkJoin([
      this.getExpertTips(partnerKey), // load the tips in for the experts
    ]).pipe(first()); // take the first one and stop the subscription
    obs$.subscribe({
      next: () => logger.info('successfully loaded'),
      error: (error) => logger.error('failed to load data', error),
    });
  }

  /**
   * This will wrap the expert tips and will cache them so we don't requery something that won't
   * be changing when the expert is on the page.
   *
   * @param {PartnerKeys} partnerKey All tips are instrumented
   * @returns {Observable<Record<string, IContentfulExpertTip>>}
   * @memberof ContentCacheApi
   */
  public getExpertTips(
    partnerKey: PartnerKeys
  ): Observable<Record<string, IContentfulExpertTip>> {
    const cacheKey = `${partnerKey}-expertTips`;
    if (this.dataCache[cacheKey]) {
      return of(this.dataCache[cacheKey]);
    }
    return this.contentfulApi.getExpertTips(partnerKey).pipe(
      tap((tipResponse) => {
        this.dataCache[cacheKey] = tipResponse;
      })
    );
  }
}

export const contentCacheApi = new ContentCacheApi();
