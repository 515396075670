import React from 'react';
import { BaseCheckbox } from '../../CommonComponents';
import { RadioButton } from '@npmsoluto/atomic-ui-library-react';

interface AnswerProps {
  displayText?: string;
  selected?: boolean;
  onSelect?: () => void;
  type?: 'radio' | 'checkbox';
}

export const QuestionAnswer = ({
  displayText = 'This is an answer choice',
  selected = false,
  onSelect = () => {},
  type = 'radio',
}: AnswerProps) => {
  if (type === 'checkbox') {
    return (
      <div className="question-answer" onClick={onSelect}>
        <BaseCheckbox checked={selected} onToggle={onSelect} /> {displayText}
      </div>
    );
  } else {
    return (
      <div className="question-answer" onClick={onSelect}>
        <RadioButton checked={selected} onToggle={onSelect}>
          {displayText}
        </RadioButton>
      </div>
    );
  }
};
