import React, { SVGProps } from 'react';

export const Wired = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <title>wired</title>
      <desc>Created with Sketch.</desc>
      <g
        id="Expert-Dashboard-v1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="Iconogrpahy"
          transform="translate(-296.000000, -568.000000)"
          fill="#9100da"
        >
          <g id="wired" transform="translate(296.000000, 568.000000)">
            <g>
              <path
                d="M19.4500781,2.30484211 L18.3296719,1.18661988 C18.17325,1.03050292 17.9204531,1.03050292 17.7640781,1.18661988 L15.5232188,3.42311111 C15.4480313,3.49777778 15.4060313,3.59957895 15.4060313,3.70535673 C15.4060313,3.8111345 15.4480313,3.9125614 15.5232188,3.98760234 L16.643625,5.10582456 C16.721625,5.18367251 16.8240469,5.22278363 16.9264219,5.22278363 C17.0287969,5.22278363 17.1312187,5.18367251 17.2092188,5.10582456 L19.4500781,2.86933333 C19.5252656,2.79466667 19.5672656,2.6928655 19.5672656,2.58708772 C19.5672656,2.48130994 19.5252656,2.37988304 19.4500781,2.30484211 Z M16.9264219,4.25904094 L16.3716094,3.70530994 L18.046875,2.03335673 L18.6016875,2.58708772 L16.9264219,4.25904094 Z"
                id="Shape"
                fillRule="nonzero"
              ></path>
              <path
                d="M20.5704844,3.42306433 L19.4500781,2.30484211 C19.3752656,2.22980117 19.2732656,2.18788304 19.1672813,2.18788304 C19.0612969,2.18788304 18.9596719,2.22980117 18.8844844,2.30484211 L16.643625,4.54128655 C16.4872031,4.69740351 16.4872031,4.9497076 16.643625,5.10577778 L17.7640313,6.224 C17.8388438,6.29904094 17.9408438,6.34095906 18.0468281,6.34095906 C18.1528125,6.34095906 18.2544375,6.29861988 18.329625,6.224 L20.5704844,3.98750877 C20.7269063,3.83148538 20.7269063,3.57918129 20.5704844,3.42306433 Z M18.046875,5.37730994 L17.4920625,4.82357895 L19.1672813,3.15162573 L19.7220938,3.70535673 L18.046875,5.37730994 Z"
                id="Shape"
                fillRule="nonzero"
              ></path>
              <path
                d="M21.6909375,4.54128655 L20.5705313,3.42306433 C20.4141094,3.26694737 20.1613125,3.26694737 20.0049375,3.42306433 L17.7640781,5.65955556 C17.6888906,5.73422222 17.6468906,5.83602339 17.6468906,5.94180117 C17.6468906,6.04757895 17.6888906,6.14900585 17.7640781,6.22404678 L18.8844844,7.34226901 C18.9624844,7.42011696 19.0649063,7.45922807 19.1672813,7.45922807 C19.2696563,7.45922807 19.3720781,7.42011696 19.4500781,7.34226901 L21.6909375,5.10577778 C21.766125,5.03111111 21.808125,4.92930994 21.808125,4.82353216 C21.808125,4.71775439 21.766125,4.61637427 21.6909375,4.54128655 Z M19.1672813,6.49553216 L18.6124688,5.94180117 L20.2876875,4.26984795 L20.8425,4.82357895 L19.1672813,6.49553216 Z"
                id="Shape"
                fillRule="nonzero"
              ></path>
              <path
                d="M22.8113438,5.65955556 L21.6909375,4.54128655 C21.616125,4.46624561 21.514125,4.42432749 21.4081406,4.42432749 C21.3021563,4.42432749 21.2005313,4.46624561 21.1253438,4.54128655 L18.8844844,6.77777778 C18.7280625,6.93389474 18.7280625,7.18619883 18.8844844,7.34226901 L20.0048906,8.46049123 C20.0797031,8.53553216 20.1817031,8.57745029 20.2876875,8.57745029 C20.3936719,8.57745029 20.4952969,8.53553216 20.5704844,8.46049123 L22.8113438,6.224 C22.9677188,6.06792982 22.9677188,5.81562573 22.8113438,5.65955556 Z M20.2876875,7.61375439 L19.732875,7.06002339 L21.4080938,5.38807018 L21.9629063,5.94180117 L20.2876875,7.61375439 Z"
                id="Shape"
                fillRule="nonzero"
              ></path>
              <path
                d="M17.48925,8.17506433 L15.8084063,6.49749708 C15.6584063,6.34778947 15.3928125,6.34778947 15.2428125,6.49749708 L11.6015625,10.1320702 C11.3015625,10.4314854 11.136375,10.8295205 11.136375,11.2530994 C11.136375,11.6766784 11.3015625,12.0747135 11.6015625,12.3741287 C11.9115469,12.6831345 12.3179531,12.837614 12.7247813,12.837614 C13.1316094,12.837614 13.5383906,12.6831345 13.8475781,12.3737076 L17.48925,8.73955556 C17.5644375,8.66488889 17.6064375,8.56308772 17.6064375,8.45730994 C17.6064375,8.35153216 17.5644375,8.25010526 17.48925,8.17506433 Z M13.2819844,11.8100117 C12.9747656,12.1170058 12.47475,12.1170058 12.1671563,11.8100117 C12.018375,11.6610994 11.9363438,11.4638596 11.9363438,11.2534737 C11.9363438,11.0430877 12.0183281,10.845848 12.1671563,10.6969357 L15.5256094,7.34502924 L16.6404375,8.45805848 L13.2819844,11.8100117 Z"
                id="Shape"
                fillRule="nonzero"
              ></path>
              <path
                d="M12.1675313,15.7236491 L6.0050625,9.5731462 C5.8490625,9.41702924 5.59546875,9.41702924 5.43946875,9.5731462 C5.28304688,9.72926316 5.28304688,9.98156725 5.43946875,10.1376374 L11.6015625,16.2881404 C11.6795625,16.3659883 11.7819844,16.4050994 11.8843594,16.4050994 C11.9867344,16.4050994 12.0891563,16.3659883 12.1675781,16.2881404 C12.3239531,16.1320702 12.3239531,15.8797661 12.1675313,15.7236491 Z"
                id="Path"
                fillRule="nonzero"
              ></path>
              <path
                d="M14.4079688,17.9601404 L13.2875625,16.8419181 C13.1311406,16.6858012 12.8783437,16.6858012 12.7219688,16.8419181 C12.5655469,16.9980351 12.5655469,17.2503392 12.7219688,17.4064094 L13.842375,18.5246316 C13.920375,18.6024795 14.0227969,18.6415906 14.1251719,18.6415906 C14.2275469,18.6415906 14.3299688,18.6024795 14.4079688,18.5246316 C14.5643906,18.3685146 14.5643906,18.1162105 14.4079688,17.9601404 Z"
                id="Path"
                fillRule="nonzero"
              ></path>
              <path
                d="M7.40545313,18.7985029 L5.16459375,16.5620117 C5.00859375,16.4058947 4.755,16.4058947 4.599,16.5620117 L3.34139062,17.8171696 C3.153,18.005614 3.04898437,18.2555322 3.04898437,18.5218246 C3.04898437,18.788117 3.15257812,19.0380351 3.34139062,19.2264795 L4.73620313,20.6185731 C4.93059375,20.8130058 5.18620313,20.9099883 5.4418125,20.9099883 C5.69742187,20.9095673 5.95340625,20.8125848 6.14784375,20.618152 L7.40545313,19.3629942 C7.48064063,19.2883275 7.52264066,19.1865263 7.52264066,19.0807485 C7.5226875,18.9749708 7.4806875,18.8735439 7.40545313,18.7985029 Z M5.582625,20.0544561 C5.50504688,20.1315088 5.37942188,20.1315088 5.30221875,20.0544561 L3.90740625,18.6623626 C3.85659375,18.6116491 3.849,18.5529825 3.849,18.5222456 C3.849,18.4915088 3.85659375,18.4324211 3.90698438,18.3821287 L4.88179688,17.4092164 L6.5574375,19.0815906 L5.582625,20.0544561 Z"
                id="Shape"
                fillRule="nonzero"
              ></path>
              <path
                d="M5.16464062,19.9171462 L4.04423437,18.798924 C3.89423437,18.6492164 3.62864062,18.6492164 3.47864062,18.798924 L0.117375,22.1535906 C0.0421875,22.2282573 0.0001875,22.3300585 0.0001875,22.4358363 C0.0001875,22.541614 0.0421875,22.6430409 0.117375,22.7180819 L1.23778125,23.8363041 C1.31578125,23.914152 1.41820312,23.9532632 1.52057812,23.9532632 C1.62295312,23.9532632 1.725375,23.914152 1.803375,23.8363041 L5.16464062,20.4815906 C5.32101562,20.3255205 5.32101562,20.0732164 5.16464062,19.9171462 Z M1.52057812,22.9895673 L0.965765625,22.4358363 L3.76139062,19.6456608 L4.31620312,20.1993918 L1.52057812,22.9895673 Z"
                id="Shape"
                fillRule="nonzero"
              ></path>
              <path
                d="M23.6513437,6.49749708 L17.4900469,0.348210526 C17.0248594,-0.116070175 16.2676406,-0.116070175 15.8028281,0.348210526 L10.7615156,5.38007018 C10.6863281,5.45473684 10.6443281,5.55653801 10.6443281,5.66231579 C10.6443281,5.76809357 10.6863281,5.86952047 10.7615156,5.9445614 L13.5627656,8.73992982 C13.7127656,8.88963743 13.9783594,8.88963743 14.1283594,8.73992982 L15.5259844,7.34465497 L16.6411875,8.45768421 L15.2431875,9.85263158 C15.168,9.92729825 15.126,10.0290994 15.126,10.1348772 C15.126,10.240655 15.168,10.3420819 15.2431875,10.4171228 L18.0440156,13.2129123 C18.1188281,13.2879532 18.2208281,13.3298713 18.3268125,13.3298713 C18.4327969,13.3298713 18.5344219,13.2879532 18.6096094,13.2129123 L23.6512969,8.18147368 C24.1161562,7.7171462 24.1161562,6.96182456 23.6513437,6.49749708 Z M23.0853281,7.61693567 L18.3268594,12.3661754 L16.091625,10.1348772 L17.48925,8.73997661 C17.5644375,8.66530994 17.6064375,8.56350877 17.6064375,8.45773099 C17.6064375,8.35195322 17.5644375,8.25052632 17.48925,8.17548538 L15.8084062,6.49791813 C15.6584062,6.34821053 15.3928125,6.34821053 15.2428125,6.49791813 L13.8451875,7.89319298 L11.6099531,5.66231579 L16.3684219,0.912748538 C16.5216094,0.760233918 16.7712187,0.760233918 16.9244531,0.912748538 L23.085375,7.06203509 C23.2385156,7.21534503 23.2385156,7.46404678 23.0853281,7.61693567 Z"
                id="Shape"
                fillRule="nonzero"
              ></path>
              <path
                d="M19.1696719,13.2077193 L15.8084062,9.85300585 C15.6519844,9.69688889 15.3991875,9.69688889 15.2428125,9.85300585 L13.2819844,11.8100117 C12.984375,12.1070409 12.4651875,12.1070409 12.1671562,11.8100117 C12.018375,11.6610994 11.9363437,11.4638596 11.9363437,11.2534737 C11.9363437,11.0430877 12.0183281,10.845848 12.1671562,10.6969357 L14.1279844,8.73992982 C14.2844062,8.58381287 14.2844062,8.33150877 14.1279844,8.1754386 L10.7667187,4.82072515 C10.6107187,4.66460819 10.357125,4.66460819 10.201125,4.82072515 L5.43942187,9.5731462 C5.39540625,9.61707602 5.36221875,9.67054971 5.342625,9.72926316 L3.38179687,15.6002807 C3.33421875,15.7435789 3.37139062,15.9017076 3.47859375,16.0087018 L7.96026562,20.4816374 C8.03625,20.5578947 8.13867187,20.5985965 8.2430625,20.5985965 C8.28548437,20.5985965 8.32828125,20.5918129 8.36948437,20.5782456 L14.2519687,18.6212398 C14.31075,18.6012632 14.364375,18.5685614 14.4083906,18.5246316 L19.1696719,13.7722105 C19.3260937,13.6161404 19.3260937,13.3638363 19.1696719,13.2077193 Z M13.9091719,17.8934737 L8.35110938,19.7426901 L4.21903125,15.6186667 L6.07185938,10.0705965 L10.4839219,5.6671345 L13.2795469,8.45730994 L11.6015625,10.1320702 C11.3015625,10.4314854 11.136375,10.8295205 11.136375,11.2530994 C11.136375,11.6766784 11.3015625,12.0747135 11.6015625,12.3741287 C12.2015625,12.9729591 13.2475781,12.9733801 13.8475781,12.3741287 L15.5256094,10.6993684 L18.3212344,13.4899649 L13.9091719,17.8934737 Z"
                id="Shape"
                fillRule="nonzero"
              ></path>
              <path
                d="M11.04675,17.4012164 L6.56507813,12.9282807 C6.40907813,12.7721637 6.15548438,12.7721637 5.99948438,12.9282807 C5.8430625,13.0843977 5.8430625,13.3367018 5.99948438,13.4927719 L10.4811562,17.9657076 C10.5591563,18.0435556 10.6615781,18.0826667 10.7639531,18.0826667 C10.866375,18.0826667 10.96875,18.0435556 11.04675,17.9657076 C11.203125,17.8095906 11.203125,17.5572865 11.04675,17.4012164 Z"
                id="Path"
                fillRule="nonzero"
              ></path>
              <path
                d="M8.80589063,17.4012164 L6.56503125,15.1647251 C6.40903125,15.0086082 6.1554375,15.0086082 5.9994375,15.1647251 C5.84301563,15.3208421 5.84301563,15.5731462 5.9994375,15.7292164 L8.24029688,17.9657076 C8.31829688,18.0435556 8.42071875,18.0826667 8.52309375,18.0826667 C8.62546875,18.0826667 8.72789063,18.0435556 8.80589062,17.9657076 C8.9623125,17.8095906 8.9623125,17.5572865 8.80589063,17.4012164 Z"
                id="Path"
                fillRule="nonzero"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
