import React, {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useMemo,
  useState,
} from 'react';
import { MergedPartnerConfig } from './interface';
import { PartnerKeys } from '../../constants';

interface IPartnerContent {
  partnerContext: MergedPartnerConfig;
  setPartnerContext: Dispatch<SetStateAction<MergedPartnerConfig>>;
}

const defaultPartnerContext: MergedPartnerConfig = {
  partnerNameUrl: PartnerKeys.missing,
};

const defaultContent: IPartnerContent = {
  partnerContext: defaultPartnerContext,
  setPartnerContext: () => {},
};

const PartnerContext = createContext<IPartnerContent>(defaultContent);
PartnerContext.displayName = 'PartnerContext';

interface PartnerProviderProps {
  children: ReactNode;
}

export const PartnerProvider = ({ children }: PartnerProviderProps) => {
  const [partnerContext, setPartnerContext] = useState<MergedPartnerConfig>(
    defaultPartnerContext
  );

  const value = useMemo(
    (): IPartnerContent => ({
      partnerContext,
      setPartnerContext,
    }),
    [partnerContext]
  );

  return (
    <PartnerContext.Provider value={value}>{children}</PartnerContext.Provider>
  );
};
