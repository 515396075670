import React, { ChangeEvent, useCallback, useState } from 'react';
import * as uuid from 'uuid';
import { UserAnalyticEvent } from '../../../utils';
import { BaseButton } from '..';
import { PartnerKeyProperties, PartnerKeys } from '../../../constants';

interface PinContentContainerProps {
  onPinSubmit: (pin: number) => void;
}

const createUniqueTarget = (classIdentifier, stringId, useHash = false) => {
  return `${useHash ? '#' : ''}${classIdentifier}-${stringId}`;
};

const pinLength = 5;

export const PinContentContainer = ({
  onPinSubmit,
}: PinContentContainerProps) => {
  const [uniqueHash] = useState(uuid.v4());
  const createUniqueName = useCallback(
    (stringId, useHash = false) => {
      return createUniqueTarget(`a-${uniqueHash}`, stringId, useHash);
    },
    [uniqueHash]
  );

  const generatePinIds = (): object => {
    const obj = {};
    for (let i = 0; i < pinLength; i++) {
      obj[i] = createUniqueName(`${i}`);
    }

    return obj;
  };

  const indices: object = generatePinIds();

  const [isButtonEnabled, setIsButtonEnabled] = useState(false);

  const getPin = (): number => {
    const pinArray: string[] = [];

    for (let i = 0; i < pinLength; i++) {
      const field: HTMLInputElement = document.getElementById(
        indices[i]
      ) as HTMLInputElement;
      pinArray[i] = field?.value;
    }

    if (pinArray.every((_, index, array) => Boolean(array[index]))) {
      const pinNumber = Number(pinArray.join(''));
      if (pinNumber >= 10000) return pinNumber;
    }
    return 0;
  };

  const submitPin = () => {
    const pin = getPin();
    if (pin !== 0) {
      onPinSubmit(pin);
    }
  };

  const changeFocus = (event) => {
    const keyPressed = event.key;
    const field = event.currentTarget;

    if (keyPressed === 'Backspace') {
      if (isButtonEnabled) {
        setIsButtonEnabled(false);
      }

      let fieldIndex = 0;
      for (let i = 0; i < pinLength; i++) {
        if (field?.id === indices[i]) {
          if (i !== 0) {
            fieldIndex = i - 1;
          } else {
            fieldIndex = 0;
          }
        }
      }

      if (field.value !== '') {
        field.value = '';
      } else {
        const element = document.getElementById(indices[fieldIndex]);
        element?.focus();
      }
    }

    if (keyPressed === 'Enter' && isButtonEnabled) {
      submitPin();
    }
  };

  const validateField = (event: ChangeEvent<HTMLInputElement>) => {
    const field: HTMLInputElement = event.currentTarget;

    if (!field.validity.valid) {
      field.value = '';
      if (isButtonEnabled) {
        setIsButtonEnabled(false);
      }
      return;
    }

    if (field.value !== '') {
      let fieldIndex = 0;
      for (let i = 0; i < pinLength; i++) {
        if (field?.id === indices[i]) {
          if (i !== pinLength - 1) {
            fieldIndex = i + 1;
          } else {
            fieldIndex = pinLength - 1;
          }
        }
      }
      const element = document.getElementById(indices[fieldIndex]);

      if (getPin() !== 0 && field.value !== '') {
        setIsButtonEnabled(true);
      } else {
        if (isButtonEnabled) {
          setIsButtonEnabled(false);
        }
      }

      element?.focus();
    }
  };

  const getInputFields: JSX.Element[] = Object.values(indices).map((index) => (
    // eslint-disable-next-line react/jsx-key
    <input
      className="pin-page__input_field"
      id={index}
      key={index}
      inputMode="numeric"
      pattern="[0-9]*"
      maxLength={1}
      onChange={validateField}
      onKeyDown={changeFocus}
    />
  ));

  return (
    <div>
      <div className="pin-page__subtitle">
        Enter the customer's {pinLength} digit code
      </div>
      <div className="pin-page__subheader">
        <span>
          This code can be found in the{' '}
          <strong>{PartnerKeyProperties[PartnerKeys.homeplus].appName}</strong>{' '}
          app after the customer taps <strong>Get help with your scan</strong>
        </span>
      </div>
      <div className="pin-page__input">{...getInputFields}</div>
      <div className="pin-page__button_container">
        <BaseButton
          customStyle="invert-button"
          disabled={!isButtonEnabled}
          analyticName={UserAnalyticEvent.EnterPinButton}
          onClick={submitPin}
        >
          Submit and get results
        </BaseButton>
      </div>
    </div>
  );
};
