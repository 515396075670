import React from 'react';
import {
  Computer,
  GamingConsole,
  Headphones,
  Hub,
  Lights,
  Modem,
  Mp3Player,
  Multimedia,
  NasDisk,
  Phone,
  Printer,
  Scanner,
  SmartSpeaker,
  Smartwatch,
  SmokeDetector,
  Speaker,
  Switches,
  Tablet,
  Thermostat,
  Thing,
  Tv,
  Unknown,
  Wifi,
} from '../../../../icons/devices-icons';

type SupportedDevices =
  | 'computer'
  | 'fitness_bands'
  | 'game_console'
  | 'headset'
  | 'hub'
  | 'lighting'
  | 'mp3_player'
  | 'multimedia'
  | 'nas_disk'
  | 'phone'
  | 'printer'
  | 'router'
  | 'scanner'
  | 'smart_speaker'
  | 'smoke_detector'
  | 'speaker'
  | 'switch'
  | 'tablet'
  | 'thermostat'
  | 'thing'
  | 'tv'
  | 'unknown'
  | 'wifi';

const DeviceTypeToIcon: Record<
  SupportedDevices,
  (props: React.SVGProps<SVGSVGElement>) => JSX.Element
> = {
  computer: Computer,
  fitness_bands: Smartwatch,
  game_console: GamingConsole,
  headset: Headphones,
  hub: Hub,
  lighting: Lights,
  mp3_player: Mp3Player,
  multimedia: Multimedia,
  nas_disk: NasDisk,
  phone: Phone,
  printer: Printer,
  router: Modem,
  scanner: Scanner,
  smart_speaker: SmartSpeaker,
  smoke_detector: SmokeDetector,
  speaker: Speaker,
  switch: Switches,
  tablet: Tablet,
  thermostat: Thermostat,
  thing: Thing,
  tv: Tv,
  unknown: Unknown,
  wifi: Wifi,
};

export const getIconSrcByType = (
  iconType: SupportedDevices | undefined | string
): ((props: React.SVGProps<SVGSVGElement>) => JSX.Element) => {
  if (!iconType) return DeviceTypeToIcon.unknown;

  return DeviceTypeToIcon[iconType] || DeviceTypeToIcon.unknown;
};
