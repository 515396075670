import React from 'react';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';

import { RoutePaths } from './RoutePaths';
import {
  HomePage,
  IncorrectPartnerPage,
  LoginPage,
  LogoutPage,
  NoMatchingRoutePage,
  PinPage,
  ResultsPageV2,
  ScanFailedPage,
} from '../../pages';
import { AuthenticateRoute, AuthenticationProvider } from '../../sso';

// eslint-disable-next-line react/display-name
export const AppRoutes = React.memo(() => {
  return (
    <AuthenticationProvider>
      <Router>
        <Switch>
          <Route exact path={RoutePaths.Login} component={LoginPage} />
          <Route exact path={RoutePaths.Logout} component={LogoutPage} />
          <Route exact path={RoutePaths.Home} component={HomePage} />
          <AuthenticateRoute
            path={RoutePaths.IncorrectPartner}
            component={IncorrectPartnerPage}
          />
          <AuthenticateRoute
            path={RoutePaths.ScanInstructions}
            component={ResultsPageV2}
          />
          <AuthenticateRoute path={RoutePaths.Pin} component={PinPage} />
          <AuthenticateRoute
            path={RoutePaths.Scanning}
            component={ResultsPageV2}
          />
          <AuthenticateRoute
            path={RoutePaths.ScanResults}
            component={ResultsPageV2}
          />
          <AuthenticateRoute
            path={RoutePaths.ScanFailed}
            component={ScanFailedPage}
          />
          <AuthenticateRoute
            path={RoutePaths.NoMatch}
            component={NoMatchingRoutePage}
          />
          <AuthenticateRoute path={RoutePaths.Default} component={LoginPage} />
        </Switch>
      </Router>
    </AuthenticationProvider>
  );
});
