import React, { useCallback, useState } from 'react';
import { DisplayString } from '../constants';
import { BaseButton, H1, RoutePaths, Spinner } from '../components';
import { Auth, useAuthentication } from '../sso';
import { useAuthenticatedRouting, useRouter } from '../hooks';
import { toast } from 'react-toastify';
import { analytics, DashboardEventType, UserAnalyticEvent } from '../utils';
import { AsurionLogo } from '../icons';

export const LoginPage = () => {
  useAuthenticatedRouting((error) => {
    toast(error, { position: 'top-right', type: 'error' });
    analytics.dispatch(
      UserAnalyticEvent.UserLoginError,
      DashboardEventType.Error,
      { error }
    );
  });

  const { isLoggedIn } = useAuthentication();
  const router = useRouter();

  if (isLoggedIn) {
    router.push({
      pathname: RoutePaths.Home,
    });
  }
  const [isLoggingIn, setIsLoggingIn] = useState(false);
  const loginWithAsurion = useCallback(() => {
    setIsLoggingIn(true);
    Auth.federatedSignIn({
      customProvider: 'sso.asurion.com',
    });
  }, []);

  return (
    <div className="login-page">
      <div className="sign-in-container">
        <AsurionLogo height={50} width={undefined} />
        <H1>{DisplayString.ExpertDashboard}</H1>
        {!isLoggingIn ? (
          <>
            <BaseButton
              analyticName={UserAnalyticEvent.AsurionLoginButtonClick}
              onClick={loginWithAsurion}
              customStyle="button-margin"
            >
              {DisplayString.SignInWithAsurion}
            </BaseButton>
          </>
        ) : (
          <Spinner
            styles={{
              position: 'relative',
            }}
          />
        )}
      </div>
    </div>
  );
};
