import React from 'react';
import { IFormattedClassifiedDevice } from '../../../../utils';
import { Tooltip } from '../../../CommonComponents';
import { getIconSrcByType } from '../display-icons';

interface DeviceViewProps {
  device: IFormattedClassifiedDevice;
}
export const DeviceView = ({ device }: DeviceViewProps) => {
  const Icon = getIconSrcByType(device.kind);
  const iconSize = 36;
  const deviceName =
    device.ip !== device.name ? device.name : device.nameCommon;
  const isDeviceWired = String(device.operatingParameters?.wired) === 'true';
  return (
    <div className="device-view v2">
      <div>
        <Icon
          className="device-icons category-icons"
          width={iconSize}
          height={iconSize}
        />
      </div>
      <div className="description">
        <Tooltip message={deviceName} conditionalTooltip={true}>
          <span className="device-name">
            <strong>{deviceName}</strong>
          </span>
        </Tooltip>
        {device.make && (
          <Tooltip conditionalTooltip={true} message={device.make}>
            <span className="device-make">{device.make}</span>
          </Tooltip>
        )}
      </div>
    </div>
  );
};
