import { PartnerKeys } from '../../constants';
import {
  InitiateScanButtonProps,
  InitiateScanButtonPropsV2,
} from '../contracts';
import React from 'react';
import {
  VerizonInitiateScanButton,
  VerizonInitiateScanButtonV2,
  VerizonScanInstructionContent,
  VerizonScanInstructionContentV2,
  VerizonScanInstructionsHeader,
} from '../verizon';
import {
  ScanInstructionProps,
  ScanInstructionPropsV2,
} from '../contracts/ScanInstructionProps';
import {
  AttInitiateScanButton,
  AttInitiateScanButtonV2,
  AttScanInstructionsContent,
  AttScanInstructionsContentV2,
  AttScanInstructionsHeader,
} from '../att';
import {
  HomeplusInitiateScanButton,
  HomeplusInitiateScanButtonV2,
  HomeplusScanInstructionsContent,
  HomeplusScanInstructionsContentV2,
  HomeplusScanInstructionsHeader,
} from '../homeplus';

export const getInitiateScanButtonV2 = (
  partnerKey: PartnerKeys,
  props: InitiateScanButtonPropsV2
): JSX.Element => {
  switch (partnerKey) {
    case PartnerKeys.att:
      return <AttInitiateScanButtonV2 {...props} />;
    case PartnerKeys.vzw:
      return <VerizonInitiateScanButtonV2 {...props} />;
    case PartnerKeys.homeplus:
      return <HomeplusInitiateScanButtonV2 {...props} />;
    default:
      return <>Unsupported</>;
  }
};

export const getInitiateScanButton = (
  partnerKey: PartnerKeys,
  props: InitiateScanButtonProps
): JSX.Element => {
  switch (partnerKey) {
    case PartnerKeys.att:
      return <AttInitiateScanButton {...props} />;
    case PartnerKeys.vzw:
      return <VerizonInitiateScanButton {...props} />;
    case PartnerKeys.homeplus:
      return <HomeplusInitiateScanButton {...props} />;
    default:
      return <>Unsupported</>;
  }
};

export const getScanInstructionsContentV2 = (
  partnerKey: string,
  props: ScanInstructionPropsV2
): JSX.Element => {
  switch (partnerKey) {
    case PartnerKeys.att:
      return <AttScanInstructionsContentV2 {...props} />;
    case PartnerKeys.vzw:
      return <VerizonScanInstructionContentV2 {...props} />;
    case PartnerKeys.homeplus:
      return <HomeplusScanInstructionsContentV2 {...props} />;
    default:
      return <>Unsupported</>;
  }
};

export const getScanInstructionsContent = (
  partnerKey: string,
  props: ScanInstructionProps
): JSX.Element => {
  switch (partnerKey) {
    case PartnerKeys.att:
      return <AttScanInstructionsContent {...props} />;
    case PartnerKeys.vzw:
      return <VerizonScanInstructionContent {...props} />;
    case PartnerKeys.homeplus:
      return <HomeplusScanInstructionsContent {...props} />;
    default:
      return <>Unsupported</>;
  }
};

export const getScanInstructionsHeader = (partnerKey: string): JSX.Element => {
  switch (partnerKey) {
    case PartnerKeys.att:
      return AttScanInstructionsHeader;
    case PartnerKeys.vzw:
      return VerizonScanInstructionsHeader;
    case PartnerKeys.homeplus:
      return HomeplusScanInstructionsHeader;
    default:
      return <span></span>;
  }
};
