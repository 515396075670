import React, { MouseEvent, ReactNode, useState } from 'react';

interface TooltipProps {
  message: string;
  position?: 'top' | 'bottom' | 'right' | 'left';
  showTooltip?: boolean;
  conditionalTooltip?: boolean;
  children?: ReactNode;
}

export const Tooltip = ({
  message,
  position = 'top',
  showTooltip = true,
  conditionalTooltip,
  children,
}: TooltipProps) => {
  const [displayTooltip, setDisplayTooltip] = useState(false);
  const onMouseLeaveEffect = () => {
    setDisplayTooltip(false);
  };
  const onMouseOverEffect = (
    event: MouseEvent<HTMLSpanElement, globalThis.MouseEvent>
  ) => {
    const childElement = event.currentTarget.firstElementChild;
    if (conditionalTooltip && childElement) {
      setDisplayTooltip(childElement.scrollWidth > childElement.clientWidth);
      return;
    }
    setDisplayTooltip(true);
  };
  if (!showTooltip) {
    return <span>{children}</span>;
  }
  return (
    <span className="tooltip-container" onMouseLeave={onMouseLeaveEffect}>
      <div
        className={
          `tooltip-bubble tooltip-${position}` && displayTooltip
            ? 'd-block'
            : 'd-none'
        }
      >
        <div className="tooltip-message">{message}</div>
      </div>
      <span className="tooltip-trigger" onMouseOver={onMouseOverEffect}>
        {children}
      </span>
    </span>
  );
};
