import React from 'react';
import PropTypes from 'prop-types';
import { DeviceBlock } from '../DeviceBlock';
import { colors } from '../../../../styles';
import { useTranslation } from 'react-i18next';

interface NetworkDevicesGraphViewProps {
  graphData: any[];
  totalDeviceCount: number;
}
export const NetworkDevicesGraphView = ({
  graphData,
  totalDeviceCount,
}: NetworkDevicesGraphViewProps) => {
  const { t } = useTranslation();
  const firstBlockFactor = function (f, i) {
    const obj = { angle: 0, width: 60, marginTop: 48.5, marginLeft: 0 };
    const fact2 = [
      { angle: -38, width: 78, marginTop: 72, marginLeft: -9 },
      { angle: 38, width: 78, marginTop: 26, marginLeft: -9 },
    ];
    const fact4 = [
      { angle: -67, width: 155, marginTop: 120, marginLeft: -48 },
      { angle: 67, width: 155, marginTop: -22, marginLeft: -48 },
    ];
    const fact6 = [
      { angle: -76, width: 250, marginTop: 165, marginLeft: -95.5 },
      { angle: 76, width: 250, marginTop: -65, marginLeft: -95.5 },
    ];
    const fact8 = [
      { angle: -80, width: 345, marginTop: 211, marginLeft: -143 },
      { angle: 80, width: 345, marginTop: -112, marginLeft: -143 },
    ];
    const fact10 = [
      { angle: -82, width: 447, marginTop: 258, marginLeft: -193 },
      { angle: 82, width: 447, marginTop: -153, marginLeft: -193 },
    ];

    const fact3 = [
      { angle: -58, width: 114, marginTop: 95, marginLeft: -27.5 },
      { angle: 0, width: 60, marginTop: 48.5, marginLeft: 0 },
      { angle: 58, width: 114, marginTop: 2, marginLeft: -27.5 },
    ];
    const fact5 = [
      { angle: -72, width: 200, marginTop: 144, marginLeft: -70 },
      { angle: 72, width: 200, marginTop: -47, marginLeft: -70 },
    ];
    const fact7 = [
      { angle: -78, width: 290, marginTop: 194, marginLeft: -116 },
      { angle: 78, width: 290, marginTop: -96, marginLeft: -116 },
    ];
    const fact9 = [
      { angle: -81, width: 400, marginTop: 230, marginLeft: -170 },
      { angle: 81, width: 400, marginTop: -136, marginLeft: -170 },
    ];

    return getCalculatedFactors(
      f,
      i,
      fact2,
      fact3,
      fact4,
      fact5,
      fact6,
      fact7,
      fact8,
      fact9,
      fact10,
      obj
    );
  };

  const secondBlockFactor = function (f, i) {
    const obj = { angle: 0, marginTop: 48.5, marginLeft: 0, width: 320 };
    const fact2 = [
      { angle: -8, width: 322, marginTop: 74, marginLeft: -1.5 },
      { angle: 8, width: 322, marginTop: 24, marginLeft: -1.5 },
    ];
    const fact4 = [
      { angle: -24, width: 350, marginTop: 120, marginLeft: -15 },
      { angle: 24, width: 350, marginTop: -22, marginLeft: -15 },
    ];
    const fact6 = [
      { angle: -37, width: 400, marginTop: 163, marginLeft: -37 },
      { angle: 37, width: 400, marginTop: -65, marginLeft: -37 },
    ];
    const fact8 = [
      { angle: -46, width: 460, marginTop: 214, marginLeft: -68 },
      { angle: 46, width: 460, marginTop: -114, marginLeft: -68 },
    ];
    const fact10 = [
      { angle: -54, width: 546, marginTop: 256, marginLeft: -114 },
      { angle: 54, width: 546, marginTop: -158, marginLeft: -114 },
    ];

    const fact3 = [
      { angle: -16, width: 334, marginTop: 97, marginLeft: -6 },
      { angle: 0, width: 320, marginTop: 48.5, marginLeft: 0 },
      { angle: 16, width: 334, marginTop: 0.5, marginLeft: -6 },
    ];
    const fact5 = [
      { angle: -30.5, width: 372, marginTop: 143, marginLeft: -25 },
      { angle: 30.5, width: 372, marginTop: -45, marginLeft: -25 },
    ];
    const fact7 = [
      { angle: -42, width: 430, marginTop: 188, marginLeft: -55 },
      { angle: 42, width: 430, marginTop: -90, marginLeft: -55 },
    ];
    const fact9 = [
      { angle: -50, width: 498, marginTop: 234, marginLeft: -88 },
      { angle: 50, width: 498, marginTop: -136, marginLeft: -88 },
    ];

    return getCalculatedFactors(
      f,
      i,
      fact2,
      fact3,
      fact4,
      fact5,
      fact6,
      fact7,
      fact8,
      fact9,
      fact10,
      obj
    );
  };

  const thirdBlockFactor = function (f, i) {
    const obj = { angle: 0, marginTop: 48.5, marginLeft: 0, width: 576 };
    const fact2 = [
      { angle: -5, marginTop: 70, marginLeft: -3, width: 582 },
      { angle: 5, marginTop: 27, marginLeft: -3, width: 582 },
    ];
    const fact3 = [
      { angle: -9, marginTop: 98, marginLeft: -6, width: 586 },
      { angle: 9, marginTop: -1, marginLeft: -6, width: 586 },
    ];
    const fact4 = [
      { angle: -14, marginTop: 119, marginLeft: -10, width: 598 },
      { angle: 14, marginTop: -20, marginLeft: -10, width: 598 },
    ];
    const fact5 = [
      { angle: -18, marginTop: 144, marginLeft: -18, width: 610 },
      { angle: 18, marginTop: -48, marginLeft: -18, width: 610 },
    ];
    const fact6 = [
      { angle: -24, marginTop: 157, marginLeft: -28, width: 634 },
      { angle: 24, marginTop: -60, marginLeft: -28, width: 634 },
    ];
    const fact7 = [
      { angle: -26, marginTop: 191, marginLeft: -35, width: 644 },
      { angle: 26, marginTop: -94, marginLeft: -32, width: 644 },
    ];
    const fact8 = [
      { angle: -31, marginTop: 210, marginLeft: -48, width: 672 },
      { angle: 31, marginTop: -110, marginLeft: -48, width: 672 },
    ];
    const fact9 = [
      { angle: -34, marginTop: 236, marginLeft: -60, width: 696 },
      { angle: 34, marginTop: -137, marginLeft: -60, width: 696 },
    ];
    const fact10 = [
      { angle: -37, marginTop: 259, marginLeft: -74, width: 722 },
      { angle: 37, marginTop: -160, marginLeft: -72, width: 722 },
    ];

    if (f === 2) {
      return fact2[i];
    } else if (f === 3) {
      if (i === 0) {
        return fact3[i];
      } else if (i === 2) {
        return fact3[fact3.length - 1];
      } else {
        return obj;
      }
    } else if (f === 4) {
      if (i === 1) {
        return fact2[i - 1];
      }
      if (i === 2) {
        return fact2[i - 1];
      }
      if (i === 0) {
        return fact4[i];
      } else {
        return fact4[fact4.length - 1];
      }
    } else if (f === 5) {
      if (i === 1) {
        return fact3[i - 1];
      }
      if (i === 3) {
        return fact3[fact3.length - 1];
      }
      if (i === 0) {
        return fact5[i];
      } else if (i === 4) {
        return fact5[fact5.length - 1];
      }
    } else if (f === 6) {
      if (i === 1) {
        return fact4[i - 1];
      } else if (i === 4) {
        return fact4[fact4.length - 1];
      } else if (i === 2) {
        return fact2[i - 2];
      } else if (i === 3) {
        return fact2[fact2.length - 1];
      } else if (i === 0) {
        return fact6[i];
      } else if (i === 5) {
        return fact6[fact6.length - 1];
      }
    } else if (f === 7) {
      if (i === 1) {
        return fact5[i - 1];
      } else if (i === 5) {
        return fact5[fact5.length - 1];
      } else if (i === 2) {
        return fact3[i - 2];
      } else if (i === 4) {
        return fact3[fact3.length - 1];
      } else if (i === 0) {
        return fact7[i];
      } else if (i === 6) {
        return fact7[fact7.length - 1];
      }
    } else if (f === 8) {
      if (i === 1) {
        return fact6[i - 1];
      } else if (i === 6) {
        return fact6[fact6.length - 1];
      } else if (i === 2) {
        return fact4[i - 2];
      } else if (i === 5) {
        return fact4[fact4.length - 1];
      } else if (i === 3) {
        return fact2[i - 3];
      } else if (i === 4) {
        return fact2[fact2.length - 1];
      } else if (i === 0) {
        return fact8[i];
      } else if (i === 7) {
        return fact8[fact8.length - 1];
      }
    } else if (f === 9) {
      if (i === 1) {
        return fact7[i - 1];
      } else if (i === 7) {
        return fact7[fact7.length - 1];
      } else if (i === 2) {
        return fact5[i - 2];
      } else if (i === 6) {
        return fact5[fact5.length - 1];
      } else if (i === 3) {
        return fact3[i - 3];
      } else if (i === 5) {
        return fact3[fact3.length - 1];
      } else if (i === 0) {
        return fact9[i];
      } else if (i === 8) {
        return fact9[fact9.length - 1];
      }
    } else if (f === 10) {
      if (i === 1) {
        return fact8[i - 1];
      } else if (i === 8) {
        return fact8[fact8.length - 1];
      } else if (i === 2) {
        return fact6[i - 2];
      } else if (i === 7) {
        return fact6[fact6.length - 1];
      } else if (i === 3) {
        return fact4[i - 3];
      } else if (i === 6) {
        return fact4[fact4.length - 1];
      } else if (i === 4) {
        return fact2[i - 4];
      } else if (i === 5) {
        return fact2[fact2.length - 1];
      } else if (i === 0) {
        return fact10[i];
      } else if (i === 9) {
        return fact10[fact10.length - 1];
      }
    }

    return obj;
  };

  const getCalculatedFactors = function (
    f,
    i,
    fact2,
    fact3,
    fact4,
    fact5,
    fact6,
    fact7,
    fact8,
    fact9,
    fact10,
    obj
  ) {
    switch (f) {
      case 2:
        return fact2[i];
      case 3:
        return fact3[i];
      case 4:
        if (i === 0) {
          return fact4[i];
        } else if (i === 3) {
          return fact4[1];
        } else {
          return fact2[i - 1];
        }
      case 5:
        if (i === 0) {
          return fact5[i];
        } else if (i === 4) {
          return fact5[1];
        } else {
          return fact3[i - 1];
        }
      case 6:
        if (i === 0) {
          return fact6[i];
        } else if (i === 5) {
          return fact6[1];
        } else if (i === 1 || i === 4) {
          if (i === 1) {
            return fact4[0];
          } else {
            return fact4[1];
          }
        } else {
          if (i === 2) {
            return fact2[0];
          } else {
            return fact2[1];
          }
        }
      case 7:
        if (i === 0) {
          return fact7[i];
        } else if (i === 6) {
          return fact7[1];
        } else {
          if (i === 1) {
            return fact5[0];
          } else if (i === 5) {
            return fact5[1];
          } else {
            return fact3[i - 2];
          }
        }
      case 8:
        if (i === 0) {
          return fact8[i];
        } else if (i === 7) {
          return fact8[fact8.length - 1];
        } else {
          if (i === 1) {
            return fact6[i - 1];
          } else if (i === 6) {
            return fact6[fact6.length - 1];
          } else {
            if (i === 2) {
              return fact4[i - 2];
            } else if (i === 5) {
              return fact4[fact4.length - 1];
            } else {
              return fact2[i - 3];
            }
          }
        }
      case 9:
        if (i === 0) {
          return fact9[i];
        } else if (i === 8) {
          return fact9[1];
        } else if (i === 1) {
          return fact7[0];
        } else if (i === 7) {
          return fact7[1];
        } else if (i === 2) {
          return fact5[0];
        } else if (i === 6) {
          return fact5[1];
        } else {
          return fact3[i - 3];
        }
      case 10:
        if (i === 0) {
          return fact10[i];
        } else if (i === 9) {
          return fact10[fact10.length - 1];
        } else {
          if (i === 1) {
            return fact8[i - 1];
          } else if (i === 8) {
            return fact8[fact8.length - 1];
          } else {
            if (i === 2) {
              return fact6[i - 2];
            } else if (i === 7) {
              return fact6[fact6.length - 1];
            } else {
              if (i === 3) {
                return fact4[i - 3];
              } else if (i === 6) {
                return fact4[fact4.length - 1];
              } else {
                return fact2[i - 4];
              }
            }
          }
        }
      default:
        return obj;
    }
  };

  const firstColumn = function (devices, hasSecondColumn, hasThirdColumn) {
    let subChildLeftMargin = 0;
    if (hasSecondColumn) {
      subChildLeftMargin = -530;
    }
    if (hasThirdColumn) {
      subChildLeftMargin = -785;
    }
    return (
      <div>
        {devices.map((device, index) => {
          const fact = firstBlockFactor(devices.length, index);
          const uid = `firstColumn${index}`;
          const borderColor = device.rssi
            ? device.rssi < 0.3
              ? colors.red
              : device.rssi < 0.67
              ? colors.yellow
              : device.rssi < 0.8
              ? colors.lime
              : colors.green
            : colors.grey;
          return (
            <div
              className="row device-column first-device-column"
              style={{ marginLeft: subChildLeftMargin }}
              key={index}
            >
              <div className="firstcolumn-wrapper">
                <div
                  className="horizontal-line"
                  style={{
                    transform: `rotate(${fact.angle}deg)`,
                    marginTop: fact.marginTop,
                    marginLeft: fact.marginLeft,
                    width: fact.width,
                    borderTopColor: borderColor,
                  }}
                ></div>
              </div>
              <DeviceBlock
                device={device}
                uid={uid}
                columnNumber={1}
                columnData={true}
              />
            </div>
          );
        })}
      </div>
    );
  };

  const secondColumn = function (devices, hasThirdColumn) {
    let marginLeft = 0;
    if (hasThirdColumn) {
      marginLeft = -785;
    }
    return (
      <div>
        {devices.map((device, index) => {
          const fact = secondBlockFactor(devices.length, index);
          const uid = `secondColumn${index}`;
          const borderColor = device.rssi
            ? device.rssi < 0.3
              ? colors.red
              : device.rssi < 0.67
              ? colors.yellow
              : device.rssi < 0.8
              ? colors.lime
              : colors.green
            : colors.grey;
          return (
            <div
              className="row device-column second-device-column"
              style={{ marginLeft: marginLeft }}
              key={index}
            >
              <div className="secondcolumn-wrapper">
                <div
                  className="horizontal-line"
                  style={{
                    transform: `rotate(${fact.angle}deg)`,
                    marginTop: fact.marginTop,
                    marginLeft: fact.marginLeft,
                    width: fact.width,
                    borderTopColor: borderColor,
                  }}
                ></div>
              </div>
              <DeviceBlock
                device={device}
                uid={uid}
                columnNumber={2}
                columnData={true}
              />
            </div>
          );
        })}
      </div>
    );
  };

  const thirdColumn = function (devices) {
    return (
      <div>
        {devices.map((device, index) => {
          const fact = thirdBlockFactor(devices.length, index);
          const uid = `thirdColumn${index}`;
          const borderColor = device.rssi
            ? device.rssi < 0.3
              ? colors.red
              : device.rssi < 0.67
              ? colors.yellow
              : device.rssi < 0.8
              ? colors.lime
              : colors.green
            : colors.grey;
          return (
            <div className="row device-column third-device-column" key={index}>
              <div style={{ width: 576, height: 74 }}>
                <div
                  className="horizontal-line"
                  style={{
                    transform: `rotate(${fact.angle}deg)`,
                    marginTop: fact.marginTop,
                    marginLeft: fact.marginLeft,
                    width: fact.width,
                    borderTopColor: borderColor,
                  }}
                ></div>
              </div>
              <DeviceBlock
                device={device}
                uid={uid}
                columnNumber={3}
                columnData={true}
              />
            </div>
          );
        })}
      </div>
    );
  };

  const handleNoRouter = function (devices) {
    let count = -4;
    return (
      <div>
        {[...Array(Math.ceil(devices.length / 4))].map(() => {
          count += 4;
          return (
            <div className="row" key={count}>
              {devices.slice(count, count + 4).map((device) => {
                const uid = `noRouter${device.name}${device.nameCommon}`;
                return (
                  <div className="no-router-class" key={device.ip}>
                    <DeviceBlock device={device} uid={uid} />
                  </div>
                );
              })}
            </div>
          );
        })}
      </div>
    );
  };

  let check = false;
  graphData.forEach((graphData, index) => {
    if (
      index < graphData.length - 1 &&
      graphData[index]['devices'].length > 0
    ) {
      check = true;
    }
  });

  return (
    <div className="network-devices-graph-container">
      {totalDeviceCount > 0 ? (
        graphData.map((typeObj, index) => {
          // deviceIndex++;
          const upLine =
            (graphData[0]['devices'].length > 0 ||
              index === graphData.length - 1) &&
            check;
          // let lineV = graphData[graphData.length-1].devices.length>0 && index < graphData.length-1;
          const validRouter = Object.keys(typeObj.router).length > 0;
          let lineHeight = 86,
            hasSecondColumn = false,
            hasThirdColumn = false;
          let firstColumnDevices = typeObj.devices;
          let secondColumnDevices = [];
          let thirdColumnDevices = [];
          if (typeObj.devices.length > 5 && typeObj.devices.length <= 10) {
            firstColumnDevices = typeObj.devices.slice(0, 5);
            secondColumnDevices = typeObj.devices.slice(5);
            hasSecondColumn = true;
          }
          if (typeObj.devices.length > 10) {
            secondColumnDevices = typeObj.devices.slice(
              0,
              Math.ceil(typeObj.devices.length / 2)
            );
            firstColumnDevices = typeObj.devices.slice(
              Math.ceil(typeObj.devices.length / 2),
              2 * Math.ceil(typeObj.devices.length / 2)
            );
            if (typeObj.devices.length > 20) {
              if (typeObj.devices.length < 30) {
                firstColumnDevices = typeObj.devices.slice(
                  2 * Math.ceil(typeObj.devices.length / 3),
                  30
                );
                secondColumnDevices = typeObj.devices.slice(
                  Math.ceil(typeObj.devices.length / 3),
                  2 * Math.ceil(typeObj.devices.length / 3)
                );
                thirdColumnDevices = typeObj.devices.slice(
                  0,
                  Math.ceil(typeObj.devices.length / 3)
                );
              }
              if (typeObj.devices.length > 30) {
                firstColumnDevices = typeObj.devices.slice(20, 30);
                secondColumnDevices = typeObj.devices.slice(10, 20);
                thirdColumnDevices = typeObj.devices.slice(0, 10);
              }
              hasThirdColumn = true;
            }
            hasSecondColumn = true;
          }
          if (secondColumnDevices.length > firstColumnDevices.length) {
            lineHeight = secondColumnDevices.length * 45;
          } else {
            lineHeight = firstColumnDevices.length * 45;
          }
          if (typeObj.router && validRouter && typeObj.devices.length > 0) {
            const uid = `router${index}`;
            return (
              <div className="row" key={index}>
                <div className="category-wrapper">
                  <div
                    className="col category-wrapper-top-div"
                    style={{
                      height: lineHeight,
                      visibility:
                        index === 0 ? 'hidden' : upLine ? 'visible' : 'hidden',
                    }}
                  ></div>
                  <DeviceBlock
                    device={typeObj.router}
                    routerType={typeObj.wired ? 'wired' : 'wifi'}
                    uid={uid}
                    band={typeObj.band}
                    ssid={typeObj.ssid}
                  />
                  <div
                    className="col category-wrapper-bottom-div"
                    style={{
                      height: lineHeight,
                      visibility:
                        index === graphData.length - 1 ? 'hidden' : 'visible',
                    }}
                  ></div>
                </div>

                <div className="row column-wrapper" key={index}>
                  {thirdColumn(thirdColumnDevices)}
                  {secondColumn(secondColumnDevices, hasThirdColumn)}
                  {firstColumn(
                    firstColumnDevices,
                    hasSecondColumn,
                    hasThirdColumn
                  )}
                </div>
              </div>
            );
          } else {
            return (
              <div className="row" key={index}>
                {handleNoRouter(typeObj.devices)}
              </div>
            );
          }
        })
      ) : (
        <div className="no-devices-found">
          <span>{t('no_devices_were_found')}.</span>
        </div>
      )}
    </div>
  );
};

NetworkDevicesGraphView.propTypes = {
  className: PropTypes.string,
  graphData: PropTypes.array,
  totalDeviceCount: PropTypes.number,
};
