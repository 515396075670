import React, { useState } from 'react';

interface ExpandableComponentProps
  extends React.HTMLAttributes<HTMLDivElement> {
  heading: React.ReactNode;
  isExpanded: boolean;
  showTransition?: boolean;
}

export const ExpandableComponent = ({
  heading,
  isExpanded,
  showTransition = false,
  children,
  className,
}: ExpandableComponentProps) => {
  const ref = React.useRef(null);

  // TODO: allow for disabling transition b/c components are of unknown height
  // and can change height at any point in time
  const expandingDivStyle = showTransition
    ? {
        maxHeight:
          isExpanded && !!ref.current
            ? `${(ref.current! as HTMLElement).scrollHeight}px`
            : '0',
      }
    : {
        display: isExpanded ? 'block' : 'none',
      };

  const classes = [
    'expandable-component',
    className,
    isExpanded ? 'active' : '',
  ].join(' ');
  return (
    <div className={classes}>
      <div className="expandable-component-heading">{heading}</div>
      <div
        ref={ref}
        style={expandingDivStyle}
        className="expandable-component-content"
      >
        {children}
      </div>
    </div>
  );
};
