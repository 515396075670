import { useRef } from 'react';

/**
 * Used to run some sort of action that is passed in when the component is about to be put into the DOM.
 * Similiar to `componentWillMount` from class based components. Method will only run exactly once.
 *
 * @param callback Method to call when the component is about to be put into the DOM
 */
export const useComponentWillMount = (callback: () => void) => {
  const willMount = useRef(true);

  if (willMount.current) {
    callback();
  }

  willMount.current = false;
};
