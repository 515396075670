import React, { SVGProps } from 'react';

export const NasDisk = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="35px"
      height="30px"
      viewBox="0 0 35 30"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <title>Nas Disk</title>
      <desc>Created with Sketch.</desc>
      <g
        id="Expert-Dashboard-v1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="Iconogrpahy"
          transform="translate(-286.000000, -271.000000)"
          fill="#9100DA"
        >
          <g id="Nas-Disk" transform="translate(286.000000, 271.000000)">
            <path
              d="M33.3485833,9.33333333 L1.65141667,9.33333333 C0.740833333,9.33333333 0,8.5925 0,7.68191667 L0,1.65141667 C0,0.740833333 0.740833333,0 1.65141667,0 L33.3485833,0 C34.2591667,0 35,0.740833333 35,1.65141667 L35,7.68191667 C35,8.5925 34.2591667,9.33333333 33.3485833,9.33333333 L33.3485833,9.33333333 L33.3485833,9.33333333 Z M1.65141667,1.16666667 C1.38425,1.16666667 1.16666667,1.38425 1.16666667,1.65141667 L1.16666667,7.68191667 C1.16666667,7.94908333 1.38425,8.16666667 1.65141667,8.16666667 L33.3485833,8.16666667 C33.61575,8.16666667 33.8333333,7.94908333 33.8333333,7.68191667 L33.8333333,1.65141667 C33.8333333,1.38425 33.61575,1.16666667 33.3485833,1.16666667 L1.65141667,1.16666667 L1.65141667,1.16666667 Z"
              id="Fill-1"
            ></path>
            <path
              d="M33.3485833,19.25 L1.65141667,19.25 C0.740833333,19.25 0,18.5091667 0,17.5985833 L0,11.5680833 C0,10.6575 0.740833333,9.91666667 1.65141667,9.91666667 L33.3485833,9.91666667 C34.2591667,9.91666667 35,10.6575 35,11.5680833 L35,17.5985833 C35,18.5091667 34.2591667,19.25 33.3485833,19.25 L33.3485833,19.25 L33.3485833,19.25 Z M1.65141667,11.0833333 C1.38425,11.0833333 1.16666667,11.3009167 1.16666667,11.5680833 L1.16666667,17.5985833 C1.16666667,17.86575 1.38425,18.0833333 1.65141667,18.0833333 L33.3485833,18.0833333 C33.61575,18.0833333 33.8333333,17.86575 33.8333333,17.5985833 L33.8333333,11.5680833 C33.8333333,11.3009167 33.61575,11.0833333 33.3485833,11.0833333 L1.65141667,11.0833333 L1.65141667,11.0833333 Z"
              id="Fill-2"
            ></path>
            <path
              d="M33.3485833,29.1666667 L1.65141667,29.1666667 C0.740833333,29.1666667 0,28.4258333 0,27.51525 L0,21.48475 C0,20.5741667 0.740833333,19.8333333 1.65141667,19.8333333 L33.3485833,19.8333333 C34.2591667,19.8333333 35,20.5741667 35,21.48475 L35,27.51525 C35,28.4258333 34.2591667,29.1666667 33.3485833,29.1666667 L33.3485833,29.1666667 L33.3485833,29.1666667 Z M1.65141667,21 C1.38425,21 1.16666667,21.2175833 1.16666667,21.48475 L1.16666667,27.51525 C1.16666667,27.7824167 1.38425,28 1.65141667,28 L33.3485833,28 C33.61575,28 33.8333333,27.7824167 33.8333333,27.51525 L33.8333333,21.48475 C33.8333333,21.2175833 33.61575,21 33.3485833,21 L1.65141667,21 L1.65141667,21 Z"
              id="Fill-3"
            ></path>
            <path
              d="M30.3333333,7 C29.0465,7 28,5.9535 28,4.66666667 C28,3.37983333 29.0465,2.33333333 30.3333333,2.33333333 C31.6201667,2.33333333 32.6666667,3.37983333 32.6666667,4.66666667 C32.6666667,5.9535 31.6201667,7 30.3333333,7 L30.3333333,7 L30.3333333,7 Z M30.3333333,3.5 C29.6899167,3.5 29.1666667,4.02325 29.1666667,4.66666667 C29.1666667,5.31008333 29.6899167,5.83333333 30.3333333,5.83333333 C30.97675,5.83333333 31.5,5.31008333 31.5,4.66666667 C31.5,4.02325 30.97675,3.5 30.3333333,3.5 L30.3333333,3.5 L30.3333333,3.5 Z"
              id="Fill-4"
            ></path>
            <path
              d="M30.3333333,16.9166667 C29.0465,16.9166667 28,15.8701667 28,14.5833333 C28,13.2965 29.0465,12.25 30.3333333,12.25 C31.6201667,12.25 32.6666667,13.2965 32.6666667,14.5833333 C32.6666667,15.8701667 31.6201667,16.9166667 30.3333333,16.9166667 L30.3333333,16.9166667 L30.3333333,16.9166667 Z M30.3333333,13.4166667 C29.6899167,13.4166667 29.1666667,13.9399167 29.1666667,14.5833333 C29.1666667,15.22675 29.6899167,15.75 30.3333333,15.75 C30.97675,15.75 31.5,15.22675 31.5,14.5833333 C31.5,13.9399167 30.97675,13.4166667 30.3333333,13.4166667 L30.3333333,13.4166667 L30.3333333,13.4166667 Z"
              id="Fill-5"
            ></path>
            <path
              d="M30.3333333,26.8333333 C29.0465,26.8333333 28,25.7868333 28,24.5 C28,23.2131667 29.0465,22.1666667 30.3333333,22.1666667 C31.6201667,22.1666667 32.6666667,23.2131667 32.6666667,24.5 C32.6666667,25.7868333 31.6201667,26.8333333 30.3333333,26.8333333 L30.3333333,26.8333333 L30.3333333,26.8333333 Z M30.3333333,23.3333333 C29.6899167,23.3333333 29.1666667,23.8565833 29.1666667,24.5 C29.1666667,25.1434167 29.6899167,25.6666667 30.3333333,25.6666667 C30.97675,25.6666667 31.5,25.1434167 31.5,24.5 C31.5,23.8565833 30.97675,23.3333333 30.3333333,23.3333333 L30.3333333,23.3333333 L30.3333333,23.3333333 Z"
              id="Fill-6"
            ></path>
            <path
              d="M5.83333333,4.95833333 C5.83333333,5.76391667 5.18058333,6.41666667 4.375,6.41666667 C3.56941667,6.41666667 2.91666667,5.76391667 2.91666667,4.95833333 C2.91666667,4.15275 3.56941667,3.5 4.375,3.5 C5.18058333,3.5 5.83333333,4.15275 5.83333333,4.95833333"
              id="Fill-7"
            ></path>
            <path
              d="M10.5,4.95833333 C10.5,5.76391667 9.84725,6.41666667 9.04166667,6.41666667 C8.23608333,6.41666667 7.58333333,5.76391667 7.58333333,4.95833333 C7.58333333,4.15275 8.23608333,3.5 9.04166667,3.5 C9.84725,3.5 10.5,4.15275 10.5,4.95833333"
              id="Fill-8"
            ></path>
            <path
              d="M15.1666667,4.95833333 C15.1666667,5.76391667 14.5139167,6.41666667 13.7083333,6.41666667 C12.90275,6.41666667 12.25,5.76391667 12.25,4.95833333 C12.25,4.15275 12.90275,3.5 13.7083333,3.5 C14.5139167,3.5 15.1666667,4.15275 15.1666667,4.95833333"
              id="Fill-9"
            ></path>
            <path
              d="M5.83333333,14.875 C5.83333333,15.6805833 5.18058333,16.3333333 4.375,16.3333333 C3.56941667,16.3333333 2.91666667,15.6805833 2.91666667,14.875 C2.91666667,14.0694167 3.56941667,13.4166667 4.375,13.4166667 C5.18058333,13.4166667 5.83333333,14.0694167 5.83333333,14.875"
              id="Fill-10"
            ></path>
            <path
              d="M10.5,14.875 C10.5,15.6805833 9.84725,16.3333333 9.04166667,16.3333333 C8.23608333,16.3333333 7.58333333,15.6805833 7.58333333,14.875 C7.58333333,14.0694167 8.23608333,13.4166667 9.04166667,13.4166667 C9.84725,13.4166667 10.5,14.0694167 10.5,14.875"
              id="Fill-11"
            ></path>
            <path
              d="M15.1666667,14.875 C15.1666667,15.6805833 14.5139167,16.3333333 13.7083333,16.3333333 C12.90275,16.3333333 12.25,15.6805833 12.25,14.875 C12.25,14.0694167 12.90275,13.4166667 13.7083333,13.4166667 C14.5139167,13.4166667 15.1666667,14.0694167 15.1666667,14.875"
              id="Fill-12"
            ></path>
            <path
              d="M5.83333333,24.7916667 C5.83333333,25.59725 5.18058333,26.25 4.375,26.25 C3.56941667,26.25 2.91666667,25.59725 2.91666667,24.7916667 C2.91666667,23.9860833 3.56941667,23.3333333 4.375,23.3333333 C5.18058333,23.3333333 5.83333333,23.9860833 5.83333333,24.7916667"
              id="Fill-13"
            ></path>
            <path
              d="M10.5,24.7916667 C10.5,25.59725 9.84725,26.25 9.04166667,26.25 C8.23608333,26.25 7.58333333,25.59725 7.58333333,24.7916667 C7.58333333,23.9860833 8.23608333,23.3333333 9.04166667,23.3333333 C9.84725,23.3333333 10.5,23.9860833 10.5,24.7916667"
              id="Fill-14"
            ></path>
            <path
              d="M15.1666667,24.7916667 C15.1666667,25.59725 14.5139167,26.25 13.7083333,26.25 C12.90275,26.25 12.25,25.59725 12.25,24.7916667 C12.25,23.9860833 12.90275,23.3333333 13.7083333,23.3333333 C14.5139167,23.3333333 15.1666667,23.9860833 15.1666667,24.7916667"
              id="Fill-15"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
};
