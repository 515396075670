import React, { SVGProps } from 'react';

export const GoodStreamingIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="36"
      height="34"
      viewBox="0 0 36 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M11.995 12.003C6.481 12.003 1.995 16.489 1.995 22.003C1.995 27.516 6.481 32.003 11.995 32.003C17.509 32.003 21.995 27.516 21.995 22.003C21.995 16.489 17.509 12.003 11.995 12.003ZM8.995 27.003V18.003L17.995 22.503L8.995 27.003Z"
        fill="black"
      />
      <circle className="override-color" cx="26" cy="10" r="10" />
      <path
        className="override-icon-good"
        d="M26 0C20.477 0 16 4.477 16 10C16 15.523 20.477 20 26 20C31.523 20 36 15.523 36 10C36 4.477 31.522 0 26 0ZM25.25 15.75L20.25 12L21.75 10L24.75 12.25L30 5.25L32 6.75L25.25 15.75Z"
      />
    </svg>
  );
};
