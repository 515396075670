import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { merge, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { useEffectOnce, useRxApi } from '../../../hooks';
import { LoadingSpinner } from '../../../icons';
import { homeGraphApi } from '../../../page-api';
import { IClassifiedDevice } from '../../../services';
import {
  analytics,
  classifiedDeviceFormatter,
  DashboardEventType,
  IFormattedClassifiedDevice,
  UserAnalyticEvent,
} from '../../../utils';
import { H1 } from '../../CommonComponents';
import { ScanComponent, withLoadingSkeleton } from '../index';
import { DisplayIcon } from './display-icons';
import { NetworkDevicesGraphView } from './graph-view';
import { NetworkDevicesListView } from './list-view';

const WIFI_BAND_2_4_GHZ = 2.4;
const WIFI_BAND_5_GHZ = 5;

interface NetworkDevicesWrapperProps {
  isLoading?: boolean;
}

export type DeviceViewType = 'list' | 'graph' | 'nodata';
export const NetworkDevicesWrapper = ({
  isLoading,
}: NetworkDevicesWrapperProps) => {
  const [deviceView, setDeviceView] = useState<DeviceViewType>('list');
  const [graphData, setGraphData] = useState<any[]>([]);
  const [hasRouter, setHasRouter] = useState(false);
  const [hasWired, setHasWired] = useState(false);
  const [deviceList, setDeviceList] = useState<IFormattedClassifiedDevice[]>(
    []
  );
  const [isDeviceCompatible, setIsDeviceCompatible] = useState(true);

  const handleGraphDataFormat = useCallback(
    (classifiedDevices: IClassifiedDevice[]) => {
      const onlineDevices = classifiedDevices.filter(
        (device) => device.online === 'true'
      );
      const graph: any[] = [];
      const wiredObj = {
        devices: [] as IClassifiedDevice[],
        wired: true,
        router: {},
      };
      const wifi5gObj = {
        devices: [] as IClassifiedDevice[],
        band: WIFI_BAND_5_GHZ,
        router: {},
        ssid: '',
      };
      const wifi2gObj = {
        devices: [] as IClassifiedDevice[],
        band: WIFI_BAND_2_4_GHZ,
        router: {},
        ssid: '',
      };
      const wifiNullObj = {
        devices: [] as IClassifiedDevice[],
        router: {},
        band: 'null',
      };
      let router = {};
      let ssid5g = '',
        ssid2g = '';

      const formattedDevices = onlineDevices.map((d) => {
        const isRouter = d.kind === 'router';
        const device = classifiedDeviceFormatter(d);
        const band = (device.operatingParameters || {}).band || null;
        let wifiBandNumber: number | null = null;
        if (band) {
          wifiBandNumber = parseFloat(band);
        }
        if (device.operatingParameters && device.operatingParameters.wired) {
          setHasWired(true);
          if (isRouter) {
            router = device;
            setHasRouter(true);
          } else {
            wiredObj.devices.push(device);
          }
        } else if (isRouter) {
          router = device;
          setHasRouter(true);
        } else if (wifiBandNumber === WIFI_BAND_5_GHZ) {
          wifi5gObj.devices.push(device);
          ssid5g = device.operatingParameters!.ssId;
        } else if (wifiBandNumber === WIFI_BAND_2_4_GHZ) {
          wifi2gObj.devices.push(device);
          ssid2g = device.operatingParameters!.ssId;
        } else if (wifiBandNumber === null && !device.wired) {
          wifiNullObj.devices.push(device);
        }
        return device;
      });
      wiredObj.router = wifi5gObj.router = wifi2gObj.router = wifiNullObj.router = router;
      wifi2gObj.ssid = ssid2g;
      wifi5gObj.ssid = ssid5g;
      graph.push(wifi5gObj);
      graph.push(wifi2gObj);
      if (wifiNullObj.devices.length > 0) {
        graph.push(wifiNullObj);
      }
      if (hasWired) {
        if (!wiredObj.hasOwnProperty('router')) {
          wiredObj.router = wifi5gObj.router;
        }
        graph.unshift(wiredObj);
      }
      //Setting up the Maximum number of devices limit per category as 29
      for (const g of graph) {
        g.devices = g.devices.length > 29 ? g.devices.slice(0, 29) : g.devices;
      }
      return {
        graphData: graph,
        deviceList: formattedDevices,
      };
    },
    [hasWired]
  );

  const { result: classifiedDevices } = useRxApi(
    homeGraphApi.classifiedDevices$,
    {
      initialValue: [],
      onNext: (devices) => {
        if (devices.length === 0) {
          setDeviceView('nodata');
          return;
        } else {
          setDeviceView('list');
        }

        // do nothing if there are no devices, save some cycles.
        const { graphData, deviceList } = handleGraphDataFormat(devices);
        setGraphData(graphData);
        setDeviceList(deviceList);
      },
    }
  );

  const setDeviceViewWithAnalytics = (type: DeviceViewType) => {
    analytics.dispatch(
      UserAnalyticEvent.ToggleDeviceViewButton,
      DashboardEventType.Click,
      {
        deviceView: type,
      }
    );
    setDeviceView(type);
  };

  useEffectOnce(() => {
    // TODO: change this width b/c it will be a half screen
    const windowSizeWatcher = () => {
      setIsDeviceCompatible(window.innerWidth > 1200);
    };
    window.addEventListener('resize', windowSizeWatcher);
    return () => {
      window.removeEventListener('resize', windowSizeWatcher);
    };
  });

  const deviceData = classifiedDevices;
  const totalDeviceCount: number =
    deviceData.length > 0
      ? deviceData.filter((device) => device.online === 'true').length
      : 0;
  const devices = graphData.reduce((sum, x) => sum + x.devices.length, 0);
  const deviceCount = hasRouter ? devices + 1 : devices;

  const hasDevices$ = homeGraphApi.classifiedDevices$.pipe(
    map((result) => {
      return !!result && result.length > 0;
    })
  );

  const isFirstLoadFinished$ = merge(hasDevices$, of(!isLoading));

  return (
    <ScanComponent className="network-devices-section">
      <NetworkDevicesHeading
        isLoading={isLoading}
        deviceView={deviceView}
        totalDeviceCount={totalDeviceCount}
        deviceCount={deviceCount}
        isDeviceCompatible={isDeviceCompatible}
        setDeviceViewWithAnalytics={setDeviceViewWithAnalytics}
      />
      {withLoadingSkeleton(
        <NetworkDevices
          deviceView={deviceView}
          totalDeviceCount={totalDeviceCount}
          isDeviceCompatible={isDeviceCompatible}
          graphData={graphData}
          deviceList={deviceList}
        />,
        isFirstLoadFinished$
      )}
    </ScanComponent>
  );
};

interface NetworkDevicesHeadingProps {
  isLoading?: boolean;
  deviceView: DeviceViewType;
  totalDeviceCount: number;
  deviceCount: number;
  isDeviceCompatible: boolean;
  setDeviceViewWithAnalytics: (type: DeviceViewType) => void;
}

export const NetworkDevicesHeading = ({
  isLoading,
  deviceView,
  totalDeviceCount,
  deviceCount,
  isDeviceCompatible,
  setDeviceViewWithAnalytics,
}: NetworkDevicesHeadingProps) => {
  const { t: translateText } = useTranslation();

  const ConnectedDevicesHeading = () =>
    deviceView === 'graph' && totalDeviceCount > deviceCount ? (
      <H1>
        {translateText('Showing')} <strong>{deviceCount}</strong>{' '}
        {translateText('of')} <strong>{totalDeviceCount}</strong>{' '}
        {translateText('connected_devices')} {isLoading && <LoadingSpinner />}
      </H1>
    ) : (
      <H1>
        {totalDeviceCount > 0 && `${totalDeviceCount} `}
        {translateText('connected_device')}
        {totalDeviceCount !== 1 && 's'} {isLoading && <LoadingSpinner />}
      </H1>
    );

  return (
    <div className="scan-subheading connected-devices v2 row">
      <ConnectedDevicesHeading />
      <div className="icons">
        <span>{translateText('view')}</span>
        {isDeviceCompatible && (
          <DisplayIcon
            className={`graph-icon ${
              deviceView === 'graph' ? 'active' : 'inactive'
            }`}
            iconType="graph"
            onIconClick={setDeviceViewWithAnalytics}
          />
        )}
        <DisplayIcon
          className={`list-icon ${
            deviceView === 'list' ? 'active' : 'inactive'
          }`}
          iconType="list"
          onIconClick={setDeviceViewWithAnalytics}
        />
      </div>
    </div>
  );
};

interface NetworkDevicesProps {
  deviceView: DeviceViewType;
  totalDeviceCount: number;
  isDeviceCompatible: boolean;
  graphData: any[];
  deviceList: IFormattedClassifiedDevice[];
}

export const NetworkDevices = ({
  deviceView,
  totalDeviceCount,
  isDeviceCompatible,
  graphData,
  deviceList,
}: NetworkDevicesProps) => {
  return (
    <>
      {isDeviceCompatible && deviceView === 'graph' ? (
        <NetworkDevicesGraphView
          graphData={graphData}
          totalDeviceCount={totalDeviceCount}
        />
      ) : (
        <NetworkDevicesListView devices={deviceList} />
      )}
    </>
  );
};
