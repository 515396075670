import React from 'react';

export const XSvg = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        id="Union"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.58574 8.00008L0.292847 14.293L1.70706 15.7072L7.99995 9.41429L14.2928 15.7072L15.7071 14.293L9.41417 8.00008L15.7071 1.70718L14.2928 0.292969L7.99995 6.58586L1.70706 0.292969L0.292847 1.70718L6.58574 8.00008Z"
        fill="black"
      />
    </svg>
  );
};
