import { EffectCallback, useEffect } from 'react';

/**
 * This hook can be used to avoid `eslint` automatically putting in dependencies. For example
 * say you want a PageLoad event to fire, you could use this hook to fire your event if everything you need at call time
 * is set up. Otherwise you will probably still need a `useEffect`
 * @param effect Method to call exactly once.
 */
export const useEffectOnce = (effect: EffectCallback) => {
  useEffect(effect, []);
};
