export enum RoutePaths {
  Default = '/',
  Pin = '/pin',
  ScanInstructions = '/scan-instructions',
  Scanning = '/scan-running',
  ScanResults = '/scan-results',
  ScanFailed = '/scan-failed',
  NoMatch = '/no-match',
  Login = '/login',
  Logout = '/logout',
  Home = '/home',
  IncorrectPartner = '/incorrect-partner',
}

export interface RouteProperties {
  carrierRoute?: {
    partners: string[];
    fallbackUrl: RoutePaths;
  };
}

export const RoutePathProperties: Record<RoutePaths, RouteProperties> = {
  [RoutePaths.Default]: {},
  [RoutePaths.Home]: {},
  [RoutePaths.Login]: {},
  [RoutePaths.NoMatch]: {},
  [RoutePaths.Pin]: {},
  [RoutePaths.ScanInstructions]: {},
  [RoutePaths.ScanFailed]: {},
  [RoutePaths.ScanResults]: {},
  [RoutePaths.Scanning]: {},
  [RoutePaths.Logout]: {},
  [RoutePaths.IncorrectPartner]: {},
};
