import React from 'react';
import { PartnerKeyProperties, PartnerKeys } from '../../constants';
import {
  ContainerSteps,
  PreScanContentContainer,
  RoutePaths,
} from '../../components';
import { useEffectOnce } from '../../hooks';
import { ScanInstructionProps } from '../contracts';

const AsurionScanLogo = require('../../img/default/homeplus/asurion-scan-intro.png');
const AsurionScanWifiCheck = require('../../img/default/homeplus/asurion-scan-wifi-check.png');
const AsurionScanPinGen = require('../../img/default/homeplus/asurion-scan-pin-gen.png');

export const HomeplusScanInstructionsContent = ({
  navigationProps$,
  navigationButtonEnabled$,
}: ScanInstructionProps) => {
  useEffectOnce(() => {
    navigationButtonEnabled$.next(true);
    navigationProps$.next({
      pathname: RoutePaths.Pin,
    });
  });

  const contentSteps: ContainerSteps[] = [
    {
      contentHtml: `Confirm the <strong>${
        PartnerKeyProperties[PartnerKeys.homeplus].appName
      } app </strong> has been downloaded. <strong>${
        PartnerKeyProperties[PartnerKeys.homeplus].appName
      }</strong> can be found in the Apple App Store and Google Play Store.`,
      imageSrc: AsurionScanLogo,
      imageAltText: `Asurion Scan logo`,
    },
    {
      contentHtml: `After continuing through onboarding screens, the customer should confirm their home network wifi and start the scan.`,
      imageSrc: AsurionScanWifiCheck,
      imageAltText: `Asurion Scan wifi check`,
    },
    {
      contentHtml: `The scan will take up to two minutes. After the scan is complete, ask the customer to tap on <strong>Get help with your scan</strong> to see their code. You${"'"}ll enter their code on the next screen.`,
      imageSrc: AsurionScanPinGen,
      imageAltText: `Asurion Scan pin generation`,
    },
  ];

  return (
    <PreScanContentContainer
      steps={contentSteps}
      customClass="asurion-scan-content-step"
    />
  );
};
