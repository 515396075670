import React, { ReactNode } from 'react';

export interface PreScanContentStepProps {
  stepNumberTitle?: string;
  contentHtml: string;
  imageSrc?: string;
  imageWidth?: string;
  imageAltText?: string;
  children?: ReactNode;
  customClass?: string;
}

export const PreScanContentStep = ({
  stepNumberTitle,
  contentHtml,
  imageSrc,
  imageAltText,
  children,
  customClass,
}: PreScanContentStepProps) => {
  return (
    <div className={['pre-scan-step', customClass].join(' ')}>
      {stepNumberTitle && (
        <div className="pre-scan-step__title">{stepNumberTitle}</div>
      )}
      <div
        className="pre-scan-step__content"
        dangerouslySetInnerHTML={{ __html: contentHtml }}
      />
      {imageSrc && (
        <img
          className="pre-scan-step__img"
          width="370px"
          alt={imageAltText}
          src={imageSrc}
        />
      )}
      <div className="pre-scan-step__children">{children}</div>
    </div>
  );
};

export const PreScanContentStepV2 = ({
  stepNumberTitle,
  contentHtml,
  imageSrc,
  imageWidth = '370px',
  imageAltText,
  children,
  customClass,
}: PreScanContentStepProps) => {
  return (
    <div className={['pre-scan-component-step', customClass].join(' ')}>
      {stepNumberTitle && (
        <div className="step-title">
          <b>{stepNumberTitle}</b>
        </div>
      )}
      <div
        className="step-content"
        dangerouslySetInnerHTML={{ __html: contentHtml }}
      />
      {imageSrc && (
        <div className="step-content-img-container">
          <img
            className="pre-scan-step__img"
            width={imageWidth}
            alt={imageAltText}
            src={imageSrc}
          />
        </div>
      )}
      <div>{children}</div>
    </div>
  );
};
