import React from 'react';
import { PartnerKeys } from '../../constants';
import { AttLogo } from './att/AttLogo';
import { VerizonLogo } from './vzw/VerizonLogo';
import { HomeplusLogo } from './homeplus/HomeplusLogo';
import { useRxApi } from '../../hooks';
import { themeApi } from '../../page-api';
import { map } from 'rxjs/operators';

interface IPartnerLogo {
  partner: PartnerKeys;
}
export const PartnerLogo = ({ partner }: IPartnerLogo) => {
  const { result: isDarkMode } = useRxApi(
    themeApi.currentTheme$.pipe(map(() => themeApi.isDarkMode)),
    {
      initialValue: false,
    }
  );
  switch (partner) {
    case PartnerKeys.att:
      return <AttLogo isDarkMode={isDarkMode} />;
    case PartnerKeys.vzw:
      return <VerizonLogo isDarkMode={isDarkMode} />;
    case PartnerKeys.homeplus:
      return <HomeplusLogo isDarkMode={isDarkMode} />;
    default:
      return <></>;
  }
};
