import React, { MouseEvent, ReactNode } from 'react';
import { Chevron } from '../../icons/Chevron';
import { BaseImage } from '../image/BaseImage';

interface AccordionProps {
  label: string;
  children: ReactNode;
  initiallyExpanded?: boolean;
  leftIconSrc?: string;
  rightIconSrc?: string;
  className?: string;
  onAccordionExpanded?: () => void;
}

export const Accordion = ({
  className,
  leftIconSrc,
  label,
  initiallyExpanded = false,
  children,
  onAccordionExpanded,
}: AccordionProps) => {
  const onButtonClick = (event: MouseEvent<HTMLButtonElement>) => {
    if (!event.currentTarget.classList.contains('active')) {
      onAccordionExpanded?.();
    }

    event.currentTarget.classList.toggle('active');
    const panel = event.currentTarget.nextElementSibling as HTMLElement;
    if (!panel) return;
    const currentPanelDisplayStyle = panel.style.display;
    panel.style.display =
      currentPanelDisplayStyle === 'block' ? 'none' : 'block';
  };

  const panelStyle = {
    display: initiallyExpanded ? 'block' : 'none',
  };

  const classes = ['a-accordion', className].join(' ');
  return (
    <div className={classes}>
      <button
        className={
          initiallyExpanded ? 'accordion-button active' : 'accordion-button'
        }
        type="button"
        onClick={onButtonClick}
      >
        <span className="text-container">
          <span className="text">
            {leftIconSrc && (
              <BaseImage className="icon-left" src={leftIconSrc} size={12} />
            )}
            {label}
          </span>
          <Chevron />
        </span>
      </button>
      <div style={panelStyle} className="panel">
        {children}
      </div>
    </div>
  );
};
