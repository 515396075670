import { IContentfulExpertTip, IContentfulExpertTipModel } from '../contracts';
import { BaseContentfulTransformer } from './BaseContentfulTransformer';
import { IContentTransformer } from './IContentTransformer';

export class ExpertTipTransformer
  extends BaseContentfulTransformer
  implements
    IContentTransformer<IContentfulExpertTipModel, IContentfulExpertTip> {
  /**
   * Transforms a Contentful Tip Model into a UI friendly Tip.
   *
   * @param {Entry<IContentfulExpertTip>} rawContent
   * @returns {IContentfulExpertTip}
   * @memberof ExpertTipTransformer
   */
  public transform(content: IContentfulExpertTipModel): IContentfulExpertTip {
    return {
      contentfulDisplayName: content.contentfulDisplayName,
      id: content.id,
      title: content.title,
      helixArticleUrl: content.helixArticleUrl,
      descriptionHtml: this.getHtmlText(content.description),
      howToHelpHtml: this.getHtmlText(content.howToHelp),
      additionalHelpHtml: content.additionalHelp
        ? this.getHtmlText(content.additionalHelp)
        : undefined,
    };
  }
}
