import React from 'react';

import { wifiWizardApi } from '../../../page-api';
import { useRxApi } from '../../../hooks';
import { QuestionSet } from './QuestionSet';
import { IWifiWizardQuestion } from '../../../services';

interface WiredQuestionSetProps {
  onQuestionsFinished?: (
    questionsCount: number,
    answeredQuestionsCount: number
  ) => void;
  setIsExpanded: (isExpanded: boolean) => void;
}

export const WiredQuestionSet = ({
  onQuestionsFinished,
  setIsExpanded,
}: WiredQuestionSetProps) => {
  const { result: questions } = useRxApi(wifiWizardApi.questionBatch$, {
    initialValue: [],
  });
  const loading = questions.length === 0;

  const questionWasAnswered = (
    quest: IWifiWizardQuestion,
    answers: string[]
  ) => {
    wifiWizardApi.submitAnswer({ questionId: quest.id, answerId: answers });
  };

  return (
    <QuestionSet
      isLoading={loading}
      questions={questions}
      onQuestionSubmit={questionWasAnswered}
      onQuestionsFinished={onQuestionsFinished}
      setIsExpanded={setIsExpanded}
    />
  );
};
