import React, { SVGProps } from 'react';

export const Wifi = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="47px"
      height="31px"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 47 31"
      {...props}
    >
      <path
        fill="#9100DA"
        fillRule="evenodd"
        d="M13.488 20.045a.774.774 0 0 0 0 1.136.81.81 0 0 0 1.158 0c4.717-4.95 12.991-4.95 18.37.08a.903.903 0 0 0 .58.244.758.758 0 0 0 .578-.243.774.774 0 0 0 0-1.136c-6.04-5.762-15.39-5.762-20.686-.081zm9.598-12.092c-6.288 0-12.08 2.434-16.218 6.817a.774.774 0 0 0 0 1.136.81.81 0 0 0 1.158 0c3.807-4.058 9.185-6.33 14.978-6.33 5.875 0 11.501 2.272 15.97 6.41a.903.903 0 0 0 .579.244.758.758 0 0 0 .579-.243.774.774 0 0 0 0-1.136 24.58 24.58 0 0 0-17.046-6.898zm23.666 1.542c-13.736-12.66-34.588-12.66-46.504 0a.774.774 0 0 0 0 1.136.81.81 0 0 0 1.159 0c11.336-12.01 31.195-12.01 44.186.081a.903.903 0 0 0 .58.243.758.758 0 0 0 .579-.243c.33-.406.33-.893 0-1.217zM23.086 22.398a4.301 4.301 0 1 0 0 8.602c2.4 0 4.303-1.948 4.303-4.301 0-2.435-1.903-4.301-4.303-4.301zm0 6.898c-1.49 0-2.648-1.217-2.648-2.678 0-1.46 1.159-2.678 2.648-2.678 1.49 0 2.648 1.217 2.648 2.678 0 1.46-1.158 2.678-2.648 2.678z"
      />
    </svg>
  );
};
