import React, { useCallback } from 'react';
import { getPartner, getScanInstructionsContentV2 } from '../../../partner';
import { useRxApi } from '../../../hooks';
import { BehaviorSubject } from 'rxjs';
import { LocationDescriptorObject } from 'history';
import { BaseButton, RoutePaths } from '../..';
import { UserAnalyticEvent } from '../../../utils';
import { ResultsPageState } from '../../../pages';

const navigationButtonEnabled$ = new BehaviorSubject<boolean>(false);
const navigationProps$ = new BehaviorSubject<LocationDescriptorObject>({});

interface ScanInstructionsComponentProps {
  setResultsPageState: (resultsPageState: ResultsPageState) => void;
}

export const ScanInstructionsComponent = ({
  setResultsPageState,
}: ScanInstructionsComponentProps) => {
  const partner = getPartner();
  const { result: navigationProps } = useRxApi(navigationProps$, {
    initialValue: {},
  });
  const {
    result: isNavigationButtonEnabled,
  } = useRxApi(navigationButtonEnabled$, { initialValue: false });

  const onNextButtonClick = useCallback(() => {
    setResultsPageState(ResultsPageState.Scanning);
  }, [setResultsPageState]);

  const ScanContent = getScanInstructionsContentV2(partner, {
    navigationButtonEnabled$,
    navigationProps$,
    setResultsPageState,
  });

  return (
    <div className="pre-scan-component">
      <div className="pre-scan-component-content">{ScanContent}</div>
      {isNavigationButtonEnabled && (
        <BaseButton
          variant="primary"
          analyticName={UserAnalyticEvent.BeginPollingButton}
          onClick={onNextButtonClick}
        >
          <span>Fetch scan data</span>
        </BaseButton>
      )}
    </div>
  );
};
