export const colors = {
  white: '#ffffff',
  lightGrey: '#f8f9f9',
  lightGreyHover: '#f1f2f2',
  lightMidGrey: '#D8D8D8',
  midGrey: '#9c9c9c',
  darkGrey: '#5b5b5b',
  urgent: '#f9d51c',
  caution: '#e2b425',
  good: '#30cfa3',
  babyBlue: '#d5d8de',
  lightBlue: '#add4ec',
  darkBlue: '#000939',
  dullBlue: '#5579B0',
  labelBlue: '#86B9D9',
  blue: '#1F93D9',
  primaryBlue: '#038cdb',
  red: '#e8554c',
  redDarkAccent: '#f06a62',
  yellow: '#f8d338',
  babyGreen: '#7ed70e',
  orange: '#f8d338',
  lime: '#7ed70e',
  green: '#30cfa3',
  grey: '#b8bec7',
};
