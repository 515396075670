import React from 'react';

export const VerizonLogo = ({ isDarkMode }) => {
  const fillColor = isDarkMode ? 'white' : 'black';
  return (
    <svg
      width="148"
      height="33"
      viewBox="0 0 148 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M144.244 0L136.187 17.2366L133.157 10.7055H129.88L134.863 21.3886H137.511L147.498 0H144.244Z"
        fill="#D52B1E"
      />
      <path
        d="M109.681 32.0043H116.167V20.1317C116.167 17.416 117.738 15.5083 120.072 15.5083C122.317 15.5083 123.484 17.0794 123.484 19.3462V32.0043H129.97V18.1118C129.97 13.3986 127.142 10.077 122.564 10.077C119.646 10.077 117.604 11.3338 116.078 13.6231H115.943V10.7054H109.659L109.681 32.0043ZM96.9333 10.1219C90.1553 10.1219 85.6218 14.9921 85.6218 21.4109C85.6218 27.7849 90.1553 32.7 96.9333 32.7C103.711 32.7 108.245 27.7849 108.245 21.4109C108.267 14.9921 103.711 10.1219 96.9333 10.1219ZM96.8884 27.8971C93.8136 27.8971 92.1753 25.3161 92.1753 21.4109C92.1753 17.4609 93.7912 14.9248 96.8884 14.9248C99.9631 14.9248 101.669 17.4609 101.669 21.4109C101.691 25.3161 99.9856 27.8971 96.8884 27.8971ZM67.1284 32.0043H85.3524V26.8872H75.0285V26.7525L84.9036 15.6879V10.683H67.1284V15.8225H77.1157V15.9572L67.1284 27.1116V32.0043ZM58.8018 32.0043H65.3329V10.7054H58.8018V32.0043ZM43.6525 32.0043H50.1611V22.2189C50.1611 17.7751 52.8319 15.8001 56.8941 16.3612H57.0288V10.7503C56.6922 10.6157 56.3106 10.5932 55.7046 10.5932C53.1685 10.5932 51.4628 11.7603 50.004 14.2515H49.8693V10.7054H43.6525V32.0043ZM31.7126 27.9195C28.8398 27.9195 27.0892 26.0567 26.7526 23.184H42.1039C42.1488 18.6055 40.9369 14.9472 38.4007 12.658C36.6053 10.9972 34.2487 10.077 31.2413 10.077C24.8225 10.077 20.3787 14.9472 20.3787 21.3212C20.3787 27.74 24.6205 32.6327 31.6677 32.6327C34.3385 32.6327 36.4482 31.9145 38.1988 30.725C40.0616 29.4457 41.4082 27.5605 41.7673 25.6976H35.528C34.922 27.0891 33.6203 27.9195 31.7126 27.9195ZM31.3759 14.7228C33.6652 14.7228 35.2362 16.4285 35.5055 18.9197H26.7975C27.3137 16.4061 28.6827 14.7228 31.3759 14.7228ZM7.40634 32.0043H14.2291L21.6355 10.7054H15.1044L10.9524 24.3959H10.8626L6.71059 10.7054H0L7.40634 32.0043ZM58.8018 3.11954H65.3329V8.90995H58.8018V3.11954Z"
        fill={fillColor}
      />
    </svg>
  );
};
