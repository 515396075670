import React, { SVGProps } from 'react';

export const Mp3Player = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="35"
      viewBox="0 0 24 35"
      {...props}
    >
      <path
        fill="#9100DA"
        fillRule="evenodd"
        d="M22.054 0H1.946C.843 0 0 .843 0 1.944v31.112C0 34.157.843 35 1.946 35h20.108C23.157 35 24 34.157 24 33.056V1.944C24 .843 23.157 0 22.054 0zm.649 33.056c0 .388-.26.648-.649.648H1.946c-.39 0-.649-.26-.649-.648V1.944c0-.388.26-.648.649-.648h20.108c.39 0 .649.26.649.648v31.112zM2.595 18.796h18.81V2.593H2.595v16.203zM3.892 3.89h16.216V17.5H3.892V3.889zm8.757 16.204c-3.373 0-6.163 2.787-6.163 6.157s2.79 6.157 6.163 6.157 6.162-2.787 6.162-6.157-2.79-6.157-6.162-6.157zm0 11.018c-2.66 0-4.865-2.204-4.865-4.861 0-2.657 2.205-4.861 4.865-4.861s4.865 2.204 4.865 4.861c0 2.657-2.206 4.861-4.865 4.861zM5.514 7.778a.59.59 0 0 0 .454-.195L7.914 5.64a.626.626 0 0 0 0-.908.627.627 0 0 0-.909 0L5.06 6.676a.626.626 0 0 0 0 .907c.13.13.26.195.455.195zm5.383-3.047L5.06 10.565a.626.626 0 0 0 0 .907c.13.13.26.195.455.195a.59.59 0 0 0 .454-.195l5.837-5.833a.626.626 0 0 0 0-.908.627.627 0 0 0-.908 0z"
      />
    </svg>
  );
};
