import React from 'react';
import {
  PreScanContentStep,
  PreScanContentStepProps,
  PreScanContentStepV2,
} from './PreScanContentStep';

export interface ContainerSteps
  extends Omit<PreScanContentStepProps, 'stepNumberTitle'> {}

interface PreScanContentContainerProps {
  steps: ContainerSteps[];
  customClass?: string;
  v2?: boolean;
  showStepNumber?: boolean;
}

export const PreScanContentContainer = ({
  steps,
  customClass,
  v2 = false,
  showStepNumber = true,
}: PreScanContentContainerProps) => {
  const StepComponents: any[] = steps.map((step, index) => {
    const stepNumberTitle = `Step ${index + 1} of ${steps.length}`;
    const VersionedPreScanContentStep = v2
      ? PreScanContentStepV2
      : PreScanContentStep;
    return (
      <VersionedPreScanContentStep
        key={stepNumberTitle}
        {...step}
        {...(showStepNumber && { stepNumberTitle })}
        customClass={customClass}
      />
    );
  });
  return (
    <div className={v2 ? 'pre-scan-component-steps' : 'pre-scan-page__steps'}>
      {StepComponents}
    </div>
  );
};
