import React from 'react';
import { useTranslation } from 'react-i18next';
import { merge, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { useRxApi } from '../../../hooks';
import { homeGraphApi } from '../../../page-api';
import { ScanComponent, withLoadingSkeleton } from '../index';
import { StreamingQualityIconWrapper } from './StreamingQualityIconWrapper';

interface StreamingQualityV2Props {
  isLoading?: boolean;
}

export const StreamingQualityV2 = ({ isLoading }: StreamingQualityV2Props) => {
  const { result: capabilities } = useRxApi(homeGraphApi.capabilities$, {
    initialValue: {},
  });

  const browsing = !!(
    capabilities.webBrowsing && capabilities.webBrowsing === 'true'
  );
  const gaming = !!(capabilities.gaming && capabilities.gaming === 'true');
  const video_chat = !!(
    capabilities.videoChat && capabilities.videoChat === 'true'
  );
  const streaming = !!(
    capabilities.videoStreaming && capabilities.videoStreaming === 'true'
  );
  const ultra_hd = !!(
    capabilities.uhdVideoStreaming && capabilities.uhdVideoStreaming === 'true'
  );

  const streamingCapabilities: StreamingCapabilities = {
    browsing,
    gaming,
    video_chat,
    streaming,
    ultra_hd,
  };

  const hasResults$ = homeGraphApi.capabilities$.pipe(
    map((result) => !!result && Object.keys(result).length > 0)
  );

  const isFirstLoadFinished$ = merge(hasResults$, of(!isLoading));

  return (
    <ScanComponent className="streaming-quality-V2">
      <StreamingQualityHeading />
      {withLoadingSkeleton(
        <StreamingQuality capabilities={streamingCapabilities} />,
        isFirstLoadFinished$
      )}
    </ScanComponent>
  );
};

export type StreamingCapabilityType =
  | 'browsing'
  | 'gaming'
  | 'video_chat'
  | 'streaming'
  | 'ultra_hd';

type StreamingCapabilities = {
  [K in StreamingCapabilityType]: boolean;
};

interface StreamingQualityProps {
  capabilities: StreamingCapabilities;
}

export const StreamingQuality = ({ capabilities }: StreamingQualityProps) => {
  return (
    <div className="streaming-column">
      {Object.keys(capabilities).map((capability) => (
        <StreamingQualityIconWrapper
          key={capability}
          streamingType={capability as StreamingCapabilityType}
          isGood={capabilities[capability]}
        />
      ))}
    </div>
  );
};

export const StreamingQualityHeading = () => {
  const { t } = useTranslation();
  return <h1 className="scan-subheading">{t('streaming_quality')}</h1>;
};
