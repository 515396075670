import React, { SVGProps } from 'react';

export const PoorGamingIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="35"
      height="34"
      viewBox="0 0 35 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0)">
        <path
          d="M18.9971 21.998C18.9971 22.5503 18.5494 22.998 17.9971 22.998C17.4448 22.998 16.9971 22.5503 16.9971 21.998C16.9971 21.4458 17.4448 20.998 17.9971 20.998C18.5494 20.998 18.9971 21.4458 18.9971 21.998Z"
          fill="black"
        />
        <path
          d="M17.9971 26.998C18.5494 26.998 18.9971 26.5503 18.9971 25.998C18.9971 25.4458 18.5494 24.998 17.9971 24.998C17.4448 24.998 16.9971 25.4458 16.9971 25.998C16.9971 26.5503 17.4448 26.998 17.9971 26.998Z"
          fill="black"
        />
        <path
          d="M20.998 23.998C20.998 24.5503 20.5503 24.998 19.998 24.998C19.4458 24.998 18.998 24.5503 18.998 23.998C18.998 23.4458 19.4458 22.998 19.998 22.998C20.5503 22.998 20.998 23.4458 20.998 23.998Z"
          fill="black"
        />
        <path
          d="M15.998 24.998C16.5503 24.998 16.998 24.5503 16.998 23.998C16.998 23.4458 16.5503 22.998 15.998 22.998C15.4458 22.998 14.998 23.4458 14.998 23.998C14.998 24.5503 15.4458 24.998 15.998 24.998Z"
          fill="black"
        />
        <path
          d="M4.99805 20.998H6.99805V22.998H8.99805V24.998H6.99805V26.998H4.99805V24.998H2.99805V22.998H4.99805V20.998Z"
          fill="black"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.998 17.998H19.998C22.203 17.998 23.998 19.793 23.998 21.998V32.998C23.998 33.551 23.551 33.998 22.998 33.998H20.998C20.732 33.998 20.479 33.894 20.291 33.706L16.584 29.998H7.41205L3.70505 33.706C3.51805 33.894 3.26405 33.998 2.99805 33.998H0.998047C0.445047 33.998 -0.00195312 33.551 -0.00195312 32.998V21.998C-0.00195312 19.793 1.79305 17.998 3.99805 17.998H8.99805V15.998C8.99805 15.446 9.44505 14.998 9.99805 14.998H10.998V13.998C10.998 12.895 10.102 11.998 8.99805 11.998H3.99805V9.99805H8.99805C11.203 9.99805 12.998 11.793 12.998 13.998V14.998H13.998C14.551 14.998 14.998 15.446 14.998 15.998V17.998ZM12.998 16.998H10.998V17.998H12.998V16.998ZM21.412 31.998H21.998V21.998C21.998 20.895 21.102 19.998 19.998 19.998H3.99805C2.89405 19.998 1.99805 20.895 1.99805 21.998V31.998H2.58405L6.29105 28.291C6.47805 28.104 6.73205 27.998 6.99805 27.998H16.998C17.264 27.998 17.517 28.104 17.705 28.291L21.412 31.998Z"
          fill="black"
        />
      </g>
      <path
        className="override-icon-poor"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25 20C30.5228 20 35 15.5228 35 10C35 4.47715 30.5228 0 25 0C19.4772 0 15 4.47715 15 10C15 15.5228 19.4772 20 25 20Z"
      />
      <path
        className="override-color"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25.8333 4.16667H24.1667V5.83333H25.8333V4.16667ZM25.8333 7.49998H24.1667V15H25.8333V7.49998Z"
      />
      <defs>
        <clipPath id="clip0">
          <rect
            width="24"
            height="24"
            className="override-color"
            transform="translate(0 10)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
