import React from 'react';

export const SpinnerSvg = (props) => {
  return (
    <svg
      width="200px"
      height="200px"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 100 100"
      preserveAspectRatio="xMidYMid"
      style={{
        backgroundImage: 'none',
        backgroundPosition: 'initial initial',
        backgroundRepeat: 'initial initial',
      }}
    >
      <g>
        <circle cx="73.801" cy="68.263" fill="#9aba13" r="3" {...props}>
          <animateTransform
            attributeName="transform"
            type="rotate"
            calcMode="spline"
            values="0 50 50;360 50 50"
            keyTimes="0;1"
            keySplines="0.5 0 0.5 1"
            repeatCount="indefinite"
            dur="1.5s"
            begin="0s"
          />
        </circle>
        <circle cx="68.263" cy="73.801" fill="#6864E9" r="3">
          <animateTransform
            attributeName="transform"
            type="rotate"
            calcMode="spline"
            values="0 50 50;360 50 50"
            keyTimes="0;1"
            keySplines="0.5 0 0.5 1"
            repeatCount="indefinite"
            dur="1.5s"
            begin="-0.062s"
          />
        </circle>
        <circle cx="61.481" cy="77.716" fill="#9100DA" r="3">
          <animateTransform
            attributeName="transform"
            type="rotate"
            calcMode="spline"
            values="0 50 50;360 50 50"
            keyTimes="0;1"
            keySplines="0.5 0 0.5 1"
            repeatCount="indefinite"
            dur="1.5s"
            begin="-0.125s"
          />
        </circle>
        <circle cx="53.916" cy="79.743" fill="#C9FC00" r="3">
          <animateTransform
            attributeName="transform"
            type="rotate"
            calcMode="spline"
            values="0 50 50;360 50 50"
            keyTimes="0;1"
            keySplines="0.5 0 0.5 1"
            repeatCount="indefinite"
            dur="1.5s"
            begin="-0.187s"
          />
        </circle>
        <circle cx="46.084" cy="79.743" fill="#6864E9" r="3">
          <animateTransform
            attributeName="transform"
            type="rotate"
            calcMode="spline"
            values="0 50 50;360 50 50"
            keyTimes="0;1"
            keySplines="0.5 0 0.5 1"
            repeatCount="indefinite"
            dur="1.5s"
            begin="-0.25s"
          />
        </circle>
        <circle cx="38.519" cy="77.716" fill="#9100DA" r="3">
          <animateTransform
            attributeName="transform"
            type="rotate"
            calcMode="spline"
            values="0 50 50;360 50 50"
            keyTimes="0;1"
            keySplines="0.5 0 0.5 1"
            repeatCount="indefinite"
            dur="1.5s"
            begin="-0.312s"
          />
        </circle>
        <circle cx="31.737" cy="73.801" fill="#C9FC00" r="3">
          <animateTransform
            attributeName="transform"
            type="rotate"
            calcMode="spline"
            values="0 50 50;360 50 50"
            keyTimes="0;1"
            keySplines="0.5 0 0.5 1"
            repeatCount="indefinite"
            dur="1.5s"
            begin="-0.375s"
          />
        </circle>
        <circle cx="26.199" cy="68.263" fill="#6864E9" r="3">
          <animateTransform
            attributeName="transform"
            type="rotate"
            calcMode="spline"
            values="0 50 50;360 50 50"
            keyTimes="0;1"
            keySplines="0.5 0 0.5 1"
            repeatCount="indefinite"
            dur="1.5s"
            begin="-0.437s"
          />
        </circle>
        <animateTransform
          attributeName="transform"
          type="rotate"
          calcMode="spline"
          values="0 50 50;0 50 50"
          keyTimes="0;1"
          keySplines="0.5 0 0.5 1"
          repeatCount="indefinite"
          dur="1.5s"
        />
      </g>
    </svg>
  );
};
