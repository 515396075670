import React, { SVGProps } from 'react';

interface GraphIconProps extends SVGProps<SVGSVGElement> {}

export const GraphIcon = (props: GraphIconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="21"
      viewBox="0 0 30 21"
      {...props}
    >
      <g fill="#9100DA" fillRule="evenodd">
        <path d="M0 0h14v5H0V0zm1 4.375h12V.625H1v3.75zM16 0h14v5H16V0zm1 4.375h12V.625H17v3.75zM0 8h14v5H0V8zm1 4.375h12v-3.75H1v3.75zM16 8h14v5H16V8zm1 4.375h12v-3.75H17v3.75zM0 16h14v5H0v-5zm1 4.375h12v-3.75H1v3.75zM16 16h14v5H16v-5zm1 4.375h12v-3.75H17v3.75z" />
      </g>
    </svg>
  );
};
