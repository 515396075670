import { fromFetch } from 'rxjs/fetch';
import { switchMap } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';

export const rxFetch = <TResponse extends Response>(
  url: string,
  requestInit?: RequestInit
): Observable<TResponse> => {
  return fromFetch(url, requestInit).pipe(
    switchMap((response) => {
      if (response.ok) {
        return response.json();
      } else {
        return throwError(response);
      }
    })
  );
};
