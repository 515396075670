import { BaseAnalyticWriter } from './baseAnalyticWriter';
import { DashboardEventType, UserAnalyticEvent } from './analytics-constants';
import { AnalyticsInterface } from './analytic.interface';

class ConsoleAnalyticWriter
  extends BaseAnalyticWriter
  implements AnalyticsInterface {
  public trackEvent(
    eventName: UserAnalyticEvent,
    eventType: DashboardEventType,
    properties: object
  ): void {
    properties = {
      ...properties,
      ...this.setupEventSpecificProps(eventName, eventType),
    };

    console.log('[Analytics]', eventType, eventName, properties);
  }
}

const consoleAnalyticWriter = new ConsoleAnalyticWriter();

export { consoleAnalyticWriter };
