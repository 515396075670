import React, { useRef, useState } from 'react';
import { BaseButton, H1 } from '../..';
import { UserAnalyticEvent } from '../../../utils';
import { Paragraph } from '@npmsoluto/atomic-ui-library-react';
import { Auth, getAuthTokenAsync, getUserEmail } from '../../../sso';
import { networkScanApi } from '../../../services';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

export const SessionWrapUp = React.memo(() => {
  const [submittedTextFeedback, setSubmittedTextFeedback] = useState(false);
  const feedbackInput = useRef<HTMLTextAreaElement>(null);
  const { t: translateText } = useTranslation();

  const ThanksForFeedback = () => (
    <div className="thanks-for-feedback">
      <H1>{translateText('thank_you')}</H1>
      <Paragraph>{translateText('thanks_for_feedback')}</Paragraph>
    </div>
  );

  if (submittedTextFeedback) {
    return <ThanksForFeedback />;
  }

  // Copied from feedback component
  const submitFeedback = async () => {
    const currentFeedback = feedbackInput.current;
    if (currentFeedback != null && currentFeedback.value.length > 0) {
      const feedbackText = currentFeedback.value;
      const authToken = await getAuthTokenAsync();
      const user = await Auth.currentUserInfo();
      const email = user ? getUserEmail(user) : 'Unknown email';
      networkScanApi
        .postExpertFeedback(email!, feedbackText, authToken)
        .subscribe({
          next: () => {
            toast.success('Feedback sent', {
              position: 'top-right',
            });
          },
          error: () => {
            toast.error('Feedback failed to send', { position: 'top-right' });
          },
        });
    }

    setSubmittedTextFeedback(true);
  };

  const FeedbackForm = () => {
    return (
      <div className="feedback-text-form">
        <p className="feedback-subheading">
          {translateText('anything_to_tell_us')}
        </p>
        <div className="feedback-input">
          <textarea className="feedback-input" ref={feedbackInput} />
        </div>
        <div className="feedback-prompt">
          {translateText('help_us_improve')}
        </div>
      </div>
    );
  };

  return (
    <div>
      {/* <FeedbackThumbs /> */}
      <FeedbackForm />
      <BaseButton
        analyticName={UserAnalyticEvent.EndSessionClick}
        onClick={submitFeedback}
      >
        {translateText('end_session')}
      </BaseButton>
    </div>
  );
});
