import { BehaviorSubject, Observable } from 'rxjs';
import { ISessionInterface } from '../services';

/**
 * Contains all the logic that is needed for anything related to user Sessions
 * that are ran from an app.
 *
 * @class ListSessionApi
 */
export class ListSessionApi {
  private listSessionSubject = new BehaviorSubject<ISessionInterface[]>([]);
  private currentOpenSessionSubject = new BehaviorSubject<ISessionInterface | null>(
    null
  );
  private today: Date;
  private weekAgo: Date;

  constructor() {
    /*
     * Adding one hour offset on upper and lower bounds to allow for scans to have a little bit of wiggle room timewise
     */
    this.today = new Date();
    this.today.setHours(this.today.getHours() + 1);
    this.weekAgo = new Date();
    this.weekAgo.setDate(this.weekAgo.getDate() - 7);
    this.weekAgo.setHours(this.weekAgo.getHours() - 1);
  }

  /**
   * Getter to hand back and observable of the currently known sessions, this can be used
   * to display them in a list or whatever is the best way to display them.
   *
   * @readonly
   * @memberof ListSessionApi
   */
  public get listSessions$() {
    return this.listSessionSubject.asObservable();
  }

  /**
   * Getter to hand back and observable of the currently known sessions, this can be used
   * to display them in a list or whatever is the best way to display them.
   *
   * @readonly
   * @memberof ListSessionApi
   */
  public get storedSessions() {
    return this.listSessionSubject.value;
  }

  /**
   * Will return you the current session that is open, this is the displayed session.
   * You use the session to get the homegraph details.
   *
   * @readonly
   * @type {(Observable<ISessionInterface | null>)}
   * @memberof ListSessionApi
   */
  public get currentOpenSession$(): Observable<ISessionInterface | null> {
    return this.currentOpenSessionSubject.asObservable();
  }

  public get currentSession(): ISessionInterface | null {
    return this.currentOpenSessionSubject.value;
  }

  /**
   * Update the current session list, calling this will reset whatever is in the subject.
   *
   * @param {ISessionInterface[]} sessions
   * @memberof ListSessionApi
   */
  public updateKnownSessions(sessions: ISessionInterface[]): void {
    this.currentOpenSessionSubject.next(sessions[0]);
    this.listSessionSubject.next(sessions);
  }

  /**
   * Sets the currently visible session
   *
   * @param {ISessionInterface} session The new session to use.
   * @memberof ListSessionApi
   */
  public setCurrentOpenSession(session: ISessionInterface): void {
    this.currentOpenSessionSubject.next(session);
  }

  /**
   * Returns the total number of sessions that have been ran in the last week.
   *
   * @returns {number}
   * @memberof ListSessionApi
   */
  public getWeekSessionCount(): number {
    const sessionInWeek = this.listSessionSubject.value.filter((session) => {
      const sessionDate = new Date(session.created_at);
      return (
        sessionDate.getTime() <= this.today.getTime() &&
        sessionDate.getTime() >= this.weekAgo.getTime()
      );
    });
    return sessionInWeek.length;
  }
}

export const listSessionApi = new ListSessionApi();
