import React from 'react';
import { useTranslation } from 'react-i18next';
import { DeviceView } from './DeviceView';
import { IFormattedClassifiedDevice } from '../../../../utils';

interface NetworkDevicesListViewProps {
  devices: IFormattedClassifiedDevice[];
}

export const NetworkDevicesListView = ({
  devices,
}: NetworkDevicesListViewProps) => {
  const { t } = useTranslation();

  return (
    <div className="network-devices-list-view">
      {devices.length > 0 ? (
        <div className="device-list-container">
          {devices.map((device) => (
            <DeviceView key={device.ip} device={device} />
          ))}
        </div>
      ) : (
        <div className="no-devices-found v2">
          <span>{t('no_devices_were_found')}.</span>
        </div>
      )}
    </div>
  );
};
