import React from 'react';

export const ThumbsUpNoFill = () => (
  <svg
    width="21"
    height="18"
    viewBox="0 0 21 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20.768 5.359C20.578 5.132 20.297 5 20 5H14V1C14 0.448 13.553 0 13 0H10C9.6 0 9.238 0.238 9.081 0.605L6.341 7H6C6 6.448 5.553 6 5 6H1C0.447 6 0 6.448 0 7V17C0 17.552 0.447 18 1 18H5C5.553 18 6 17.552 6 17V16.478L9.629 17.929C9.746 17.976 9.873 18 10 18H18C18.483 18 18.897 17.654 18.984 17.179L20.984 6.179C21.037 5.887 20.957 5.587 20.768 5.359ZM4 16H2V8H4V16ZM17.166 16H10.192L6 14.323V9H7C7.4 9 7.762 8.762 7.919 8.394L10.659 2H12V6C12 6.552 12.447 7 13 7H18.802L17.166 16Z"
      fill="#8223D2"
    />
  </svg>
);
