import React, { SVGProps } from 'react';

export const Thermostat = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="36px"
      height="36px"
      viewBox="0 0 36 36"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <title>thermostat</title>
      <desc>Created with Sketch.</desc>
      <g
        id="Expert-Dashboard-v1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="Iconogrpahy"
          transform="translate(-46.000000, -799.000000)"
          fill="#9100DA"
          fillRule="nonzero"
        >
          <g id="thermostat" transform="translate(46.000000, 799.000000)">
            <path
              d="M9.64285714,18 C9.64285714,22.6082041 13.3917959,26.3571429 18,26.3571429 C22.6082041,26.3571429 26.3571429,22.6082041 26.3571429,18 C26.3571429,13.3917959 22.6082041,9.64285714 18,9.64285714 C13.3917959,9.64285714 9.64285714,13.3917959 9.64285714,18 Z M25.2868934,18 C25.2868934,22.0178947 22.0178947,25.2868934 18,25.2868934 C13.9821053,25.2868934 10.7131066,22.0178947 10.7131066,18 C10.7131066,13.9821053 13.9821053,10.7131066 18,10.7131066 C22.0178947,10.7131066 25.2868934,13.9821053 25.2868934,18 Z"
              id="Shape"
            ></path>
            <polygon
              id="Path"
              points="13.5 14.7857143 13.5 15.7902603 15.3065302 15.7902603 15.6865707 16.1617331 15.6865707 21.2142857 16.7142857 21.2142857 16.7142857 15.7455267 15.7320685 14.7857143"
            ></polygon>
            <path
              d="M17.3571429,20.1679869 L18.3045837,21.2142857 L20.267103,21.2142857 L21.2142857,20.1679869 L21.2142857,15.189156 L20.267103,14.1428571 L18.3045837,14.1428571 L17.3571429,15.189156 L17.3571429,20.1679869 Z M18.3484707,15.6428658 L18.7150553,15.2379205 L19.8563732,15.2379205 L20.2229579,15.6428658 L20.2229579,19.7145622 L19.8563732,20.1192224 L18.7150553,20.1192224 L18.3484707,19.7145622 L18.3484707,15.6428658 Z"
              id="Shape"
            ></path>
            <path
              d="M16.3466549,7.36237187 L15.8217708,7.88722889 L16.5411349,8.60655595 L17.066019,8.08169886 C17.1987635,8.15694338 17.3413115,8.2168211 17.4912784,8.25841752 L17.4912784,9 L18.5087216,9 L18.5087216,8.25841752 C18.6586885,8.2168211 18.8012365,8.15694338 18.9337161,8.08169886 L19.4586001,8.60655595 L20.1779642,7.88722889 L19.6530802,7.36237187 C19.7283286,7.22989905 19.7882093,7.08735841 19.8298079,6.93739922 L20.5714286,6.93739922 L20.5714286,5.9200085 L19.8300729,5.9200085 C19.7882093,5.77004938 19.7285935,5.62750866 19.6533451,5.49477098 L20.1782292,4.96991396 L19.4588651,4.25058691 L18.933981,4.77544393 C18.8012365,4.70019948 18.6586885,4.64058669 18.5087216,4.59872533 L18.5087216,3.85714286 L17.4912784,3.85714286 L17.4912784,4.59872533 C17.3413115,4.64032176 17.1987635,4.70019948 17.0662839,4.77544393 L16.5413999,4.25058691 L15.8220358,4.96991396 L16.3469198,5.49477098 C16.2716714,5.62750866 16.2117907,5.76978445 16.1701921,5.91974357 L15.4285714,5.91974357 L15.4285714,6.93713429 L16.1701921,6.93713429 C16.2117907,7.08735841 16.2714065,7.22989905 16.3466549,7.36237187 Z M18,5.54696523 C18.4862001,5.54696523 18.8817841,5.94252891 18.8817841,6.42870386 C18.8817841,6.91487887 18.4862001,7.31044249 18,7.31044249 C17.5137999,7.31044249 17.1182159,6.91487887 17.1182159,6.42870386 C17.1182159,5.94252891 17.5137999,5.54696523 18,5.54696523 Z"
              id="Shape"
            ></path>
            <polygon
              id="Path"
              points="17.3571429 27 18.6428571 27 18.6428571 30.8571429 17.3571429 30.8571429"
            ></polygon>
            <polygon
              id="Path"
              points="14.1428571 29.9376224 15.0719775 26.3571429 16.0714286 26.6338062 15.1423083 30.2142857"
            ></polygon>
            <polygon
              id="Path"
              points="10.9285714 29.0209813 12.6443155 25.7142857 13.5 26.264733 11.784256 29.5714286"
            ></polygon>
            <polygon
              id="Path"
              points="8.35714286 26.9240428 10.853321 24.4285714 11.5714286 25.1473858 9.07525052 27.6428571"
            ></polygon>
            <polygon
              id="Path"
              points="6.42857143 24.2150581 9.73559438 22.5 10.2857143 23.3563704 6.97840484 25.0714286"
            ></polygon>
            <polygon
              id="Path"
              points="5.78571429 20.8570031 9.36645218 19.9285714 9.64285714 20.9287113 6.06211925 21.8571429"
            ></polygon>
            <polygon
              id="Path"
              points="19.9285714 26.6338061 20.9281523 26.3571429 21.8571429 29.9376224 20.8578314 30.2142857"
            ></polygon>
            <polygon
              id="Path"
              points="22.5 26.264733 23.3557702 25.7142857 25.0714286 29.0209812 24.2156584 29.5714286"
            ></polygon>
            <polygon
              id="Path"
              points="24.4285714 25.1473858 25.1469437 24.4285714 27.6428571 26.9240428 26.9247496 27.6428571"
            ></polygon>
            <polygon
              id="Path"
              points="25.7142857 23.3563705 26.2641191 22.5 29.5714286 24.2150581 29.0213087 25.0714286"
            ></polygon>
            <polygon
              id="Path"
              points="26.3571429 20.928851 26.6335478 19.9285714 30.2142857 20.8571329 29.9378808 21.8571429"
            ></polygon>
            <polygon
              id="Path"
              points="5.14285714 17.3571429 9 17.3571429 9 18.6428571 5.14285714 18.6428571"
            ></polygon>
            <polygon
              id="Path"
              points="5.78571429 15.1416104 6.06267592 14.1428571 9.64285714 15.0726752 9.36561717 16.0714286"
            ></polygon>
            <polygon
              id="Path"
              points="6.42857143 11.7839135 6.97934617 10.9285714 10.2857143 12.6449151 9.73493947 13.5"
            ></polygon>
            <polygon
              id="Path"
              points="8.35714286 9.0751323 9.07672352 8.35714286 11.5714286 10.8537037 10.8521126 11.5714286"
            ></polygon>
            <polygon
              id="Path"
              points="10.9285714 6.97795509 11.7854567 6.42857143 13.5 9.73633062 12.6431147 10.2857143"
            ></polygon>
            <polygon
              id="Path"
              points="27 17.6785714 30.364801 17.6785714 30.364801 18.3214286 27 18.3214286 27 19.2857143 31.5 19.2857143 31.5 16.7142857 27 16.7142857"
            ></polygon>
            <polygon
              id="Path"
              points="21.8571429 14.1428571 23.1428571 14.1428571 23.1428571 15.4285714 21.8571429 15.4285714"
            ></polygon>
            <path
              d="M30.7279358,5.27206423 C27.3282166,1.87234495 22.8078918,0 18,0 C13.1921082,0 8.67178343,1.87234495 5.27206423,5.27206423 C1.87234495,8.67178343 0,13.1921082 0,18 C0,22.8078918 1.87234495,27.3282166 5.27206423,30.7279358 C8.67178343,34.127655 13.1921082,36 18,36 C22.5392761,36 26.8744812,34.3053589 30.2074585,31.2280883 L29.4919739,30.4532776 C26.3542785,33.3500977 22.2731324,34.9453125 18,34.9453125 C13.5173035,34.9453125 9.43588259,33.1957398 6.40200804,30.343689 L7.46575931,29.2799377 C10.3337402,31.9625244 14.0537109,33.4335938 18,33.4335938 C22.1226196,33.4335938 25.9983215,31.8282166 28.9132691,28.9132691 C31.8282166,25.9983215 33.4335938,22.1226196 33.4335938,18 C33.4335938,14.0537109 31.9625244,10.3337402 29.2799377,7.46603395 L30.343689,6.40228275 C33.1957398,9.43588259 34.9453125,13.5173035 34.9453125,18 C34.9453125,22.2714844 33.3511963,26.351532 30.4562988,29.4884033 L31.2313843,30.2038879 C34.3064575,26.87146 36,22.5373535 36,18 C36,13.1921082 34.127655,8.67178343 30.7279358,5.27206423 L30.7279358,5.27206423 Z M1.0546875,18 C1.0546875,8.65640257 8.65640257,1.0546875 18,1.0546875 C22.4826965,1.0546875 26.5641174,2.80426022 29.597992,5.656311 L28.5342407,6.72006227 C25.6662598,4.03747559 21.9462891,2.56640625 18,2.56640625 C13.8773804,2.56640625 10.0016785,4.17178343 7.08673092,7.08673092 C4.17178343,10.0016785 2.56640625,13.8773804 2.56640625,18 C2.56640625,21.9462891 4.03747559,25.6662598 6.72006227,28.533966 L5.656311,29.5977173 C2.80426022,26.5641174 1.0546875,22.4826965 1.0546875,18 Z M18,32.3789063 C10.0714416,32.3789063 3.62109375,25.9285584 3.62109375,18 C3.62109375,10.0714416 10.0714416,3.62109375 18,3.62109375 C25.9285584,3.62109375 32.3789062,10.0714416 32.3789062,18 C32.3789062,25.9285584 25.9285584,32.3789063 18,32.3789063 Z"
              id="Shape"
            ></path>
            <path
              d="M29.857972,14.7857143 C29.1498217,12.0411785 27.5564837,9.57618652 25.3716732,7.844927 L25.9973676,6.98464059 C28.3637966,8.85989057 30.0897106,11.5307437 30.8571429,14.5050455 L29.857972,14.7857143 Z M24.8338239,7.44164135 C24.5605879,7.24815425 24.2765733,7.06394777 23.9901335,6.8943653 L24.500767,5.955053 C24.811189,6.1389782 25.1186469,6.33837114 25.4145179,6.5478884 L24.8338239,7.44164135 Z M23.4059365,6.56954326 C23.1106045,6.41599096 22.8058412,6.27312549 22.5,6.14488404 L22.8858718,5.14285714 C23.217312,5.28178534 23.5476743,5.43674378 23.8677971,5.60323268 L23.4059365,6.56954326 Z"
              id="Shape"
            ></path>
            <path
              d="M18,24.4285714 L18,23.3819013 C19.7929677,23.3819013 21.4635513,22.4897786 22.4687907,20.9958206 L23.3371998,21.5799389 C22.1373452,23.3636392 20.1418577,24.4285714 18,24.4285714 L18,24.4285714 Z M23.7231594,20.9263152 L22.7917866,20.4484994 C22.936794,20.1658439 23.05727,19.8690148 23.1496714,19.5659166 L24.1508223,19.8711954 C24.0404313,20.2334413 23.8965141,20.5886005 23.7231594,20.9263152 L23.7231594,20.9263152 Z M24.3285381,19.1328023 L23.2982223,18.9488173 C23.3538266,18.6378146 23.3819013,18.3186347 23.3819013,18 L24.4285714,18 C24.4285714,18.3802356 24.3947727,18.7612889 24.3285381,19.1328023 L24.3285381,19.1328023 Z"
              id="Shape"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
};
