import React, { useState } from 'react';
import { DisplayString, PartnerKeyProperties, PartnerKeys } from '../constants';
import { BaseButton, CardButton, H1, Header, RoutePaths } from '../components';
import { analytics, UserAnalyticEvent } from '../utils/analytics';
import { StoredQueryParams, useRouter, useSessionStorage } from '../hooks';
import { setPartner } from '../partner/utils';
import { PartnerLogo } from '../img/default/PartnerLogo';

export const IncorrectPartnerPage = () => {
  const [selectedPartner, setSelectedPartner] = useState<string>('');
  const router = useRouter();
  const [params, setParams] = useSessionStorage<StoredQueryParams>(
    'queryParams'
  );

  const onClickProceed = () => {
    router.push({
      pathname: RoutePaths.Home,
    });
  };

  const updateSelectedPartner = (key: string) => {
    setSelectedPartner(key);
    setParams({
      ...params,
      ...{ partner: key },
    });
    setPartner(PartnerKeys[key]);
    analytics.updateProperties({ partner: key });
  };

  const generatePartnerList = () => {
    const list: JSX.Element[] = [];

    for (const key in PartnerKeys) {
      const { partner } = PartnerKeyProperties[key];

      if (partner.length === 0) continue;

      list.push(
        <CardButton
          onClick={() => updateSelectedPartner(partner)}
          selected={partner === selectedPartner}
          key={partner}
        >
          <PartnerLogo partner={partner} />
        </CardButton>
      );
    }
    return list;
  };

  return (
    <div className="incorrect-partner-page">
      <Header />
      <div className="scan-failed-subtitle">
        <H1>{DisplayString.IncorrectPartnerProvided}</H1>
      </div>
      <div className="partner-list">{generatePartnerList()}</div>
      <BaseButton
        analyticName={UserAnalyticEvent.ConfirmPartnerSelection}
        onClick={onClickProceed}
      >
        Proceed
      </BaseButton>
    </div>
  );
};
