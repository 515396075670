import React from 'react';
import { H2 } from '../../CommonComponents';
import { useTranslation } from 'react-i18next';
import { useRxApi } from '../../../hooks';
import { BaseImage } from '../../../atomic-ui';
import { homeGraphApi, themeApi } from '../../../page-api';
import { map } from 'rxjs/operators';

const arrowImgWhite = require('../../../img/default/arrow-white.png');
const arrowImg = require('../../../img/default/arrow.png');
export interface BandwidthUsageProps {
  className?: string;
}
export const BandwidthUsage = ({ className }: BandwidthUsageProps) => {
  const { t: translateText } = useTranslation();
  const { result: isDarkMode } = useRxApi(
    themeApi.currentTheme$.pipe(map(() => themeApi.isDarkMode)),
    {
      initialValue: false,
    }
  );
  const { result: broadbandParams } = useRxApi(
    homeGraphApi.broadbandParameters$,
    {
      initialValue: {},
    }
  );

  const usage = broadbandParams.bandwidthUtilization
    ? parseInt(broadbandParams.bandwidthUtilization, 10)
    : 0;
  const planMax = broadbandParams.provisionedDown
    ? Math.round((parseInt(broadbandParams.provisionedDown, 10) / 1000) * 10) /
      10
    : 0;
  const availableMax = broadbandParams.broadbandMaxDown
    ? Math.round(
        (parseInt(broadbandParams.broadbandMaxDown, 10) / 1000000) * 10
      ) / 10
    : 0;

  const usagePer =
    usage > 0 && availableMax > 0 ? (usage / availableMax) * 100 : 0;
  const planMaxPer =
    planMax > 0 && availableMax > 0
      ? (planMax / availableMax) * 100 - usagePer
      : 0;
  const availableMaxPer = availableMax > 0 ? 100 - (usagePer + planMaxPer) : 0;

  const classes = ['bandwidth-usage', className].join(' ');
  return (
    <div className={classes}>
      <H2>{translateText('bandwidth_usage')}</H2>
      {availableMax > 0 && (
        <div className="bandwidth-container">
          <div className="progressbar">
            <div className="min">
              <div>
                <p className="number">0</p>
                <p className="mbps">{translateText('mbps')}</p>
              </div>
            </div>
            {usagePer !== 0 && (
              <div
                className="progress-bar-usage value"
                style={{ width: `${usagePer}%` }}
              >
                <div>
                  <p className="number">{usage}</p>
                  <p className="mbps">{translateText('mbps')}</p>
                  <BaseImage
                    className="img"
                    src={isDarkMode ? arrowImgWhite : arrowImg}
                  />
                </div>
              </div>
            )}
            {planMaxPer !== 0 && (
              <div
                className="plan-max value"
                style={{ width: `${planMaxPer}%` }}
              >
                <div>
                  <BaseImage
                    className="img"
                    src={isDarkMode ? arrowImgWhite : arrowImg}
                  />
                  <p className="number">{planMax}</p>
                  <p className="mbps">{translateText('mbps')}</p>
                </div>
              </div>
            )}
            {availableMaxPer !== 0 && (
              <div
                className="available-max value"
                style={{ width: `${availableMaxPer}%` }}
              />
            )}
            <div className="max">
              <div>
                <p className="number">{availableMax}</p>
                <p className="mbps">{translateText('mbps')}</p>
              </div>
            </div>
          </div>
          <div className="indicators">
            {usagePer !== 0 && (
              <div className="usage">
                <p className="indicator"></p>
                <p className="text">{translateText('data_usage')}</p>
              </div>
            )}
            {planMaxPer !== 0 && (
              <div className="plan-max">
                <p className="indicator"></p>
                <p className="text">{translateText('data_plan_max')}</p>
              </div>
            )}
            {availableMaxPer !== 0 && (
              <div className="available-max">
                <p className="indicator"></p>
                <p className="text">{translateText('max_offered_by_isp')}</p>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};
