import {
  IRecommendationServiceResponse,
  IWifiWizardAnsweredQuestion,
  IWifiWizardQuestion,
  IWifiWizardRecommendation,
  IWifiWizardRequest,
  IWifiWizardResponse,
} from '../services';
import { BehaviorSubject, Observable } from 'rxjs';
import { delay, skipWhile } from 'rxjs/operators';
import { wifiWizardApiData } from './WifiWizardApiData';

const mockNetworkDelay = 10;
/**
 * Consumes a response from the backend containing a batch of wifi wizard questions
 * and receives answers to the questions from the front end. It then sends the
 * answers to the backend to repeat the question cycle.
 *
 * @exports
 * @class WifiWizardApi
 */
export class WifiWizardApi {
  private questionBatch = new BehaviorSubject<IWifiWizardQuestion[]>([]);

  private cachedRequest: IWifiWizardRequest;
  public shouldUseMockResponses = true;

  public get questionBatch$(): Observable<IWifiWizardQuestion[]> {
    return this.questionBatch.asObservable();
  }

  /**
   * Populate questionBatch with questions from an `IWifiWizardResponse`.
   * Overwrites the previous batch with the new batch. As implemented the new
   * batch should be the full set of questions until this point.
   *
   * @private
   * @param {IWifiWizardResponse} response
   * @memberof WifiWizardApi
   */
  private updateBatchData(response: IWifiWizardResponse): void {
    if (response.batch) {
      this.questionBatch.next(response.batch);
    }
  }

  /**
   * This is the entry point from the UI. The first time for requesting
   * questions the UI layer is responsible for creating a request. Afterwards,
   * it will be cached and used for subsequent requests.
   *
   * @param {IWifiWizardRequest} request
   * @memberof WifiWizardApi
   */
  public requestQuestions(
    request: IWifiWizardRequest
  ): Observable<IWifiWizardQuestion[]> {
    this.cachedRequest = request;
    this.sendRequest(request, 'GET');
    return this.questionBatch$;
  }

  /**
   * Submits an answerId and a questionId
   *
   * @param {IWifiWizardAnsweredQuestion} answeredQuestion
   * @memberof WifiWizardApi
   */
  public submitAnswer(answeredQuestion: IWifiWizardAnsweredQuestion): void {
    const request = this.cachedRequest;
    request.answeredQuestion = answeredQuestion;
    this.sendRequest(request, 'POST');
  }

  /**
   * This will handle the actually sending the request and retrieving a response
   *
   * @private
   * @param {IWifiWizardRequest} request
   * @param {string} requestMethod
   * @memberof WifiWizardApi
   */
  private sendRequest(
    request: IWifiWizardRequest,
    requestMethod: string
  ): void {
    if (this.shouldUseMockResponses) {
      // if this is a submitAnswer request
      if (requestMethod === 'POST') {
        wifiWizardApiData.setAnswerToLocalQuestion(request.answeredQuestion);
      }
      wifiWizardApiData.updateResponseQuestionBatch();

      this.mockResponse().subscribe((response) => {
        this.updateBatchData(response);
      });
    } else {
      // requestMethod = requestMethod.toUpperCase();
      // rxFetch<IWifiWizardResponse>(this.apiUrl, {
      //   method: requestMethod,
      //   headers: new Headers({
      //     'Content-Type': 'application/json',
      //   }),
      //   ...(requestMethod === 'POST' && { body: request as any }),
      // }).pipe(
      //   tap((response: IWifiWizardResponse) => {
      //     this.updateBatchData(response);
      //   })
      // );
    }
  }

  /**
   * Creates a mock response
   *
   * @private
   * @returns {Observable<IWifiWizardResponse>}
   * @memberof WifiWizardApi
   */
  private mockResponse(): Observable<IWifiWizardResponse> {
    return new BehaviorSubject<IWifiWizardResponse>(
      wifiWizardApiData.mockWifiWizardResponse()
    ).pipe(delay(mockNetworkDelay));
  }
}

export const wifiWizardApi = new WifiWizardApi();
