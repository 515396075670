import React, { useCallback } from 'react';
import {
  BaseButton,
  H1,
  H2,
  Header,
  RoutePaths,
  ScanFailure,
} from '../components';
import { DisplayString } from '../constants';
import { useRouter } from '../hooks';
import { UserAnalyticEvent } from '../utils';

export const ScanFailedPage = () => {
  const router = useRouter();

  const onClickRefresh = useCallback(() => {
    router.push({ pathname: RoutePaths.Scanning });
  }, [router]);

  return (
    <div className="scan-failed-container">
      <Header />
      <div className="scan-failed-animation-container">
        <ScanFailure />
        <div className="scan-failed-subtitle">
          <H1>{DisplayString.ScanFailedUnableToComplete}</H1>
          <H2>{DisplayString.ScanFailedAskCustomerToCheckConnection}</H2>
          <H2>{DisplayString.ScanFailedAfterCustomerRestartedRefresh}</H2>
        </div>
        <BaseButton
          analyticName={UserAnalyticEvent.ScanUnsuccessfulRefreshButton}
          onClick={onClickRefresh}
        >
          Refresh
        </BaseButton>
      </div>
    </div>
  );
};
