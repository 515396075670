const appConfig = require('appConfig');

export const getSessionData = (key: string) => {
  return JSON.parse(localStorage.getItem(key) as string);
};

/**
 * Takes a '[UUID...]' or a 'UUID' and will hand it back if no brackets otherwise it will match the brackets and pop the first one
 *
 * @param {string} clientId The unformatted clientId to parse
 * @returns {string}
 */
export const getClientId = (clientId: string | null): string => {
  if (clientId?.includes('[')) {
    return clientId?.match(/\[(.*?)]/)?.pop() ?? '';
  }
  return clientId ?? '';
};

/**
 * Sorts an array by a given key which must be a date string
 *
 * @template T Type of the array
 * @param {T[]} arr The array to sort
 * @param {keyof T} key Key on the type of T which contains a date string
 */
export const sortArrayByDateKey = <T extends any>(
  arr: T[],
  key: keyof T
): T[] => {
  return arr.sort(function (a, b) {
    return (
      Date.parse(b[key].replace(/ /g, 'T')) -
      Date.parse(a[key].replace(/ /g, 'T'))
    );
  });
};

export const searchStringToObj = (string: string | any) => {
  if (typeof string !== 'string' || string.length < 1) {
    return null;
  }
  const search = string.substring(1);
  return JSON.parse(
    `{"${search.replace(/&/g, '","').replace(/=/g, '":"')}"}`,
    function (key, value) {
      return key === '' ? value : decodeURIComponent(value);
    }
  );
};

export const isObjEmpty = (obj) => {
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) return false;
  }
  return true;
};

export const generateClientIdAry = (props) => {
  const clientIdString = decodeURIComponent(
    props && props.hasOwnProperty('clientId') && props.clientId
  );
  const clientIdAry =
    clientIdString[0] === '[' &&
    clientIdString[clientIdString.length - 1] === ']'
      ? clientIdString.substring(1, clientIdString.length - 1)
      : clientIdString;
  return clientIdAry.split(',');
};

export const isProduction = (): boolean => {
  return process.env.NODE_ENV === 'production';
};

export const getEnvironment = (): string => {
  return isProduction() ? 'prod' : 'dev';
};
