import React from 'react';
import { RoutePaths } from '../components/routing';
import { RouteComponentProps } from 'react-router';
import { Route } from 'react-router-dom';
import { withAuthentication } from './withAuthentication';

interface AuthenticatedRouteProps {
  path: RoutePaths;
  component:
    | React.ComponentType<RouteComponentProps<any>>
    | React.ComponentType<any>;
}

export const AuthenticateRoute = (props: AuthenticatedRouteProps) => {
  const { path, component } = props;
  return <Route exact path={path} component={withAuthentication(component)} />;
};
