import React from 'react';

export const CircleQuestionMarkNoFill = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9 13V10H10C11.104 10 12 9.103 12 8C12 6.896 11.104 6 10 6C8.896 6 8 6.896 8 8H6C6 5.795 7.795 4 10 4C12.205 4 14 5.795 14 8C14 9.86 12.723 11.428 11 11.875V13H9Z"
      fill="#8223D2"
    />
    <path
      d="M11.25 15C11.25 15.6904 10.6904 16.25 10 16.25C9.30964 16.25 8.75 15.6904 8.75 15C8.75 14.3096 9.30964 13.75 10 13.75C10.6904 13.75 11.25 14.3096 11.25 15Z"
      fill="#8223D2"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 20C4.486 20 0 15.515 0 10C0 4.487 4.486 0 10 0C15.514 0 20 4.486 20 10C20 15.515 15.514 20 10 20ZM10 2C5.589 2 2 5.589 2 10C2 14.411 5.589 18 10 18C14.411 18 18 14.411 18 10C18 5.589 14.411 2 10 2Z"
      fill="#8223D2"
    />
  </svg>
);
