import React, { SVGProps } from 'react';

export const Scanner = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="39px"
      height="32px"
      viewBox="0 0 39 32"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <title>scanner</title>
      <desc>Created with Sketch.</desc>
      <g
        id="Expert-Dashboard-v1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="Iconogrpahy" transform="translate(-45.000000, -679.000000)">
          <g id="scanner" transform="translate(45.000000, 679.000000)">
            <g>
              <path
                d="M38.35,20.4798326 L5.2,20.4798326 L5.2,17.798231 L38.6048584,3.78927739 C38.7692627,3.71958894 38.8974853,3.58646196 38.9590576,3.42083451 C39.0206299,3.25520715 39.0101563,3.07176696 38.9298584,2.91426467 L37.6298584,0.354227459 C37.4762451,0.0514105776 37.109668,-0.0814038329 36.7932373,0.0514105776 L1.69323732,14.7716245 C1.45456545,14.872876 1.3,15.1041294 1.3,15.3597581 L1.3,20.4798326 L0.65,20.4798326 C0.291040019,20.4798326 0,20.7663992 0,21.1198419 L0,28.7999535 C0,29.1533961 0.291040019,29.4399628 0.65,29.4399628 L1.3,29.4399628 L1.3,31.3599907 C1.3,31.7134333 1.59104002,32 1.95,32 L37.05,32 C37.40896,32 37.7,31.7134333 37.7,31.3599907 L37.7,29.4399628 L38.35,29.4399628 C38.70896,29.4399628 39,29.1533961 39,28.7999535 L39,21.1198419 C39,20.7663992 38.70896,20.4798326 38.35,20.4798326 Z M2.6,15.7835142 L36.7418213,1.46455616 L37.4568847,2.87770166 L4.61506346,16.6532144 C4.59348142,16.6475893 4.57189939,16.6429017 4.55,16.6397767 L2.6,16.6397767 L2.6,15.7835142 Z M2.6,17.9197953 L3.9,17.9197953 L3.9,20.4798326 L2.6,20.4798326 L2.6,17.9197953 Z M36.4,30.7199814 L2.6,30.7199814 L2.6,29.4399628 L36.4,29.4399628 L36.4,30.7199814 Z M37.7,28.1599442 L1.3,28.1599442 L1.3,21.7598512 L37.7,21.7598512 L37.7,28.1599442 Z"
                id="Shape"
                fill="#9100da"
                fillRule="nonzero"
              ></path>
              <path
                d="M34,27 C35.1044922,27 36,26.1048177 36,25 C36,23.8955077 35.1044922,23 34,23 C32.8955078,23 32,23.8955077 32,25 C32,26.1048177 32.8955078,27 34,27 Z M34,24.3333333 C34.3681641,24.3333333 34.6666667,24.6318359 34.6666667,25 C34.6666667,25.368164 34.3681641,25.6666667 34,25.6666667 C33.6318359,25.6666667 33.3333333,25.368164 33.3333333,25 C33.3333333,24.6318359 33.6318359,24.3333333 34,24.3333333 Z"
                id="Shape"
                fill="#000000"
                fillRule="nonzero"
              ></path>
              <polygon
                id="Path"
                fill="#9100da"
                fillRule="nonzero"
                points="3 23 4 23 4 27 3 27"
              ></polygon>
              <polygon
                id="Path"
                fill="#9100da"
                fillRule="nonzero"
                points="6 23 7 23 7 27 6 27"
              ></polygon>
              <polygon
                id="Path"
                fill="#9100da"
                fillRule="nonzero"
                points="23 13 24 13 24 18 23 18"
              ></polygon>
              <polygon
                id="Path"
                fill="#9100da"
                fillRule="nonzero"
                points="26 12 27 12 27 17 26 17"
              ></polygon>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
