import { ContentHandler } from './api';
import { Observable } from 'rxjs';
import { IContentfulExpertTip } from './contracts';
import { PartnerKeys } from '../../constants';

export class ContentfulApi {
  private handler: ContentHandler;
  constructor() {
    this.handler = new ContentHandler();
  }

  /**
   * Get the expert tips available from contentful for their links
   *
   * @param {PartnerKeys} partnerKey Enforce being called with a PartnerKey used in tag query.
   * @returns {Observable<Record<string, IContentfulExpertTip>>}
   * @memberof ContentfulApi
   */
  public getExpertTips(
    partnerKey: PartnerKeys
  ): Observable<Record<string, IContentfulExpertTip>> {
    return this.handler.getExpertTips(partnerKey);
  }
}
