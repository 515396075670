import {
  IClassifiedDevice,
  IExpertTip,
  IHomeGraph,
  IHomeGraphResponse,
  NullishString,
  RecordStringBooleans,
  UnknownArray,
  UnknownObject,
} from '../services';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { HomeGraphApiCache } from './HomeGraphApiCache';

type ValueOf<T> = T[keyof T];
type HomeGraphRecord = Record<
  keyof IHomeGraph,
  BehaviorSubject<ValueOf<IHomeGraph>>
>;

/**
 * A collection for all things homegraphs. It will consume a homegraph and break it down
 * into the properties on the response and publish those to the Subjects and any Observable will fire
 * if the key it listens for has data.
 *
 * @export
 * @class HomeGraphApi
 */
export class HomeGraphApi extends HomeGraphApiCache {
  private readonly homeGraphDisplayMap: HomeGraphRecord;
  private readonly noErrorTipId = '0';

  public get appTips$(): Observable<UnknownArray> {
    return this.homeGraphDisplayMap['appTips'] as Observable<UnknownArray>;
  }
  public get broadbandParameters$(): Observable<UnknownObject> {
    return this.homeGraphDisplayMap[
      'broadbandParameters'
    ] as Observable<UnknownObject>;
  }
  public get capabilities$() {
    return this.homeGraphDisplayMap[
      'capabilities'
    ] as Observable<RecordStringBooleans>;
  }
  public get classifiedDevices$() {
    return this.homeGraphDisplayMap['devicesClassified'] as Observable<
      IClassifiedDevice[]
    >;
  }
  public get dnsResolver$() {
    return this.homeGraphDisplayMap['dnsResolver'] as Observable<NullishString>;
  }
  public get expertTips$() {
    return (this.homeGraphDisplayMap['expertTips'] as Observable<
      IExpertTip[]
    >).pipe(
      map((tips) => tips.filter((tip) => tip.tipId !== this.noErrorTipId))
    );
  }
  public get internetHealth$() {
    return this.homeGraphDisplayMap[
      'internetHealth'
    ] as Observable<UnknownObject>;
  }
  public get ispData$() {
    return this.homeGraphDisplayMap['ispData'] as Observable<NullishString>;
  }
  public get siteScores$() {
    return this.homeGraphDisplayMap['siteScores'] as Observable<UnknownObject>;
  }

  constructor() {
    super();
    // This is like this since a subscription will grab something at a moment in time
    // if this BehaviorSubject where to change to a new one it would only ever listen to the old one
    // this gives it a consistent access and it's emitted to below.
    this.homeGraphDisplayMap = this.getNewHomeGraphRecord$();
  }

  /**
   * Will toggle on a cached record from the homegraphs
   *
   * @param {string} sessionId The sessionId to toggle from the cache.
   * @returns {void}
   * @memberof HomeGraphApi
   */
  public toggleCachedHomeGraph(sessionId: string): void {
    if (this.sessionId === sessionId) {
      return;
    }
    this.sessionId = sessionId;
    const cachedData = this.getCachedData(sessionId);
    this.updateHomeGraphData(sessionId, cachedData as IHomeGraphResponse);
  }

  /**
   * Give this a homeGraph and it will loop over they keys in the object and have the
   * matching behaviorSubject emit the value if it is defined.
   *
   * @param {string} sessionId
   * @param {IHomeGraph} homeGraphData
   * @memberof HomeGraphApi
   */
  public updateHomeGraphData(
    sessionId: string,
    homeGraphData: IHomeGraph
  ): void {
    this.setCachedData(sessionId, homeGraphData);
    Object.keys(homeGraphData).forEach((key: keyof IHomeGraph) => {
      const value: ValueOf<IHomeGraph> = homeGraphData[key];
      if (homeGraphData[key] && this.homeGraphDisplayMap[key]) {
        this.homeGraphDisplayMap[key].next(value);
      }
    });
  }
}

export const homeGraphApi = new HomeGraphApi();
