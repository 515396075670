import Auth from './auth';

let authToken: string | null = null;

export const getAuthTokenAsync = async (): Promise<string> => {
  if (authToken) return authToken;

  const session = await Auth.currentSession();
  const idToken = session.getIdToken();
  return (authToken = idToken.getJwtToken());
};

export const getAuthToken = (): string => {
  if (authToken) return authToken;
  console.log('No auth token has been set');
  return '';
};

/**
 * Returns back a list of authorized partners
 * example: ["Partner1", "Partner2"]
 */
export const getAuthorizedPartners = async (): Promise<string[]> => {
  const session = await Auth.currentSession();
  return session.getIdToken().decodePayload().partners?.split(' ');
};

export const getAuthorizationHeaders = async () => {
  const token = await getAuthTokenAsync();
  return new Headers({
    Authorization: `Bearer ${token}`,
  });
};

export const getAuthorizationHeadersObject = () => {
  const token = getAuthToken();
  return {
    Authorization: `Bearer ${token}`,
  };
};
