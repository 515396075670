import React, { useMemo } from 'react';
import { InitiateScanButton, RoutePaths } from '../../components';
import { useRouter } from '../../hooks';
import { of } from 'rxjs';
import { finalize } from 'rxjs/operators';
import {
  InitiateScanButtonProps,
  InitiateScanButtonPropsV2,
} from '../contracts';
import { ResultsPageState } from '../../pages';

export const AttInitiateScanButtonV2 = ({
  clientId,
  buttonText,
  variant,
  setResultsPageState,
}: InitiateScanButtonPropsV2) => {
  const router = useRouter();
  const triggerScanAction$ = useMemo(
    () =>
      of({}).pipe(
        finalize(() => setResultsPageState?.(ResultsPageState.Scanning))
      ),
    [setResultsPageState]
  );
  return (
    <InitiateScanButton
      buttonText={buttonText}
      triggerScan$={triggerScanAction$}
      clientId={clientId}
      variant={variant}
    />
  );
};
