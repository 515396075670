import React, { ReactNode } from 'react';

interface ICardButtonProps {
  onClick?: () => void;
  selected?: boolean;
  children: ReactNode;
}

export const CardButton = ({
  onClick,
  selected = false,
  children,
}: ICardButtonProps) => {
  return (
    <div
      onClick={onClick}
      className={`card-button ${selected ? 'selected' : ''}`}
    >
      {children}
    </div>
  );
};
