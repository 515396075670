import React from 'react';

export const HomeplusLogo = ({ isDarkMode }) => {
  const fillColor = isDarkMode ? 'white' : 'black';
  return (
    <svg
      width="196"
      height="24"
      viewBox="0 0 196 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M44.5486 6.81652V15.9712C44.5486 18.4083 43.2744 20.1527 40.8249 20.1527C38.3754 20.1527 37.1507 18.9155 37.1507 15.9712V6.81652H33.4269V16.7506C33.4269 21.1671 35.7404 23.5547 39.9342 23.5547C42.2971 23.5547 43.794 22.4537 44.5981 21.4887V23.3815H48.3219V6.81652H44.5486Z"
        fill={fillColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M77.7776 6.63096C72.9653 6.63096 69.1054 10.4537 69.1054 15.0928C69.1054 19.7815 73.0147 23.5547 77.7776 23.5547C82.59 23.5547 86.4498 19.732 86.4498 15.0928C86.4993 10.4042 82.59 6.63096 77.7776 6.63096ZM82.7756 15.0928C82.7756 17.765 80.5612 19.93 77.8271 19.93C75.0931 19.93 72.8787 17.765 72.8787 15.0928C72.8787 12.4207 75.0931 10.2557 77.8271 10.2557C80.5612 10.2681 82.7756 12.4207 82.7756 15.0928Z"
        fill={fillColor}
      />
      <path
        d="M60.2477 6.63096C58.8868 6.63096 56.858 6.90312 55.2992 8.56086V6.81652H51.5755V23.3815H55.2992V13.8062C55.2992 11.8269 56.9446 10.2681 58.924 10.2681C59.3941 10.2681 59.8147 10.3547 60.2477 10.5031V6.63096Z"
        fill={fillColor}
      />
      <path
        d="M29.5053 21.9465C28.4166 23.0104 27.0063 23.5547 25.4475 23.5547C23.9382 23.5547 22.4784 22.9609 21.3898 21.9465C20.3011 20.8826 19.7444 19.5094 19.7444 17.9877H23.2826C23.2826 18.5444 23.5176 19.0887 23.9011 19.4599C24.3218 19.8681 24.8413 20.0537 25.4104 20.0537C25.9795 20.0537 26.5362 19.8186 26.9197 19.4599C27.7733 18.631 27.7733 17.3444 26.9692 16.5155C26.489 16.1383 26.1357 16.0674 25.5576 15.9514C25.5101 15.9419 25.4611 15.932 25.4104 15.9217L25.3439 15.9079C24.461 15.7246 23.3018 15.4838 22.1073 14.3135C20.3135 12.5691 20.3135 9.6619 22.1073 7.91756C23.9011 6.17322 26.8702 6.17322 28.664 7.91756C28.8225 8.07603 28.9539 8.2345 29.1045 8.41609L29.1836 8.51138L26.8207 10.8124C26.4496 10.4042 26.1156 10.1691 25.6455 10.1196C25.2743 10.033 24.8908 10.1196 24.6558 10.3918C24.4702 10.5774 24.3218 10.8495 24.3218 11.1341C24.3218 11.4062 24.4207 11.6908 24.6558 11.8763C25.1259 12.3341 25.4599 12.433 26.1651 12.5196C26.9692 12.6557 28.095 12.8908 29.3692 13.9423L29.4682 14.0289C31.7197 16.2434 31.7197 19.7815 29.5053 21.9465Z"
        fill={fillColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.6207 23.3815H17.3444V15.0928C17.3444 10.4042 13.4351 6.63096 8.6722 6.63096C3.9093 6.63096 0 10.4042 0 15.0928C0 19.7815 3.9093 23.5547 8.6722 23.5547C10.3176 23.5547 12.1609 23.097 13.6207 21.4887V23.3815ZM8.6722 19.93C5.93817 19.93 3.72373 17.765 3.72373 15.0928C3.72373 12.4207 5.93817 10.2557 8.6722 10.2557C11.4062 10.2557 13.6207 12.4207 13.6207 15.0928C13.6207 17.765 11.4062 19.93 8.6722 19.93Z"
        fill={fillColor}
      />
      <path
        d="M96.3468 10.2681C94.2684 10.2681 92.6231 11.8763 92.6231 13.9052V23.3815H88.8993V13.9052C88.8993 9.89695 92.2519 6.63096 96.3468 6.63096C100.442 6.63096 103.794 9.89695 103.794 13.9052V23.3815H100.071V13.9052C100.071 11.8763 98.4252 10.2681 96.3468 10.2681Z"
        fill={fillColor}
      />
      <path
        d="M62.9817 6.81652H66.7054V23.3815H62.9817V6.81652Z"
        fill={fillColor}
      />
      <path
        d="M67.3611 2.48661C67.3611 3.87218 66.2353 4.97322 64.8126 4.97322C63.39 4.97322 62.2642 3.87218 62.2642 2.48661C62.2642 1.10104 63.39 0 64.8126 0C66.2353 0 67.3611 1.10104 67.3611 2.48661Z"
        fill={fillColor}
      />
      <path
        d="M192.044 6.62999V2.4943H190.189V6.62999H185.861V8.48567H190.189V12.6332H192.044V8.48567H196V6.62999H192.044Z"
        fill="#8223D2"
      />
      <path
        d="M112.441 0.802368H110.447V23.5752H112.441V16.6598C112.441 15.1373 112.687 13.7971 113.181 12.6391C113.674 11.4597 114.339 10.5591 115.175 9.93725C116.011 9.29395 116.923 8.9723 117.909 8.9723C119.003 8.9723 119.839 9.30467 120.418 9.96942C120.997 10.6127 121.286 11.5241 121.286 12.7034V23.5752H123.281V12.4461C123.281 10.7521 122.83 9.44405 121.93 8.52199C121.05 7.57848 119.817 7.10672 118.231 7.10672C117.051 7.10672 115.925 7.42837 114.853 8.07168C113.781 8.71498 112.977 9.65849 112.441 10.9022V0.802368Z"
        fill="#8223D2"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M129.656 22.6746C130.814 23.4466 132.155 23.8326 133.677 23.8326C135.2 23.8326 136.529 23.4466 137.666 22.6746C138.824 21.8812 139.713 20.8412 140.335 19.5546C140.957 18.268 141.268 16.9064 141.268 15.4696C141.268 14.0329 140.957 12.6713 140.335 11.3847C139.713 10.0981 138.824 9.06879 137.666 8.29683C136.529 7.50343 135.2 7.10672 133.677 7.10672C132.155 7.10672 130.814 7.50343 129.656 8.29683C128.52 9.06879 127.641 10.0981 127.019 11.3847C126.397 12.6713 126.086 14.0329 126.086 15.4696C126.086 16.9064 126.397 18.268 127.019 19.5546C127.641 20.8412 128.52 21.8812 129.656 22.6746ZM136.636 21.0664C135.821 21.6668 134.835 21.967 133.677 21.967C132.519 21.967 131.522 21.6668 130.686 21.0664C129.871 20.4445 129.249 19.6404 128.82 18.654C128.391 17.6462 128.177 16.5847 128.177 15.4696C128.177 14.3546 128.391 13.3039 128.82 12.3175C129.249 11.3096 129.871 10.5055 130.686 9.90509C131.522 9.28323 132.519 8.9723 133.677 8.9723C134.835 8.9723 135.821 9.28323 136.636 9.90509C137.473 10.5055 138.105 11.3096 138.534 12.3175C138.963 13.3039 139.177 14.3546 139.177 15.4696C139.177 16.5847 138.963 17.6462 138.534 18.654C138.105 19.6404 137.473 20.4445 136.636 21.0664Z"
        fill="#8223D2"
      />
      <path
        d="M144.095 7.36404H146.09V10.7414C146.604 9.62632 147.301 8.74714 148.18 8.10384C149.081 7.4391 150.099 7.10672 151.236 7.10672C153.681 7.10672 155.149 8.30755 155.643 10.7092C156.2 9.65849 156.951 8.80075 157.894 8.13601C158.859 7.44982 159.878 7.10672 160.95 7.10672C162.515 7.10672 163.662 7.54631 164.391 8.42549C165.142 9.30467 165.517 10.6449 165.517 12.4461V23.5752H163.523V12.7034C163.523 11.4383 163.309 10.5055 162.88 9.90509C162.451 9.28323 161.7 8.9723 160.628 8.9723C159.749 8.9723 158.934 9.28323 158.184 9.90509C157.455 10.5055 156.876 11.374 156.447 12.5105C156.018 13.647 155.803 14.9657 155.803 16.4668V23.5752H153.809V12.7034C153.809 11.4383 153.595 10.5055 153.166 9.90509C152.737 9.28323 151.986 8.9723 150.914 8.9723C150.035 8.9723 149.22 9.28323 148.47 9.90509C147.741 10.5055 147.162 11.374 146.733 12.5105C146.304 13.647 146.09 14.9657 146.09 16.4668V23.5752H144.095V7.36404Z"
        fill="#8223D2"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M170.4 15.4696H182.655V14.569C182.655 13.0894 182.355 11.7921 181.754 10.677C181.154 9.54055 180.307 8.66137 179.213 8.03951C178.141 7.41765 176.919 7.10672 175.546 7.10672C174.131 7.10672 172.877 7.47126 171.783 8.20034C170.69 8.90797 169.843 9.89436 169.242 11.1595C168.642 12.4247 168.341 13.8614 168.341 15.4696C168.341 17.035 168.642 18.461 169.242 19.7476C169.864 21.0128 170.722 22.0099 171.815 22.739C172.909 23.468 174.153 23.8326 175.546 23.8326C177.09 23.8326 178.398 23.4895 179.471 22.8033C180.543 22.1171 181.486 20.9699 182.301 19.3616L180.532 18.4932C179.932 19.7369 179.256 20.6268 178.506 21.1629C177.755 21.6989 176.769 21.967 175.546 21.967C173.917 21.967 172.652 21.3451 171.751 20.1014C170.85 18.8577 170.4 17.3138 170.4 15.4696ZM178.988 10.2267C179.867 11.0416 180.403 12.1674 180.596 13.6041H170.561C170.861 12.103 171.462 10.9558 172.362 10.1624C173.284 9.369 174.346 8.9723 175.546 8.9723C176.983 8.9723 178.13 9.39044 178.988 10.2267Z"
        fill="#8223D2"
      />
    </svg>
  );
};
