import React, { SVGProps } from 'react';

export const GoodUltraHdIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="34"
      height="34"
      viewBox="0 0 34 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15 16H13V24H15C17.206 24 19 22.205 19 20C19 17.795 17.206 16 15 16ZM15 18C16.103 18 17 18.898 17 20C17 21.104 16.103 22 15 22V18Z"
        fill="black"
      />
      <path d="M7 19H9V16H11V24H9V21H7V24H5V16H7V19Z" fill="black" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 12H20C21.103 12 22 12.896 22 14V26C22 27.103 21.103 28 20 28H4C2.897 28 2 27.103 2 26V14C2 12.896 2.897 12 4 12ZM4 14V26H20.001L20 14H4Z"
        fill="black"
      />
      <path
        d="M11.9998 29C13.7818 29 15.4708 29.392 16.9888 30.092C17.4158 30.288 17.6468 30.756 17.5478 31.215C17.4468 31.673 17.0408 32 16.5708 32H7.42979C6.95979 32 6.55279 31.673 6.45179 31.214C6.35179 30.756 6.58379 30.288 7.01079 30.092C8.52879 29.392 10.2178 29 11.9998 29Z"
        fill="black"
      />
      <circle className="override-color" cx="24" cy="10" r="10" />
      <path
        className="override-icon-good"
        d="M24 0C18.477 0 14 4.477 14 10C14 15.523 18.477 20 24 20C29.523 20 34 15.523 34 10C34 4.477 29.522 0 24 0ZM23.25 15.75L18.25 12L19.75 10L22.75 12.25L28 5.25L30 6.75L23.25 15.75Z"
      />
    </svg>
  );
};
