import React from 'react';

interface ScanComponentProps extends React.HTMLAttributes<HTMLDivElement> {
  children: React.ReactNode;
}

export const ScanComponent = ({ children, ...props }: ScanComponentProps) => {
  const { className, ...propsWithoutClassName } = props;
  const classList = ['scan-component', className].join(' ');
  return (
    <div className={classList} {...propsWithoutClassName}>
      {children}
    </div>
  );
};
