import React, { SVGProps } from 'react';

export const Tablet = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="27px"
      height="36px"
      viewBox="0 0 27 36"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <title>Shape</title>
      <desc>Created with Sketch.</desc>
      <g
        id="Expert-Dashboard-v1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="Iconogrpahy"
          transform="translate(-50.000000, -207.000000)"
          fill="#9100DA"
        >
          <path
            d="M73.8604651,207 L53.1395349,207 C51.3813953,207 50,208.414286 50,210.214286 L50,239.785714 C50,241.585714 51.3813953,243 53.1395349,243 L73.8604651,243 C75.6186047,243 77,241.585714 77,239.785714 L77,210.214286 C77,208.414286 75.6186047,207 73.8604651,207 L73.8604651,207 Z M75.744186,239.785714 C75.744186,240.878571 74.927907,241.714286 73.8604651,241.714286 L53.1395349,241.714286 C52.072093,241.714286 51.255814,240.878571 51.255814,239.785714 L51.255814,210.214286 C51.255814,209.121429 52.072093,208.285714 53.1395349,208.285714 L73.8604651,208.285714 C74.927907,208.285714 75.744186,209.121429 75.744186,210.214286 L75.744186,239.785714 L75.744186,239.785714 Z M63.3116279,210.857143 C63.6883721,210.857143 63.9395349,210.6 63.9395349,210.214286 C63.9395349,209.828571 63.6883721,209.571429 63.3116279,209.571429 C62.9348837,209.571429 62.6837209,209.828571 62.6837209,210.214286 C62.6837209,210.6 62.9348837,210.857143 63.3116279,210.857143 L63.3116279,210.857143 Z M63.5627907,236.571429 L63.5,236.571429 C62.3069767,236.571429 61.3651163,237.535714 61.3651163,238.757143 L61.3651163,238.821429 C61.3651163,240.042857 62.3069767,241.007143 63.5,241.007143 L63.5627907,241.007143 C64.755814,241.007143 65.6976744,240.042857 65.6976744,238.821429 L65.6976744,238.757143 C65.6976744,237.535714 64.755814,236.571429 63.5627907,236.571429 L63.5627907,236.571429 Z M64.4418605,238.885714 C64.4418605,239.4 64.0651163,239.785714 63.5627907,239.785714 L63.5,239.785714 C62.9976744,239.785714 62.6209302,239.4 62.6209302,238.885714 L62.6209302,238.821429 C62.6209302,238.307143 62.9976744,237.921429 63.5,237.921429 L63.5627907,237.921429 C64.0651163,237.921429 64.4418605,238.307143 64.4418605,238.821429 L64.4418605,238.885714 L64.4418605,238.885714 Z"
            id="Shape"
          ></path>
        </g>
      </g>
    </svg>
  );
};
