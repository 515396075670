import React, { ReactNode } from 'react';
import { ISessionInterface } from '../../../services';
import {
  analytics,
  DashboardEventType,
  UserAnalyticEvent,
} from '../../../utils';
import { listSessionApi } from '../../../page-api';

interface DropdownItemProps {
  children: ReactNode;
  isItemSelected: boolean;
  clientSession: ISessionInterface;
  sessionIndex: number;
  setSelectedSessionIndex: (index: number) => void;
}
export const DropdownItem = ({
  children,
  clientSession,
  isItemSelected,
  sessionIndex,
  setSelectedSessionIndex,
}: DropdownItemProps) => {
  const onSessionClick = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    e.preventDefault();
    listSessionApi.setCurrentOpenSession(clientSession);
    analytics.dispatch(
      UserAnalyticEvent.SessionListDropdownButtonItem,
      DashboardEventType.Click,
      {
        selectedSessionId: clientSession.session_id,
      }
    );
    setSelectedSessionIndex(sessionIndex);
  };

  return (
    <a
      className={`dropdown-item ${isItemSelected ? 'selected' : ''}`}
      key={clientSession.client_id}
      href="#"
      onClick={onSessionClick}
    >
      {children}
    </a>
  );
};
