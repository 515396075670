import React, { useCallback } from 'react';
import Toggle from 'react-toggle';
import 'react-toggle/style.css';
import { analytics } from '../../utils';
import { themeApi } from '../../page-api';
import { useRxApi } from '../../hooks';

export const ThemeToggle = () => {
  const { result: currentTheme } = useRxApi(themeApi.currentTheme$, {
    initialValue: themeApi.defaultTheme,
    onNext: (theme) => {
      analytics.updateProperties({ theme });
    },
  });

  const onToggleClick = useCallback(() => {
    themeApi.toggleTheme();
  }, []);

  return (
    <div className="theme-toggle">
      <Toggle
        icons={{
          checked: 'Dark Mode',
          unchecked: 'Light Mode',
        }}
        onChange={onToggleClick}
        checked={currentTheme !== themeApi.defaultTheme}
      />
    </div>
  );
};
