import { wifiWizardApi } from './WifiWizardApi';
import { Observable } from 'rxjs';
import {
  IRecommendationServiceRequest,
  IRecommendationServiceResponse,
  IWifiWizardQuestion,
  IWifiWizardRecommendation,
} from '../services';
import { recommendationServiceApi } from '../services/RecommendationServiceApi';
import { debounceTime, map, skipWhile, switchMap } from 'rxjs/operators';
import { analytics } from '../utils';
import { wifiWizardApiData } from './WifiWizardApiData';
import { getAuthorizedPartners } from '../sso';
import { Question } from '../components/Default/TroubleshootingQuestions';

export class RecommendationComponentApi {
  /**
   * Request new recommendations when there's an update to questions & answers
   * This will only update when at least one question is answered. Right now,
   * questionBatch$ seems to be being updated more than you would think
   */
  public get recommendations$(): Observable<IWifiWizardRecommendation[]> {
    return wifiWizardApi.questionBatch$.pipe(
      skipWhile((questions) => {
        if (questions.length === 0) {
          return true;
        }

        for (let i = 0; i < questions.length; i++) {
          const answers = questions[i].chosenAnswerIds;
          if (answers && answers.length > 0) {
            return false;
          }
        }

        return true;
      }),
      debounceTime(40),
      switchMap((questions) => {
        return recommendationServiceApi
          .rxGetRecommendations(
            this.mapLocalQuestionsToRecommendationRequest(questions)
          )
          .pipe(map((response) => this.mapResponseToRecommendations(response)));
      })
    );
  }

  private mapResponseToRecommendations(
    response: IRecommendationServiceResponse
  ) {
    return [
      {
        rootCause: response.rootCause,
        solutions: wifiWizardApiData.mapSolutions(response.solutions),
      },
    ];
  }

  private mapLocalQuestionsToRecommendationRequest(
    localQuestions: IWifiWizardQuestion[]
  ): IRecommendationServiceRequest {
    const answeredQuestions = localQuestions.filter(
      (question) =>
        question.chosenAnswerIds && question.chosenAnswerIds.length > 0
    );
    analytics.updateProperties({
      wizardAnsweredQuestions: answeredQuestions.map((question) => question.id),
      wizardAnswersGiven: answeredQuestions.flatMap(
        (question) => question.chosenAnswerIds
      ),
    });

    return {
      questionData: answeredQuestions.map((question) => ({
        questionID: question.id,
        answers:
          question.chosenAnswerIds?.map((chosenId) => ({
            answerID: chosenId,
          })) ?? [],
      })),
    };
  }
}

export const recommendationComponentApi = new RecommendationComponentApi();
