import { BehaviorSubject, Observable } from 'rxjs';

export const ThemeStorageKey = 'asurion-dashboard-theme';
export const BodyThemeAttribute = 'data-theme';

export enum ThemeType {
  Dark = 'dark',
  Light = 'light',
}

/**
 * This contains all the logic to update the page and make it switch between themes.
 *
 * @class ThemeApi
 */
export class ThemeApi {
  private readonly themeSubject = new BehaviorSubject<ThemeType>(
    (localStorage.getItem(ThemeStorageKey) as ThemeType) ?? ThemeType.Light
  );
  public readonly defaultTheme: ThemeType = ThemeType.Light;

  public get currentTheme$(): Observable<ThemeType> {
    return this.themeSubject.asObservable();
  }

  public get isDarkMode(): boolean {
    return this.themeSubject.value === ThemeType.Dark;
  }

  constructor() {
    // When it gets built it needs to update the theme and get it all set.
    this.updateTheme(this.themeSubject.value);
  }

  /**
   * To manually set the theme to a given ThemeType, you can pass this method your theme.
   *
   * @param {ThemeType} newTheme The new theme you want the document to set
   * @memberof ThemeApi
   */
  public setTheme(newTheme: ThemeType): void {
    this.updateTheme(newTheme);
  }

  /**
   * Toggle a theme, just pass the current one and it will toggle to the opposite.
   * Currently there is only 'dark' or 'default' so it can take much more.
   * @param {ThemeType} [currentTheme=this.themeSubject.value] If no value is passed it will grab the current theme.
   * @memberof ThemeApi
   */
  public toggleTheme(currentTheme: ThemeType = this.themeSubject.value): void {
    const newTheme = this.getThemeOpposite(currentTheme);
    this.updateTheme(newTheme);
  }

  /**
   * This will update your theme and update the document body and set it into local storage.
   *
   * @private
   * @param {ThemeType} theme The new theme to toggle to.
   * @memberof ThemeApi
   */
  private updateTheme(theme: ThemeType): void {
    document.body.setAttribute(BodyThemeAttribute, theme);
    localStorage.setItem(ThemeStorageKey, theme);
    this.themeSubject.next(theme);
  }

  /**
   * Given a theme it will take the theme and hand you back the opposite.
   *
   * @private
   * @param {ThemeType} theme Current theme
   * @returns {ThemeType} The theme that is the opposite
   * @memberof ThemeApi
   */
  private getThemeOpposite(theme: ThemeType): ThemeType {
    switch (theme) {
      case ThemeType.Light:
        return ThemeType.Dark;
      case ThemeType.Dark:
        return ThemeType.Light;
      default:
        return ThemeType.Light;
    }
  }
}

export const themeApi = new ThemeApi();
