import React, { SVGProps } from 'react';

export const WarningCircle = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      className="warning-circle"
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.45312 12.9978C2.45312 7.4748 6.93013 2.9978 12.4531 2.9978C17.9751 2.9978 22.4531 7.4748 22.4531 12.9978C22.4531 18.5208 17.9761 22.9978 12.4531 22.9978C6.93013 22.9978 2.45312 18.5208 2.45312 12.9978ZM13.9428 6.58569H10.9637L11.4723 14.9052H13.4342L13.9428 6.58569ZM11.3815 18.9742C11.6722 19.2648 12.0294 19.4101 12.4532 19.4101C12.8771 19.4101 13.2343 19.2648 13.525 18.9742C13.8156 18.6835 13.9609 18.3324 13.9609 17.9206C13.9609 17.4968 13.8096 17.1395 13.5068 16.8489C13.2162 16.5461 12.865 16.3948 12.4532 16.3948C12.0415 16.3948 11.6843 16.5461 11.3815 16.8489C11.0909 17.1395 10.9456 17.4968 10.9456 17.9206C10.9456 18.3324 11.0909 18.6835 11.3815 18.9742Z"
      />
    </svg>
  );
};
