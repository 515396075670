import React, { useState } from 'react';
import { useRxApi } from '../../../hooks';
import { listSessionApi } from '../../../page-api';
import Moment from 'react-moment';
import { DropdownItem } from './DropdownItem';

export const SessionDropdownV2 = () => {
  const [sessionsInRange, setSessionsInRange] = useState(0);

  const [selectedSessionIndex, setSelectedSessionIndex] = useState<number>(0);

  const { result: clientSessions } = useRxApi(listSessionApi.listSessions$, {
    initialValue: [],
    onNext: () => {
      setSessionsInRange(listSessionApi.getWeekSessionCount());
    },
  });
  const {
    result: currentSession,
  } = useRxApi(listSessionApi.currentOpenSession$, { initialValue: null });

  const shouldAppendDropdown = clientSessions.length >= 1;
  const date = currentSession?.created_at ?? null;

  return (
    <div className="session-dropdown session-dropdown-v2">
      <button
        disabled={!shouldAppendDropdown}
        className={`btn btn-secondary dropdown-toggle ${
          clientSessions.length > 1 ? 'morethanone' : 'one'
        }`}
        type="button"
        id="dropdownMenuButton"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
      >
        {date ? (
          <>
            <Moment local={true} format="MM/DD/YYYY hh:mm A">
              {`${date}Z`}
            </Moment>
            {selectedSessionIndex === 0 && <span className="star"> *</span>}
          </>
        ) : (
          <>N/A</>
        )}
      </button>
      {shouldAppendDropdown && (
        <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
          <div className="overflow">
            {clientSessions.map((clientSession, index) => {
              return (
                <DropdownItem
                  clientSession={clientSession}
                  isItemSelected={
                    clientSession.session_id === currentSession?.session_id
                  }
                  sessionIndex={index}
                  setSelectedSessionIndex={setSelectedSessionIndex}
                  key={clientSession.session_id}
                >
                  {clientSession.created_at ? (
                    <>
                      <Moment local={true} format="MM/DD/YYYY hh:mm A">
                        {`${clientSession.created_at}Z`}
                      </Moment>
                      {index === 0 && <span className="star"> *</span>}
                    </>
                  ) : (
                    'N/A'
                  )}
                </DropdownItem>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
};
