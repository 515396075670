import React from 'react';

import { useSessionStorage } from '../../../hooks';
import { DisplayString } from '../../../constants';
import { getInitiateScanButtonV2, getPartner } from '../../../partner';
import { SessionDropdownV2 } from './SessionDropdown';
import { ScanComponent } from '../index';
import { ResultsPageState } from '../../../pages/index';

interface SubheaderV2Props {
  setResultsPageState: (resultsPageState: ResultsPageState) => void;
}

export const SubheaderV2 = ({ setResultsPageState }: SubheaderV2Props) => {
  const [queryParams] = useSessionStorage<Record<string, string>>(
    'queryParams',
    {}
  );
  const { clientId } = queryParams;
  const partner = getPartner();

  return (
    <ScanComponent className="small-bottom-padding subheader-v2">
      <SessionDropdownV2 />
      <div className="matching-button">
        {getInitiateScanButtonV2(partner, {
          clientId,
          buttonText: DisplayString.NewScan,
          variant: 'primary',
          setResultsPageState: setResultsPageState,
        })}
      </div>
    </ScanComponent>
  );
};
