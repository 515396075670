import React from 'react';

export const AttTvLogo = () => (
  <svg
    width="72"
    height="23"
    className="device-icons"
    viewBox="0 0 72 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M48.5745 0H48.7235C48.7244 1.66429 48.7187 3.32953 48.7235 4.99382C52.3951 5.00042 56.0667 4.98628 59.7393 4.99948C60.5026 7.47424 61.2726 9.94618 62.0303 12.4219C62.2517 13.1428 62.4449 13.8751 62.7295 14.5743C63.7756 11.4022 64.7463 8.20272 65.7132 5.00513C67.7827 4.98723 69.8522 4.98911 71.9227 5.0023C69.791 10.6926 67.6366 16.3743 65.4748 22.0524H59.7496C59.3651 21.3493 59.1503 20.5709 58.8562 19.8292C57.5906 16.4846 56.3607 13.1259 55.0517 9.79822C52.9511 9.80199 50.8505 9.78503 48.7508 9.82367C48.7621 12.0129 48.5727 14.2191 48.8337 16.397C48.8488 17.0114 49.4548 17.3196 49.9882 17.3827C51.4225 17.4977 52.8625 17.427 54.2997 17.4412C53.7305 18.982 53.1302 20.5106 52.5628 22.0524H48.0119C46.9187 21.8139 45.8321 21.452 44.9529 20.7349C43.5892 19.6794 42.9116 17.9095 42.9757 16.2123C42.9804 11.3259 42.9418 6.43853 43.0049 1.55309C44.8681 1.05832 46.7227 0.533403 48.5745 0Z"
      fill="black"
    />
    <path
      opacity="0.98"
      d="M23.1814 5.17768C24.6374 4.55475 26.4563 4.51516 27.7728 5.49715C28.8226 6.23035 29.1233 7.75234 28.5833 8.88605C28.0678 9.81621 27.1546 10.409 26.2602 10.932C26.8313 11.5936 27.4364 12.2269 28.0508 12.8489C28.4758 12.1675 28.834 11.4475 29.2213 10.7445C30.0506 10.7426 30.8809 10.7454 31.7111 10.7605C31.0882 12.012 30.3833 13.2211 29.652 14.4123C30.5849 15.4141 31.5434 16.3933 32.4575 17.412C31.4162 17.4139 30.3635 17.5204 29.3278 17.4026C28.8462 17.1142 28.5211 16.6355 28.1554 16.2199C27.3798 16.7476 26.6127 17.3856 25.6646 17.5543C24.7835 17.7042 23.8571 17.7994 22.9844 17.5515C21.9251 17.3065 20.9922 16.5686 20.488 15.6101C20.0469 14.4745 20.1723 13.0769 21.011 12.1543C21.4492 11.522 22.2079 11.2402 22.7036 10.6757C22.1607 9.83035 21.3955 9.00574 21.4794 7.9182C21.3823 6.76846 22.1692 5.65831 23.1814 5.17768ZM25.0982 6.72794C24.6006 6.78166 23.9834 7.08417 23.9617 7.6449C23.8693 8.40354 24.3839 9.04061 24.9069 9.51841C25.6043 9.2781 26.2517 8.65328 26.3648 7.90218C26.4195 7.22365 25.7947 6.6271 25.0982 6.72794ZM23.9193 12.3258C23.482 12.6529 23.1267 13.108 22.9477 13.6264C22.7441 14.369 23.187 15.2454 23.9466 15.4518C24.8673 15.7873 25.8588 15.4075 26.543 14.7742C25.8814 14.0099 25.1962 13.2664 24.5139 12.5209C24.3632 12.3758 24.1304 12.1468 23.9193 12.3258Z"
      fill="black"
    />
    <path
      opacity="0.98"
      d="M4.63759 5.0033C5.60073 4.99199 6.56387 4.99294 7.52796 5.00236C9.04995 9.15461 10.6153 13.2908 12.1693 17.4299C11.1327 17.4365 10.097 17.4346 9.06126 17.428C8.81246 16.6119 8.55141 15.7995 8.28188 14.9891C6.80701 14.9815 5.3312 14.9834 3.85633 14.9815C3.56702 15.7826 3.3022 16.5912 3.05152 17.4054C2.03466 17.428 1.0178 17.4412 0 17.4516V17.3875C0.251623 16.7438 0.522094 16.1077 0.753927 15.4556C2.00921 11.9573 3.36628 8.49587 4.63759 5.0033ZM4.59801 12.5812C5.55927 12.6538 6.52524 12.618 7.48555 12.5661C7.069 11.0545 6.56482 9.56833 6.06911 8.08121C5.50649 9.55608 5.07675 11.0771 4.59801 12.5812Z"
      fill="black"
    />
    <path
      opacity="0.98"
      d="M9.99234 4.99555C13.4152 4.99367 16.838 4.99367 20.2608 4.99555C20.2627 5.84278 20.2636 6.69 20.2608 7.53723C19.0357 7.54477 17.8115 7.53346 16.5873 7.541C16.5835 10.8385 16.5892 14.136 16.5854 17.4335C15.643 17.4353 14.7016 17.4353 13.7601 17.4335C13.7563 14.1369 13.762 10.8394 13.7582 7.54194C12.501 7.52498 11.2401 7.58152 9.9848 7.49482C9.98669 6.66079 9.9848 5.82864 9.99234 4.99555Z"
      fill="black"
    />
    <path
      opacity="0.98"
      d="M30.2522 4.99555C33.6459 4.99367 37.0404 4.99367 40.435 4.99555C40.4359 5.81074 40.4567 6.62686 40.419 7.44205C39.2146 7.65409 37.9734 7.50896 36.7539 7.53817C36.752 10.8366 36.7568 14.135 36.753 17.4335C35.8106 17.4353 34.8691 17.4353 33.9276 17.4335C33.9239 14.136 33.9295 10.8385 33.9258 7.541C32.7006 7.53346 31.4764 7.54477 30.2522 7.53723C30.2494 6.69 30.2494 5.84278 30.2522 4.99555Z"
      fill="black"
    />
  </svg>
);
