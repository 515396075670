import React from 'react';
import { Redirect } from 'react-router-dom';
import { RoutePaths } from '../components';
import { useAuthentication } from '../sso';

export const LogoutPage = () => {
  const { setUser } = useAuthentication();
  setUser(null);
  return <Redirect to={RoutePaths.Login} />;
};
