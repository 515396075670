import React from 'react';
import { DisplayString } from '../../constants';

const alertTriangleSrc = require('../../img/default/alert-triangle.svg');
const cloudBlockSrc = require('../../img/default/cloud-block.svg');

interface NoDataProps {
  serverError?: boolean;
  msg: string;
}

export const NoData = ({ serverError, msg }: NoDataProps) => {
  return (
    <div className="no-data-found">
      {serverError ? (
        <>
          <img src={cloudBlockSrc} />
          <h2>{msg || `${DisplayString.ConnectionErrorReturnLater}`}</h2>
        </>
      ) : (
        <>
          <img src={alertTriangleSrc} />
          <h2>
            {msg ||
              `${DisplayString.NoScanAssociatedToClientIdYet} ${DisplayString.InitiateScanWithClientForResults}`}
          </h2>
        </>
      )}
    </div>
  );
};
