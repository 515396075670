type SupportedDevices =
  | 'computer'
  | 'fitness_bands'
  | 'game_console'
  | 'headset'
  | 'hub'
  | 'lighting'
  | 'mp3_player'
  | 'multimedia'
  | 'nas_disk'
  | 'phone'
  | 'printer'
  | 'router'
  | 'scanner'
  | 'smart_speaker'
  | 'smoke_detector'
  | 'speaker'
  | 'switch'
  | 'tablet'
  | 'thermostat'
  | 'thing'
  | 'tv'
  | 'unknown'
  | 'wifi';

const DeviceTypeToIcon: Record<SupportedDevices, string> = {
  computer: require('../../../../img/default/devices-icons/computer.svg'),
  fitness_bands: require('../../../../img/default/devices-icons/smartwatch.svg'),
  game_console: require('../../../../img/default/devices-icons/gaming-console.svg'),
  headset: require('../../../../img/default/devices-icons/headphones.svg'),
  hub: require('../../../../img/default/devices-icons/hub.svg'),
  lighting: require('../../../../img/default/devices-icons/lights.svg'),
  mp3_player: require('../../../../img/default/devices-icons/mp-3-player.svg'),
  multimedia: require('../../../../img/default/devices-icons/multimedia.svg'),
  nas_disk: require('../../../../img/default/devices-icons/nas-disk.svg'),
  phone: require('../../../../img/default/devices-icons/phone.svg'),
  printer: require('../../../../img/default/devices-icons/printer.svg'),
  router: require('../../../../img/default/devices-icons/modem.svg'),
  scanner: require('../../../../img/default/devices-icons/scanner.svg'),
  smart_speaker: require('../../../../img/default/devices-icons/smart-speaker.svg'),
  smoke_detector: require('../../../../img/default/devices-icons/smoke-detector.svg'),
  speaker: require('../../../../img/default/devices-icons/speaker.svg'),
  switch: require('../../../../img/default/devices-icons/switches.svg'),
  tablet: require('../../../../img/default/devices-icons/tablet.svg'),
  thermostat: require('../../../../img/default/devices-icons/thermostat.svg'),
  thing: require('../../../../img/default/devices-icons/thing.svg'),
  tv: require('../../../../img/default/devices-icons/tv.svg'),
  unknown: require('../../../../img/default/devices-icons/unknown.svg'),
  wifi: require('../../../../img/default/devices-icons/wifi.svg'),
};

export const getIconSrcByType = (
  iconType: SupportedDevices | undefined | string
): string => {
  if (!iconType) return DeviceTypeToIcon.unknown;

  return DeviceTypeToIcon[iconType] || DeviceTypeToIcon.unknown;
};
