import React, { SVGProps } from 'react';

interface ListIconProps extends SVGProps<SVGSVGElement> {}

export const ListIcon = (props: ListIconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="26"
      height="22"
      viewBox="0 0 26 22"
      {...props}
    >
      <path
        fill="#9100DA"
        fillRule="evenodd"
        d="M8.089 2.804h17.333c.347 0 .578-.224.578-.56 0-.337-.231-.562-.578-.562H8.09c-.347 0-.578.225-.578.561 0 .337.231.56.578.56zM3.467 0H1.733C.751 0 0 .729 0 1.682v1.683c0 .953.751 1.682 1.733 1.682h1.734c.982 0 1.733-.729 1.733-1.682V1.682C5.2.73 4.449 0 3.467 0zm.577 3.365c0 .336-.23.56-.577.56H1.733c-.346 0-.577-.224-.577-.56V1.682c0-.336.23-.56.577-.56h1.734c.346 0 .577.224.577.56v1.683zm21.378 6.73H8.09c-.347 0-.578.223-.578.56 0 .336.231.56.578.56h17.333c.347 0 .578-.224.578-.56 0-.337-.231-.56-.578-.56zM3.467 8.411H1.733C.751 8.412 0 9.14 0 10.094v1.682c0 .954.751 1.683 1.733 1.683h1.734c.982 0 1.733-.73 1.733-1.683v-1.682c0-.953-.751-1.682-1.733-1.682zm.577 3.364c0 .337-.23.561-.577.561H1.733c-.346 0-.577-.224-.577-.56v-1.683c0-.336.23-.56.577-.56h1.734c.346 0 .577.224.577.56v1.682zm21.378 6.73H8.09c-.347 0-.578.224-.578.56 0 .337.231.561.578.561h17.333c.347 0 .578-.224.578-.56 0-.337-.231-.561-.578-.561zM3.467 16.824H1.733c-.982 0-1.733.729-1.733 1.682v1.682c0 .954.751 1.683 1.733 1.683h1.734c.982 0 1.733-.73 1.733-1.683v-1.682c0-.953-.751-1.682-1.733-1.682zm.577 3.364c0 .337-.23.561-.577.561H1.733c-.346 0-.577-.224-.577-.56v-1.683c0-.337.23-.56.577-.56h1.734c.346 0 .577.223.577.56v1.682z"
      />
    </svg>
  );
};
