import React, { CSSProperties } from 'react';
import { SpinnerSvg } from '../../icons/Spinner';

export interface SpinnerProps {
  height?: string;
  styles?: CSSProperties;
}

export const Spinner = (props: SpinnerProps) => {
  const { height = '100px' } = props;
  const spinnerProps = {
    height,
  };

  return (
    <div
      className="dashboard-spinner d-block"
      style={props.styles}
      data-testid="spinner"
    >
      <SpinnerSvg alt="reload" className="reload-svg" {...spinnerProps} />
    </div>
  );
};
