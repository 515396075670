import React, { SVGProps } from 'react';

export const Thing = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="38px"
      height="39px"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 38 39"
      {...props}
    >
      <g fill="#9100DA" fillRule="evenodd">
        <path
          stroke="#FFF"
          d="M25.854 4.846c5.795 5.796 5.795 15.212 0 21.008-5.796 5.795-15.212 5.795-21.008 0-5.795-5.796-5.795-15.212 0-21.008 5.796-5.795 15.212-5.795 21.008 0zm-1.688 19.32c4.845-4.844 4.845-12.788.003-17.63-4.915-4.848-12.79-4.848-17.633-.005-4.848 4.915-4.848 12.79-.005 17.633 4.915 4.848 12.79 4.848 17.635.002zm3.434 2.027l9.107 9.107-2.107 2.107-9.107-9.107 2.107-2.107z"
        />
        <path d="M19.968 4.81h-8.536A2.013 2.013 0 0 0 9.4 6.842v16.664c0 1.139.894 2.033 2.032 2.033h8.536A2.013 2.013 0 0 0 22 23.506V6.842a2.013 2.013 0 0 0-2.032-2.032zm1.22 18.696c0 .691-.53 1.22-1.22 1.22h-8.536c-.69 0-1.22-.529-1.22-1.22V6.842c0-.691.53-1.22 1.22-1.22h8.536c.69 0 1.22.529 1.22 1.22v16.664zM14.561 6.842c0-.244-.163-.407-.407-.407-.243 0-.406.163-.406.407 0 .244.163.406.406.406a.407.407 0 0 0 .407-.406zm2.967-.407h-2.032c-.244 0-.407.163-.407.407 0 .244.163.406.407.406h2.032c.244 0 .406-.162.406-.406 0-.244-.162-.407-.406-.407zm-1.788 16.04H15.7a1.37 1.37 0 0 0-1.382 1.381v.04c0 .773.61 1.383 1.382 1.383h.04a1.37 1.37 0 0 0 1.383-1.382v-.04a1.37 1.37 0 0 0-1.382-1.383zm.569.462a.556.556 0 0 1-.57.57h-.04a.556.556 0 0 1-.569-.57v-.04c0-.325.244-.57.569-.57h.04c.326 0 .57.245.57.57v.04z" />
      </g>
    </svg>
  );
};
