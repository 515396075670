import { Checkbox } from '@npmsoluto/atomic-ui-library-react';
import React, { useState } from 'react';
import { first } from 'rxjs/operators';
import { ContainerSteps, PreScanContentContainer } from '../../components';
import { PinContentContainerV2 } from '../../components/v2/Pin';
import {
  StoredQueryParams,
  useEffectOnce,
  useRouter,
  useSessionStorage,
} from '../../hooks';
import { ResultsPageState } from '../../pages';
import { networkScanApi } from '../../services';
import { logger } from '../../utils';
import { ScanInstructionPropsV2 } from '../contracts';

export const HomeplusScanInstructionsContentV2 = ({
  navigationButtonEnabled$,
  setResultsPageState,
}: ScanInstructionPropsV2) => {
  const [hasDownloadedAppChecked, setHasDownloadedAppChecked] = useState(false);
  const [hasStartedScanChecked, setHasStartedScanChecked] = useState(false);
  const [isStandingByRouterChecked, setIsStandingByRouterChecked] = useState(
    false
  );

  useEffectOnce(() => {
    navigationButtonEnabled$.next(false);
  });

  const router = useRouter();

  const [params, setParams] = useSessionStorage<StoredQueryParams>(
    'queryParams'
  );

  const [displayWarning, setDisplayWarning] = useState(false);

  const getIdByPin = (pinNumber: number) => {
    setDisplayWarning(false);
    networkScanApi
      .getDataByPin(pinNumber)
      .pipe(first())
      .subscribe({
        next: (pinResponse) => {
          setParams({ ...params, clientId: pinResponse?.id });
          setResultsPageState(ResultsPageState.Scanning);
        },
        error: (e) => {
          logger.error('Error in retrieving clientId by Pin', e);
          setDisplayWarning(true);
        },
      });
  };

  const HasDownloadedAppCheckbox = () => (
    <div className="homeplus-scan-instructions-checkbox-container">
      <Checkbox
        checked={hasDownloadedAppChecked}
        onToggle={() => setHasDownloadedAppChecked(!hasDownloadedAppChecked)}
      />
      <span>Yes</span>
    </div>
  );

  const IsStandingByRouterCheckbox = () => (
    <div className="homeplus-scan-instructions-checkbox-container">
      <Checkbox
        checked={isStandingByRouterChecked}
        onToggle={() =>
          setIsStandingByRouterChecked(!isStandingByRouterChecked)
        }
      />
      <span>Yes</span>
    </div>
  );

  const HasStartedScanCheckbox = () => (
    <div className="homeplus-scan-instructions-checkbox-container">
      <Checkbox
        checked={hasStartedScanChecked}
        onToggle={() => setHasStartedScanChecked(!hasStartedScanChecked)}
      />
      <span>Yes</span>
    </div>
  );

  const contentSteps: ContainerSteps[] = [
    {
      contentHtml: `Has customer downloaded scan app?`,
      children: <HasDownloadedAppCheckbox />,
    },
    {
      contentHtml: `Is customer standing near the wifi router?`,
      children: <IsStandingByRouterCheckbox />,
    },
    {
      contentHtml: `Did the customer start the scan?`,
      children: <HasStartedScanCheckbox />,
    },
    {
      contentHtml: `Enter pin code`,
      children: (
        <PinContentContainerV2
          onPinSubmit={getIdByPin}
          shouldEnableButton={
            hasDownloadedAppChecked &&
            isStandingByRouterChecked &&
            hasStartedScanChecked
          }
          displayWarning={displayWarning}
        />
      ),
    },
  ];

  return (
    <PreScanContentContainer
      steps={contentSteps}
      customClass="asurion-scan-content-step"
      v2={true}
      showStepNumber={false}
    />
  );
};
