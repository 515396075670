import React, { SVGProps } from 'react';

export const Switches = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="30px"
      height="30px"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 30 30"
      {...props}
    >
      <path
        fill="#9100DA"
        fillRule="evenodd"
        d="M25 0H5a4.98 4.98 0 0 0-5 5v20c0 2.778 2.222 5 5 5h20c2.778 0 5-2.222 5-5V5c0-2.778-2.222-5-5-5zm3.889 25A3.865 3.865 0 0 1 25 28.889H5A3.865 3.865 0 0 1 1.111 25V5A3.865 3.865 0 0 1 5 1.111h20A3.865 3.865 0 0 1 28.889 5v20zm-10-22.222h-7.222a2.75 2.75 0 0 0-2.778 2.778v18.888a2.75 2.75 0 0 0 2.778 2.778h7.222a2.75 2.75 0 0 0 2.778-2.778V5.556a2.75 2.75 0 0 0-2.778-2.778zm1.667 21.666c0 .945-.723 1.667-1.667 1.667h-7.222A1.637 1.637 0 0 1 10 24.444v-10a2.856 2.856 0 0 0 1.667.556h7.222c.611 0 1.222-.222 1.667-.556v10zm0-12.222c0 .945-.723 1.667-1.667 1.667h-7.222A1.637 1.637 0 0 1 10 12.222V5.556c0-.945.722-1.667 1.667-1.667h7.222c.944 0 1.667.722 1.667 1.667v6.666zM4.722 3.333c-.778 0-1.389.611-1.389 1.39 0 .777.611 1.388 1.39 1.388.777 0 1.388-.611 1.388-1.389S5.5 3.333 4.722 3.333zm20.556 0c-.778 0-1.39.611-1.39 1.39 0 .777.612 1.388 1.39 1.388.778 0 1.389-.611 1.389-1.389s-.611-1.389-1.39-1.389zM4.722 23.89c-.778 0-1.389.611-1.389 1.389s.611 1.389 1.39 1.389c.777 0 1.388-.611 1.388-1.39 0-.777-.611-1.388-1.389-1.388zm20.556 0c-.778 0-1.39.611-1.39 1.389s.612 1.389 1.39 1.389c.778 0 1.389-.611 1.389-1.39 0-.777-.611-1.388-1.39-1.388zm-7.5-18.333h-5c-.334 0-.556.222-.556.555 0 .333.222.556.556.556h5c.333 0 .555-.223.555-.556 0-.333-.222-.555-.555-.555zm0 2.777h-5c-.334 0-.556.223-.556.556 0 .333.222.555.556.555h5c.333 0 .555-.222.555-.555 0-.333-.222-.556-.555-.556zm0 2.778h-5c-.334 0-.556.222-.556.556 0 .333.222.555.556.555h5c.333 0 .555-.222.555-.555 0-.334-.222-.556-.555-.556z"
      />
    </svg>
  );
};
