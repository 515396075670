import React from 'react';
import { WifiWizardIssue, WifiWizardSolutionList } from './index';
import {
  IWifiWizardRecommendation,
  IWifiWizardSolution,
} from '../../../services/index';
import { useRxApi } from '../../../hooks';
import { recommendationComponentApi } from '../../../page-api/RecommendationComponentApi';
import { analytics } from '../../../utils';
import { withLoadingSkeleton } from '../index';
import { of } from 'rxjs';
import { delay } from 'rxjs/operators';

export const WifiWizardRecommendationWrapper = ({
  numSolutionsDisplayed,
  onFeedbackSubmitted = () => null,
  isExpanded,
}: {
  numSolutionsDisplayed?: number;
  onFeedbackSubmitted?: () => void;
  isExpanded?: boolean;
}) => {
  const { result: recommendations } = useRxApi(
    recommendationComponentApi.recommendations$,
    {
      initialValue: [],
    }
  );

  const recommendation = withLoadingSkeleton(
    <WifiWizardRecommendation
      numSolutionsDisplayed={numSolutionsDisplayed}
      onFeedbackSubmitted={onFeedbackSubmitted}
      recommendation={recommendations[0]}
    />,
    of(!!isExpanded).pipe(delay(1500))
  );

  return (
    <>{!!recommendations && recommendations.length > 0 && recommendation}</>
  );
};

interface WifiWizardRecommendationProps {
  recommendation: IWifiWizardRecommendation;
  numSolutionsDisplayed?: number;
  onFeedbackSubmitted?: () => void;
}

const solutionsListForAnalytics = (
  solutions: IWifiWizardSolution[]
): { id: string; rank: number }[] => {
  return solutions.map((solution) => {
    return { id: solution.id, rank: solution.rank };
  });
};

/**
 * Given an issue title, issue description, solution title, and solution
 * description, `WifiWizardRecommendation` gives a `WifiWizardIssue` and a
 * `WifiWizardSolution`
 */
export const WifiWizardRecommendation = ({
  recommendation,
  numSolutionsDisplayed,
  onFeedbackSubmitted = () => null,
}: WifiWizardRecommendationProps) => {
  // a negative numSolutionsDisplayed will display (# solutions) + numSolutionsDisplayed
  const solutions = numSolutionsDisplayed
    ? recommendation.solutions.slice(0, numSolutionsDisplayed)
    : recommendation.solutions;
  analytics.updateProperties({
    recommendations: solutionsListForAnalytics(solutions),
  });
  return (
    <>
      <WifiWizardIssue title={recommendation.rootCause} />
      <WifiWizardSolutionList
        onFeedbackSubmitted={onFeedbackSubmitted}
        solutions={solutions}
      />
    </>
  );
};
