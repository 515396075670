import React, { SVGProps } from 'react';

export const CircleCheck = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      className="circle-check"
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M12.2295 2C6.70649 2 2.22949 6.477 2.22949 12C2.22949 17.523 6.70649 22 12.2295 22C17.7525 22 22.2295 17.523 22.2295 12C22.2295 6.477 17.7515 2 12.2295 2ZM11.4795 17.75L6.47949 14L7.97949 12L10.9795 14.25L16.2295 7.25L18.2295 8.75L11.4795 17.75Z" />
    </svg>
  );
};
