import React, { useState } from 'react';
import { IWifiWizardQuestion } from '../../../services';
import {
  analytics,
  DashboardEventType,
  UserAnalyticEvent,
} from '../../../utils';
import { BaseButton, H1 } from '../../CommonComponents';
import { Question } from './Question';

interface QuestionSetProps {
  questions: IWifiWizardQuestion[];
  isLoading: boolean;
  onQuestionSubmit: (question: IWifiWizardQuestion, answers: string[]) => void;
  onQuestionsFinished?: (
    questionsCount: number,
    answeredQuestionsCount: number
  ) => void;
  setIsExpanded: (isExpanded: boolean) => void;
}

const questionsListForAnalytics = (
  questions: IWifiWizardQuestion[]
): string[] => {
  return questions.map((question) => question.id);
};

export const QuestionSet = ({
  questions,
  isLoading,
  onQuestionSubmit,
  onQuestionsFinished,
  setIsExpanded,
}: QuestionSetProps) => {
  // Rules of hooks mean that we must call the hooks every render
  const [questionIndex, setQuestionIndex] = useState(0);
  const [answeredQuestions, setAnsweredQuestions] = useState<Set<number>>(
    new Set<number>()
  );

  if (isLoading) {
    return <H1>Loading...</H1>;
  }

  const nextQuestion = () => {
    if (questionIndex < questions.length - 1) {
      setQuestionIndex(questionIndex + 1);
    }

    if (questionIndex === questions.length - 1) {
      setIsExpanded(false);
      onQuestionsFinished?.(questions.length, answeredQuestions.size);
    }
  };

  const prevQuestion = () => {
    if (questionIndex > 0) {
      setQuestionIndex(questionIndex - 1);
    }
  };

  const onAnsweredQuestion = (index: number) => (
    question: IWifiWizardQuestion,
    answerIds: string[]
  ) => {
    setAnsweredQuestions(new Set(answeredQuestions.add(index)));
    onQuestionSubmit(question, answerIds);
    analytics.updateProperties({
      wizardQuestions: questionsListForAnalytics(questions),
    });
    analytics.dispatch(
      UserAnalyticEvent.QuestionAnswered,
      DashboardEventType.Business,
      {
        questionId: question.id,
        answerIds: answerIds,
        currentQuestion: questionIndex + 1,
        totalQuestions: questions.length,
      }
    );
  };

  const percentageComplete = (
    (answeredQuestions.size / questions.length) *
    100
  ).toFixed(0);

  const NextButton = () => {
    const nextText = questionIndex < questions.length - 1 ? 'Next' : 'Finish';
    const variant =
      questionIndex < questions.length - 1 ? 'primary-ghost' : 'primary';
    const disabled =
      answeredQuestions.size === 0 && questionIndex === questions.length - 1;
    return (
      <BaseButton
        analyticName={UserAnalyticEvent.NextCustomerQuestion}
        onClick={nextQuestion}
        variant={variant}
        disabled={disabled}
      >
        {nextText}
      </BaseButton>
    );
  };

  /**
   * We still need this button to occupy space so that the question counter
   * flexes correctly
   */
  const PrevButton = () => {
    const styles = {
      opacity: '100%',
    };
    if (questionIndex === 0) {
      styles.opacity = '0%';
    }

    return (
      <BaseButton
        style={styles}
        analyticName={UserAnalyticEvent.PrevCustomerQuestion}
        onClick={prevQuestion}
        variant="primary-ghost"
      >
        Previous
      </BaseButton>
    );
  };

  return (
    <div className="troubleshooting-questions">
      <Question
        question={questions[questionIndex]}
        onSubmit={onAnsweredQuestion(questionIndex)}
        key={questions[questionIndex].id}
      />
      <div className="troubleshooting-nav">
        <NextButton />
        Question {questionIndex + 1}&nbsp;of&nbsp;{questions.length}
        <PrevButton />
      </div>
    </div>
  );
};
