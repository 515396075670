import { analytics, DashboardEventType, UserAnalyticEvent } from '../utils';
import { IHomeGraphResponse } from '../services';

/**
 * Send an analytic event as part of the home graph polling
 * @param {string} listSessionId Current Session Id that is visible
 * @param {IHomeGraphResponse} homeGraphsData Data from that session
 */
export const sendHomeGraphBusinessEvent = (
  listSessionId: string,
  homeGraphsData: IHomeGraphResponse
): void => {
  analytics.dispatch(
    UserAnalyticEvent.HomeGraphDataReceived,
    DashboardEventType.Business,
    {
      scanId: listSessionId,
      numIssuesFound: homeGraphsData.expertTips?.length ?? 'undefined',
      numDevices: homeGraphsData.devicesClassified?.length ?? 'undefined',
      isp: homeGraphsData.ispData ?? 'undefined',
    }
  );
};
