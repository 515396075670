import {
  MergedPartnerConfig,
  PartnerConfigFile,
  PartnerConfiguration,
} from './interface';
import { RouteProperties } from '../../components';
import { PartnerKeys } from '../../constants';

const appConfig = require('appConfig');

interface UrlConfiguration {
  clientId?: string;
  socoSupportSessionId?: string;
  partner: any;
}

let partnerConfig: PartnerConfiguration = {
  partnerNameUrl: PartnerKeys.missing,
  clientId: '',
  socoSupportSessionId: '',
};

export const isValidPartner = (partner: string): boolean => {
  return Object.keys(PartnerKeys).some(
    (partnerKey) => partnerKey === partner && partnerKey !== PartnerKeys.missing
  );
};

export const flattenUrlConfigs = (
  urlConfiguration: UrlConfiguration
): PartnerConfiguration => {
  return (partnerConfig = {
    partnerNameUrl: urlConfiguration.partner ?? appConfig.partner,
    clientId: urlConfiguration.clientId
      ? urlConfiguration.clientId
      : partnerConfig.clientId,
    socoSupportSessionId: urlConfiguration.socoSupportSessionId
      ? urlConfiguration.socoSupportSessionId
      : partnerConfig.socoSupportSessionId,
  });
};

export const mergePartnerContext = (
  context: PartnerConfigFile
): MergedPartnerConfig => ({
  ...partnerConfig,
  ...context,
});

export const getPartnerConfig = (): PartnerConfiguration => partnerConfig;

export const getApplicationName = () => 'soco';

export const getPartner = (): PartnerKeys =>
  partnerConfig.partnerNameUrl ?? PartnerKeys.missing;

export const setPartner = (partner: PartnerKeys) => {
  partnerConfig.partnerNameUrl = partner;
};

export const getClientIdWithPartnerUtils = (): string =>
  partnerConfig.clientId!;

export const isRouteEnabled = (
  routeProperties: RouteProperties,
  urlPartner: string,
  userAuthorizedPartners: string[]
): boolean => {
  const { carrierRoute } = routeProperties;
  if (!carrierRoute) return true;
  const { partners } = carrierRoute;
  return (
    partners.some((partner) => partner === urlPartner) &&
    partners.some((partner) =>
      userAuthorizedPartners.some(
        (userAuthorizedPartner) => userAuthorizedPartner === partner
      )
    )
  );
};
