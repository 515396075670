import React, { SVGProps } from 'react';

export const ArrowDown = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="22"
      height="49"
      viewBox="0 0 22 49"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M21 37.994L11 47.994L1 37.994"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11 47.994V1.99396"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
