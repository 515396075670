export const DEFAULT_NO_OF_DAYS = 7;
export const ASURION_CLIENT_VERSION = '5.86.14';

export enum DisplayString {
  AttAppConfirmScan = 'Start my scan',
  AttAppSettingsButtonTitle = 'Streaming support',
  ClientIdMissing = 'The ClientId is missing in the URL. Please try the link again.',
  ConnectionErrorReturnLater = 'Unable to connect to the server. Please try again later.',
  English = 'English',
  ExpertDashboard = 'Expert Dashboard',
  Feedback = 'Have feedback? We’d love to hear it but please don’t share sensitive information.',
  HelpUsImprove = 'Help us improve',
  InitiateScan = 'Initiate Scan',
  InitiateScanWithClientForResults = 'Initiate a scan with the client for results.',
  NoScanAssociatedToClientIdYet = 'No scan is associated with this client ID yet.',
  ScanFailedAfterCustomerRestartedRefresh = 'After customer has restarted scan, refresh your screen.',
  ScanFailedAskCustomerToCheckConnection = "Ask customer to check connection to home Wi-Fi and tap 'Start my scan' again.",
  ScanFailedUnableToComplete = 'Unable to successfully complete scan.',
  IncorrectPartnerProvided = 'Please select a partner to continue',
  ScanInProgress = 'Scan In Progress',
  SendFeedback = 'Send Feedback',
  SendFeedbackButton = 'Send',
  Sending = 'Sending',
  SendingFailed = 'Sending Failed...',
  Sent = 'Sent',
  SignInWithAsurion = 'Sign in with Asurion',
  Spanish = 'Spanish',
  StartNewScan = 'Start New Scan',
  NewScan = 'New Scan',
  SomethingWentWrong = 'Something went wrong. Try again.',
  VzwDynamicComponentButton = 'Start checkup',
  VzwPreScanSendScanNow = 'Send scan now',
}

export enum PartnerKeys {
  att = 'att',
  vzw = 'vzw',
  homeplus = 'homeplus',
  missing = 'missing',
  all = 'all', // This is a fallback for contentful.
}

interface IPartnerProperties {
  name: string;
  appName: string;
  partner: string;
  tableName: string;
}

export enum URL_PARAMS {
  clientId = 'clientId',
  authToken = 'authToken',
}
export const defaultUrlParams: string[] = [
  URL_PARAMS.clientId,
  URL_PARAMS.authToken,
];

export const PartnerKeyProperties: Record<PartnerKeys, IPartnerProperties> = {
  [PartnerKeys.att]: {
    name: 'ATT',
    appName: 'ProTech',
    partner: 'att',
    tableName: 'att',
  },
  [PartnerKeys.vzw]: {
    name: 'Verizon',
    appName: 'Tech Coach',
    partner: 'vzw',
    tableName: 'vzw',
  },
  [PartnerKeys.homeplus]: {
    name: 'Home+',
    appName: 'Asurion Scan',
    partner: 'homeplus',
    tableName: 'skan',
  },
  [PartnerKeys.missing]: {
    name: '',
    appName: '',
    partner: '',
    tableName: '',
  },
  [PartnerKeys.all]: {
    name: '',
    appName: '',
    partner: '',
    tableName: '',
  },
};
