import React, { SVGProps } from 'react';

export const GoodVideoChatIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="34"
      height="34"
      viewBox="0 0 34 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M21 11.999V12.999L24 11.999V15.999L21 14.999V15.999C21 16.552 20.552 16.999 20 16.999H16C15.448 16.999 15 16.552 15 15.999V11.999C15 11.446 15.448 10.999 16 10.999H20C20.552 10.999 21 11.446 21 11.999Z"
        fill="black"
      />
      <path
        d="M11 20.499C11.9665 20.499 12.75 19.7155 12.75 18.749C12.75 17.7825 11.9665 16.999 11 16.999C10.0335 16.999 9.25 17.7825 9.25 18.749C9.25 19.7155 10.0335 20.499 11 20.499Z"
        fill="black"
      />
      <path
        d="M8.375 23.999C8.375 22.388 9.389 21.374 11 21.374C12.61 21.374 13.625 22.388 13.625 23.999H8.375Z"
        fill="black"
      />
      <path d="M9 29.999V27.999H13V29.999H9Z" fill="black" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.6315 30.733L19.9995 25.837V18.999H17.9995V24.999H3.99953V15.999H12.9995V13.999H2.99953C2.44753 13.999 1.99953 14.446 1.99953 14.999V25.837L0.367526 30.733C0.181526 31.291 0.257526 31.863 0.575526 32.304C0.893526 32.745 1.41153 32.999 1.99953 32.999H19.9995C20.5875 32.999 21.1065 32.746 21.4245 32.305C21.7425 31.864 21.8175 31.291 21.6315 30.733ZM2.38753 30.999L3.72053 26.999H18.2795L19.6125 30.999H2.38753Z"
        fill="black"
      />
      <circle className="override-color" cx="24" cy="10" r="10" />
      <path
        className="override-icon-good"
        d="M24 0C18.477 0 14 4.477 14 10C14 15.523 18.477 20 24 20C29.523 20 34 15.523 34 10C34 4.477 29.522 0 24 0ZM23.25 15.75L18.25 12L19.75 10L22.75 12.25L28 5.25L30 6.75L23.25 15.75Z"
      />
    </svg>
  );
};
