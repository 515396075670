import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRxApi } from '../../../hooks';
import { homeGraphApi, themeApi } from '../../../page-api';
import { noValueFormatter } from '../../../utils';
import { BaseImage } from '../../../atomic-ui';
import { map } from 'rxjs/operators';

const arrowImg = require('../../../img/default/arrow.png');
const arrowImgWhite = require('../../../img/default/arrow-white.png');

export const NoData = 'N/A';

export const UploadDownloadSpeeds = () => {
  const { t: translate } = useTranslation();

  const { result: isDarkMode } = useRxApi(
    themeApi.currentTheme$.pipe(map(() => themeApi.isDarkMode)),
    {
      initialValue: false,
    }
  );

  const { result: internetHealth } = useRxApi(homeGraphApi.internetHealth$, {
    initialValue: {},
  });

  const { downloadThroughput, uploadThroughput } = internetHealth;
  return (
    <div className="upload-download-speeds">
      <h2>{translate('speed_test_results')}</h2>
      <div className="speed-container">
        <div className="wrapper">
          <p className="speed">{noValueFormatter(downloadThroughput)}</p>
          <div className="stack">
            <p className="unit-of-measure">{translate('mbps')}</p>
            <BaseImage
              className="arrow"
              src={isDarkMode ? arrowImgWhite : arrowImg}
            />
          </div>
          <p className="text">{translate('download')}</p>
        </div>
        <div className="upload wrapper">
          <p className="speed">{noValueFormatter(uploadThroughput)}</p>
          <div className="stack">
            <p className="unit-of-measure">{translate('mbps')}</p>
            <BaseImage
              className="arrow"
              src={isDarkMode ? arrowImgWhite : arrowImg}
            />
          </div>
          <p className="text">{translate('upload')}</p>
        </div>
      </div>
    </div>
  );
};
