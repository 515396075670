import React from 'react';

export const LoadingSpinner = () => {
  return (
    <svg
      className="loading-spinner"
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M12.1875 2C13.9222 2 15.627 2.45124 17.1346 3.3094C18.6421 4.16756 19.9005 5.40312 20.7861 6.8947C21.6717 8.38628 22.1541 10.0826 22.1858 11.817C22.2176 13.5514 21.7976 15.2642 20.9672 16.7872C20.1368 18.3102 18.9244 19.591 17.4493 20.5037C15.9742 21.4165 14.287 21.9298 12.5535 21.9933C10.82 22.0568 9.09975 21.6682 7.56181 20.8658C6.02387 20.0634 4.72111 18.8747 3.78151 17.4166L5.42944 16.3547C6.18484 17.527 7.2322 18.4826 8.46864 19.1278C9.70508 19.7729 11.0881 20.0852 12.4817 20.0342C13.8754 19.9831 15.2318 19.5704 16.4178 18.8366C17.6037 18.1028 18.5784 17.0731 19.246 15.8487C19.9136 14.6243 20.2513 13.2472 20.2257 11.8529C20.2002 10.4585 19.8124 9.09473 19.1004 7.89556C18.3884 6.69639 17.3767 5.70305 16.1647 5.01313C14.9527 4.32321 13.5821 3.96043 12.1875 3.96043V2Z" />
    </svg>
  );
};
