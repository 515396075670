import React, { SVGProps } from 'react';

export const CheckIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M10.042 0C4.51899 0 0.0419922 4.477 0.0419922 10C0.0419922 15.523 4.51899 20 10.042 20C15.565 20 20.042 15.523 20.042 10C20.042 4.477 15.564 0 10.042 0ZM9.29199 15.75L4.29199 12L5.79199 10L8.79199 12.25L14.042 5.25L16.042 6.75L9.29199 15.75Z"
        fill="#008662"
      />
    </svg>
  );
};
