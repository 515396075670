import {
  IRecommendationServiceRequest,
  IRecommendationServiceResponse,
} from './contracts';
import { Observable } from 'rxjs';
import { rxFetch } from './rx-fetch';

import {
  DashboardEventType,
  dispatchAnalyticEvent,
  dispatchAnalyticEventOnError,
  UserAnalyticEvent,
} from '../utils';
import { defaultTweekRepository } from '../tweek';
import { getAuthToken } from '../sso';
import { shouldUseHomeMgmt } from './SharedServiceUtils';

const appConfig = require('appConfig');

export class RecommendationServiceApi {
  private get getRecommendationurl(): string {
    if (shouldUseHomeMgmt()) {
      return `${appConfig.mgmtApi}/expert/question/getRawRecommendationIds`;
    } else {
      return `${appConfig.recommendationApiBase}/predict`;
    }
  }

  public rxGetRecommendations(
    request: IRecommendationServiceRequest
  ): Observable<IRecommendationServiceResponse> {
    return rxFetch<IRecommendationServiceResponse>(this.getRecommendationurl, {
      method: 'POST',
      headers: new Headers({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getAuthToken()}`,
      }),
      body: JSON.stringify(request),
    }).pipe(
      dispatchAnalyticEvent(
        UserAnalyticEvent.RecommendationServiceSuccess,
        DashboardEventType.Business
      ),
      dispatchAnalyticEventOnError(
        UserAnalyticEvent.RecommendationServiceFailure,
        DashboardEventType.Business
      )
    );
  }
}

export const recommendationServiceApi = new RecommendationServiceApi();
