import React, { SVGProps } from 'react';

export const ArrowUp = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="22"
      height="49"
      viewBox="0 0 22 49"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M1 11.9943L11 1.99426L21 11.9943"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11 1.99414V47.9941"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
