import React, { SVGProps } from 'react';

export const ArrowHorizontalLeft = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="16"
      height="13"
      viewBox="0 0 16 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="arrow-horizontal-left"
      {...props}
    >
      <path
        id="arrow-horizontal-shape"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.08922 5.37061L7.99997 1.78061L6.58997 0.370605L0.589966 6.37061L6.58997 12.3706L7.99997 10.9606L4.08922 7.37061H16V5.37061H4.08922ZM2.99997 6.37052L2.99988 6.37061L2.99997 6.37069V6.37052Z"
      />
    </svg>
  );
};
