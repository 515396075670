import React, { useEffect, useState } from 'react';
import { Observable } from 'rxjs';

interface LoadingSkeletonProps {
  numLines?: number;
}

export const LoadingSkeleton = ({ numLines = 4 }: LoadingSkeletonProps) => {
  const skeletonBody =
    numLines === 1 ? (
      <div className="large gradient"></div>
    ) : (
      new Array<string>(numLines)
        .fill('gradient')
        .map((classname, i) =>
          i === 0 ? (
            <div key={i} className={`small ${classname}`}></div>
          ) : (
            <div key={i} className={`large ${classname}`}></div>
          )
        )
    );

  return <div className="loading-skeleton">{skeletonBody}</div>;
};

export const WithLoadingSkeleton = (
  Content: React.ReactNode,
  isFirstLoadFinished$: Observable<boolean>
) => {
  // use state allows it to persist beyond renders
  const [showContent, setShowContent] = useState(false);

  // this allows for an initial value of true to cause the content to show
  useEffect(() => {
    const subscription = isFirstLoadFinished$.subscribe((result) => {
      if (result) setShowContent(true);
    });
    return () => {
      subscription.unsubscribe();
    };
  });

  return showContent ? Content : <LoadingSkeleton />;
};
