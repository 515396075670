import React from 'react';

export const NetflixLogo = () => (
  <svg
    width="64"
    height="18"
    className="device-icons"
    viewBox="0 0 64 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.80019 16.1815C7.83744 16.3506 6.85775 16.4012 5.84431 16.5362L2.75319 7.48275V16.9248C1.79044 17.0261 0.912125 17.1613 0 17.2964V0H2.5675L6.08075 9.81381V0H8.80019V16.1815ZM14.1209 6.33413C15.1682 6.33413 16.7728 6.28344 17.7356 6.28344V8.98594C16.5363 8.98594 15.1344 8.98594 14.1209 9.03662V13.0568C15.7087 12.9554 17.2964 12.8202 18.901 12.7695V15.3706L11.4183 15.9619V0H18.901V2.70256H14.1209V6.33413ZM28.9511 2.70263H26.1472V15.1344C25.2351 15.1344 24.323 15.1344 23.4448 15.1681V2.70263H20.6409V0H28.9513L28.9511 2.70263V2.70263ZM33.3428 6.14838H37.0419V8.85087H33.3428V14.9824H30.6907V0H38.2412V2.70256H33.3428V6.14838ZM42.6328 12.6176C44.1699 12.6512 45.7238 12.7697 47.2272 12.854V15.5228C44.8118 15.3707 42.3964 15.2189 39.9303 15.1681V0H42.6328V12.6176ZM49.5074 15.7086C50.3689 15.7594 51.281 15.8101 52.1593 15.9113V0H49.5074V15.7086ZM64 0L60.5711 8.22594L64 17.2964C62.9864 17.1613 61.973 16.9754 60.9595 16.8065L59.0171 11.8069L57.0411 16.4013C56.0612 16.2322 55.1153 16.1815 54.1358 16.0464L57.6153 8.1245L54.4735 0H57.3786L59.1522 4.54369L61.0441 0H64Z"
      fill="black"
    />
  </svg>
);
