import React, { SVGProps } from 'react';
import { ListIcon } from './ListIcon';
import { GraphIcon } from './GraphIcon';

type DisplayIconType = 'list' | 'graph';
export const getIconByType = (
  iconType: DisplayIconType,
  props: SVGProps<SVGSVGElement>
): JSX.Element | null => {
  switch (iconType) {
    case 'list':
      return <ListIcon {...props} />;
    case 'graph':
      return <GraphIcon {...props} />;
    default:
      return null;
  }
};

interface DisplayIconProps extends SVGProps<SVGSVGElement> {
  iconType: DisplayIconType;
  onIconClick: (type: DisplayIconType) => void;
}
export const DisplayIcon = ({
  iconType,
  onIconClick,
  ...props
}: DisplayIconProps) => {
  const onClick = () => {
    onIconClick(iconType);
  };
  return getIconByType(iconType, { onClick, ...props });
};
