import React, { useEffect, useState } from 'react';
import { useTweekValue } from 'react-tweek';
import { first } from 'rxjs/operators';
import { Header, RoutePaths, Spinner } from '../components';
import { defaultUrlParams, PartnerKeys, URL_PARAMS } from '../constants';
import {
  StoredQueryParams,
  useAuthenticatedRouting,
  useRouter,
  useSessionStorage,
} from '../hooks';
import { isValidPartner } from '../partner';
import {
  Auth,
  getAuthorizedPartners,
  getAuthTokenAsync,
  useAuthentication,
} from '../sso';
import { updateTweekContext } from '../tweek';

export const HomePage = () => {
  const {
    setUser,
    setUserAuthorizedPartners,
    isLoggedIn,
  } = useAuthentication();
  const [authToken, setAuthToken] = useState<string | null>(null);

  const router = useRouter();
  const [params] = useSessionStorage<StoredQueryParams>('queryParams');
  const partner = (params && params.partner) || '';
  updateTweekContext({ partner: partner });

  const urlParams: string[] = useTweekValue(
    'network_scan/expert_dashboard/url_params',
    defaultUrlParams
  );

  useAuthenticatedRouting((_) => {
    router.push({ pathname: RoutePaths.Login });
  });

  useEffect(() => {
    if (isLoggedIn && !isValidPartner(partner)) {
      router.push({ pathname: RoutePaths.IncorrectPartner });
      return;
    }

    if (isLoggedIn) {
      router.push({
        pathname: RoutePaths.ScanInstructions,
        search: '',
      });
      return;
    }
    while (!isLoggedIn) {
      const ref = setInterval(async () => {
        const user = await Auth.currentUserInfo();
        if (user) {
          const partners = await getAuthorizedPartners();
          setUserAuthorizedPartners(partners);
          setAuthToken(await getAuthTokenAsync());
          setUser(user);
        }
      }, 200);
      return () => {
        clearInterval(ref);
      };
    }
    // ! The component refreshes on router.push(...) if you do not use this override
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn, setUser, setUserAuthorizedPartners, urlParams]);

  return (
    <div>
      <Header />
      {!isLoggedIn && <Spinner />}
    </div>
  );
};
