import React, {
  ButtonHTMLAttributes,
  DetailedHTMLProps,
  ReactNode,
  useCallback,
} from 'react';
import ReactTooltip from 'react-tooltip';
import { BaseButton, ButtonVariant } from '../Button/BaseButton';
import { DisplayString } from '../../../constants';
import { useTranslation } from 'react-i18next';
import { UserAnalyticEvent } from '../../../utils';
import { first } from 'rxjs/operators';
import { Observable } from 'rxjs';

interface InitiateScanButtonProps
  extends DetailedHTMLProps<
    ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  children?: ReactNode;
  buttonText?: DisplayString;
  clientId: string;
  analyticName?: UserAnalyticEvent;
  triggerScan$: Observable<any>;
  tooltipMessage?: string;
  variant?: ButtonVariant;
  onClick?: () => void;
}

export const InitiateScanButton = ({
  clientId,
  buttonText = DisplayString.InitiateScan,
  analyticName = UserAnalyticEvent.InitiateScanButton,
  children,
  tooltipMessage,
  triggerScan$,
  onClick,
  ...props
}: InitiateScanButtonProps) => {
  const { t: translation } = useTranslation();

  const onScanButtonClick = useCallback(() => {
    onClick?.();
    triggerScan$.pipe(first()).subscribe();
  }, [onClick, triggerScan$]);

  return (
    <>
      <BaseButton
        variant="primary-ghost"
        analyticName={analyticName}
        {...props}
        onClick={onScanButtonClick}
      >
        {children ? children : buttonText}
      </BaseButton>
      {tooltipMessage && (
        <>
          <p
            className="request_error text"
            data-html="true"
            data-tip={tooltipMessage}
            data-place="bottom"
            data-type="error"
            data-effect="solid"
          >
            {translation('error_initiating_scan')}
          </p>
          <ReactTooltip />
        </>
      )}
    </>
  );
};
