import React, { useCallback } from 'react';
import { contentCacheApi, IExpertTip } from '../../../services';
import { Accordion } from '../../../atomic-ui';
import { useRxApi } from '../../../hooks';
import { getPartner } from '../../../partner';
import {
  analytics,
  DashboardEventType,
  UserAnalyticEvent,
} from '../../../utils';

const networkErrorIconSrc = require('../../../img/default/network-error.png');

interface TipProps {
  expertTip: IExpertTip;
}

export const Tip = ({ expertTip }: TipProps) => {
  const { description, name, tipId } = expertTip;
  const partner = getPartner();
  const { result: tipIdContentfulMapping } = useRxApi(
    contentCacheApi.getExpertTips(partner),
    {
      initialValue: {},
    }
  );
  const helixData = tipIdContentfulMapping[tipId];

  const onAccordionExpanded = useCallback(() => {
    analytics.dispatch(
      UserAnalyticEvent.TipDropdownExpanded,
      DashboardEventType.Business,
      {
        tipId,
        displayName: helixData?.title ?? name,
      }
    );
  }, [helixData, name, tipId]);

  return (
    <Accordion
      className="expert-tip"
      label={helixData?.title ?? name}
      leftIconSrc={networkErrorIconSrc}
      onAccordionExpanded={onAccordionExpanded}
    >
      {helixData ? (
        <div className="tip-wrapper">
          <div
            className="tip-box"
            dangerouslySetInnerHTML={{ __html: helixData.descriptionHtml }}
          />
          <div
            className="tip-box"
            dangerouslySetInnerHTML={{ __html: helixData.howToHelpHtml }}
          />
          {helixData.additionalHelpHtml && (
            <div
              className="tip-box"
              dangerouslySetInnerHTML={{
                __html: helixData.additionalHelpHtml,
              }}
            />
          )}
        </div>
      ) : (
        <div className="description">{description}</div>
      )}
    </Accordion>
  );
};
