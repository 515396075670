import { LogLevel } from './logLevels';
import { serializeError } from 'serialize-error';
// eslint-disable-next-line @typescript-eslint/no-var-requires
const appConfig = require('appConfig');

const normalizeUrl = (url: string) => {
  try {
    return url[url.length - 1] === '/' ? url : `${url}/`;
  } catch {
    return url;
  }
};

class LoggingApi {
  url: string;
  headers: {};
  context: { OsType?: string; appVersion?: string } = {};

  constructor(options: {
    url: string;
    headers: {};
    context: { OsType?: string; appVersion?: string };
  }) {
    this.url = options.url;
    this.headers = options.headers;
    this.context = options.context || {};
  }

  async log(
    logLevel: LogLevel,
    logMessage: string,
    error: Record<string, any> = {},
    extraData: Record<string, any>
  ) {
    if (error && error instanceof Error) {
      error = serializeError(error);
    }

    if (typeof logMessage !== 'string') {
      throw new Error(`Cannot log message of type ${typeof logMessage}`);
    }

    const headers = {
      'Content-Type': 'application/json',
    };

    let data: {} = {
      currentUrlPath: window.location.pathname,
      ...extraData,
    };

    if (error) {
      data = { ...data, error };
    }

    const body = JSON.stringify({
      message: logMessage,
      context: {
        appName: appConfig.appName,
        currentUrl: window.location.href,
        ...this.context,
      },
      parameters: {
        ...data,
      },
    });

    const url = normalizeUrl(this.url);
    try {
      const response = await fetch(url + logLevel, {
        method: 'POST',
        headers: headers,
        body: body,
      });

      if (!response.ok) {
        console.warn('LoggingApi Response indicates failure', response.status);
      }
    } catch (err) {
      console.warn('Error sending log to LoggingApi', err.message);
    }
  }

  updateContext(update: any) {
    this.context = { ...this.context, ...update };
  }
}

// change app id
const logger = new LoggingApi({
  url: `${appConfig.loggingApiUrl}${appConfig.appName}/`,
  headers: {},
  context: {},
});

export const updateLoggingApiContext = logger.updateContext.bind(logger);

export const logToLoggingApi = (
  level: LogLevel,
  message: any,
  error: any,
  data?: any
) => logger.log(level, message, error, data);
