import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BaseButton } from '../../index';
import { UserAnalyticEvent } from '../../../utils/index';
import { Accordion } from '../../../atomic-ui';
import { ThumbsUpNoFill } from '../../../icons/ThumbsUpNoFill';
import { ThumbsDownNoFill } from '../../../icons/ThumbsDownNoFill';
import { CircleQuestionMarkNoFill } from '../../../icons/CircleQuestionMarkNoFill';

interface WifiWizardSolutionProps {
  title: string;
  description: string;
  helixArticle?: string;
  isShowing?: boolean;
  onFeedbackSubmitted?: (rating: FeedbackRating) => void;
}

export type FeedbackRating = 'yes' | 'no' | 'maybe';

export const WifiWizardSolution = ({
  title,
  description,
  helixArticle,
  isShowing = true,
  onFeedbackSubmitted = () => null,
}: WifiWizardSolutionProps) => {
  const [answeredThumbs, setAnsweredThumbs] = useState(false);
  const { t: translateText } = useTranslation();

  const outerDivStyle = {
    display: isShowing ? 'block' : 'none',
  };

  const thumbsClickedCommon = (rating: FeedbackRating) => {
    onFeedbackSubmitted(rating);
    setAnsweredThumbs(true);
  };

  const thumbClickedMaybe = () => {
    thumbsClickedCommon('maybe');
  };

  const thumbClickedNo = () => {
    thumbsClickedCommon('no');
  };

  const thumbClickedYes = () => {
    thumbsClickedCommon('yes');
  };

  const FeedbackThumbs = () => {
    if (answeredThumbs) {
      return (
        <div className="feedback-thumbs">
          {translateText('thanks_for_thumbs')}
        </div>
      );
    }
    return (
      <div className="feedback-thumbs">
        <BaseButton
          variant="primary-ghost"
          analyticName={UserAnalyticEvent.ResolutionWorked}
          onClick={thumbClickedYes}
          className="recommendation-button"
        >
          {translateText('yes')}&nbsp;
          <ThumbsUpNoFill />
        </BaseButton>
        <BaseButton
          variant="primary-ghost"
          analyticName={UserAnalyticEvent.ResolutionFailed}
          onClick={thumbClickedNo}
          className="recommendation-button"
        >
          {translateText('no')}&nbsp;
          <ThumbsDownNoFill />
        </BaseButton>
        <BaseButton
          variant="primary-ghost"
          analyticName={UserAnalyticEvent.ResolutionMaybe}
          onClick={thumbClickedMaybe}
          className="recommendation-button"
        >
          {translateText('maybe')}&nbsp;
          <CircleQuestionMarkNoFill />
        </BaseButton>
      </div>
    );
  };

  return (
    <div className="wifi-wizard-solution" style={outerDivStyle}>
      <Accordion
        className="wifi-wizard-accordion"
        label={title}
        initiallyExpanded={true}
      >
        <p>{description}</p>
        {helixArticle && (
          <a href={helixArticle} rel="noopener noreferrer" target="_blank">
            Read more
          </a>
        )}
      </Accordion>
      {
        // TODO: css from class above is not overriding defaults on <Heading> and <Paragraph>
        // <Heading size={3}>{title}</Heading>
        // <Paragraph>
        //   {description}
        // </Paragraph>
      }
      <div className="wifi-wizard-will-use-solution">
        <span>Did this solution work?</span>
        <FeedbackThumbs />
      </div>
    </div>
  );
};
