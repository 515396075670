import React from 'react';
import { useTranslation } from 'react-i18next';
import { map } from 'rxjs/operators';
import { ScanComponent, withLoadingSkeleton } from '..';
import { useRxApi } from '../../../hooks';
import { LoadingSpinner } from '../../../icons';
import { homeGraphApi } from '../../../page-api';
import { H1 } from '../../CommonComponents';
import { BandwidthUsageWrapper } from './BandwidthUsage';
import { InternetServiceProvider } from './InternetServiceProvider';
import { UploadDownloadSpeeds } from './UploadDownloadSpeeds';

interface BroadbandInformationWrapperProps {
  isLoading?: boolean;
}

export const BroadbandInformationWrapper = ({
  isLoading,
}: BroadbandInformationWrapperProps) => {
  const { result: broadbandParams } = useRxApi(
    homeGraphApi.broadbandParameters$,
    {
      initialValue: {},
    }
  );
  const showBandwidthUsage =
    !!broadbandParams.provisionedDown && !!broadbandParams.broadbandMaxDown;

  const { result: internetHealth } = useRxApi(homeGraphApi.internetHealth$, {
    initialValue: {},
  });

  const { downloadThroughput, uploadThroughput } = internetHealth;

  const { result: ispName } = useRxApi(homeGraphApi.ispData$, {
    initialValue: null,
  });

  const isFirstLoadFinished$ = homeGraphApi.internetHealth$.pipe(
    map((result) => {
      const { downloadThroughput, uploadThroughput } = result;
      return !!downloadThroughput && !!uploadThroughput;
    })
  );

  return (
    <ScanComponent>
      <BroadbandInformationHeading isLoading={isLoading} />
      {withLoadingSkeleton(
        <BroadbandInformation
          showBandwidthUsage={showBandwidthUsage}
          downloadThroughput={downloadThroughput}
          uploadThroughput={uploadThroughput}
          ispName={ispName}
        />,
        isFirstLoadFinished$
      )}
    </ScanComponent>
  );
};

interface BroadbandInformationHeadingProps {
  isLoading?: boolean;
}

export const BroadbandInformationHeading = ({
  isLoading,
}: BroadbandInformationHeadingProps) => {
  const { t } = useTranslation();

  const headingStyle = {
    fontSize: '1.25rem',
    marginBottom: '1rem',
    marginTop: '0',
  };
  return (
    <H1 className="scan-subheading">
      {t('speed_test_results')} {isLoading && <LoadingSpinner />}
    </H1>
  );
};

interface BroadbandInformationProps {
  showBandwidthUsage?: boolean;
  downloadThroughput: string | null | undefined;
  uploadThroughput: string | null | undefined;
  ispName: string | null | undefined;
}

export const BroadbandInformation = ({
  showBandwidthUsage,
  downloadThroughput,
  uploadThroughput,
  ispName,
}: BroadbandInformationProps) => {
  const ispStyle = {
    marginTop: '2rem',
  };

  // isp is not guaranteed, so first load is finished when upload & download
  // throughput are not null

  return (
    <div>
      <UploadDownloadSpeeds
        downloadThroughput={downloadThroughput}
        uploadThroughput={uploadThroughput}
      />
      <InternetServiceProvider ispName={ispName} style={ispStyle} />
      {showBandwidthUsage && (
        <BandwidthUsageWrapper className="bandwidth-usage-bar" />
      )}
    </div>
  );
};
