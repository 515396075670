import React, { useState } from 'react';

import { QuestionAnswer } from './QuestionAnswer';
import { Paragraph } from '@npmsoluto/atomic-ui-library-react';
import { IWifiWizardQuestion } from '../../../services';

interface QuestionProps {
  question: IWifiWizardQuestion;
  initiallySelectedAnswers?: string[];
  onSubmit?: (id: IWifiWizardQuestion, answers: string[]) => void;
}

export const Question = ({ question, onSubmit = () => {} }: QuestionProps) => {
  const [answerIds, setAnswerIds] = useState<string[]>(
    question.chosenAnswerIds ?? []
  );
  const { answers } = question;
  const onAnswerClick = (id: string) => {
    // !NOTE:  A missing or empty question type will default to multi-select
    if (question.questionType === 'singleSelect') {
      onSubmit(question, [id]);
      return setAnswerIds([id]);
    }

    const answerIndex = answerIds.indexOf(id);

    // If they've already selected the answerId, unselect it
    if (answerIndex > -1) {
      const newAnswers = answerIds
        .slice(0, answerIndex)
        .concat(answerIds.slice(answerIndex + 1));
      onSubmit(question, newAnswers);
      return setAnswerIds(newAnswers);
    } else {
      const newAnswers = answerIds.concat(id);
      onSubmit(question, newAnswers);
      return setAnswerIds(newAnswers);
    }
  };

  const answerIsSelected = (id: string): boolean => {
    return answerIds.includes(id);
  };

  const answerType =
    question.questionType === 'singleSelect' ? 'radio' : 'checkbox';

  const questionTextStyle = {
    margin: '0 0 1rem 1rem',
  };

  //TODO: Get the boostrap classes out of here
  return (
    <div className="border">
      <div style={questionTextStyle}>
        <Paragraph>{question.question}</Paragraph>
      </div>
      {answers.map((ans) => {
        return (
          <QuestionAnswer
            key={ans.id}
            selected={answerIsSelected(ans.id)}
            displayText={ans.text}
            type={answerType}
            onSelect={() => {
              onAnswerClick(ans.id);
            }}
          />
        );
      })}
    </div>
  );
};
