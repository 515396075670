import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SessionWrapUp } from '../../Default/SessionWrapUp';
import { WiredQuestionSet } from '../../Default/TroubleshootingQuestions';
import { ExpandableComponent } from '../ExpandableComponent';
import { WifiWizardRecommendationWrapper } from '../WifiWizardRecommendation';
import { useEffectOnce } from '../../../hooks';
import { wifiWizardApi } from '../../../page-api';
import {
  analytics,
  DashboardEventType,
  UserAnalyticEvent,
} from '../../../utils';
import { ScanInstructionsComponent } from '../ScanningPageComponent';
import { Chevron } from '../../../icons/Chevron';
import { ResultsPageState } from '../../../pages';

interface ResultsPageQuestionSectionProps {
  resultsPageState: ResultsPageState;
  setResultsPageState: (resultsPageState: ResultsPageState) => void;
}

export const ResultsPageQuestionSection = ({
  resultsPageState,
  setResultsPageState,
}: ResultsPageQuestionSectionProps) => {
  const { t: translateText } = useTranslation();

  useEffectOnce(() => {
    wifiWizardApi.requestQuestions({ userType: 'expert' });
  });

  const [recommendationExpanded, setRecommendationExpanded] = useState(false);
  const [wrapUpExpanded, setWrapUpExpanded] = useState(false);
  const [scanInstructionsExpanded, setScanInstructionsExpanded] = useState(
    true
  );
  const [wiredQuestionSetExpanded, setWiredQuestionSetExpanded] = useState(
    false
  );

  const recommendationHeading = <div>Results</div>;

  const wrapUpHeading = <div>{translateText('feedback')}</div>;

  const scanInstructionsHeading = (
    <div
      className="pre-scan-component-heading"
      onClick={() => setScanInstructionsExpanded(!scanInstructionsExpanded)}
    >
      <div>Network Scan</div>
      <div className={scanInstructionsExpanded ? 'active' : ''}>
        <Chevron />
      </div>
    </div>
  );

  const arrowTransform = wiredQuestionSetExpanded
    ? 'rotate(180deg)'
    : 'rotate(0deg)';

  const collapseArrowStyles = {
    marginLeft: 'auto',
    transition: 'transform 0.2s',
    transform: arrowTransform,
  } as const;

  const toggleWiredQuestionSetExpansion = () =>
    setWiredQuestionSetExpanded(!wiredQuestionSetExpanded);

  const wiredQuestionSetHeading = (
    <div
      onClick={toggleWiredQuestionSetExpansion}
      className="troubleshooting-header"
    >
      <span
        className="troubleshooting-heading"
        onClick={toggleWiredQuestionSetExpansion}
      >
        Customer Questions
      </span>
      <span
        style={collapseArrowStyles}
        onClick={toggleWiredQuestionSetExpansion}
      >
        <Chevron />
      </span>
    </div>
  );

  useEffect(() => {
    if (
      (resultsPageState === ResultsPageState.Scanning ||
        resultsPageState === ResultsPageState.ScanResults) &&
      scanInstructionsExpanded
    ) {
      setScanInstructionsExpanded(false);
    }

    // TODO: do we always go through Scanning to get to ScanResults?
    // Is there a situation where we need to expand the questions when we get to
    // ScanResults?
    if (resultsPageState === ResultsPageState.Scanning) {
      setWiredQuestionSetExpanded(true);
    }
  }, [resultsPageState, scanInstructionsExpanded]);

  const onQuestionsFinished = (
    questionsCount: number,
    answeredQuestionsCount: number
  ) => {
    analytics.dispatch(
      UserAnalyticEvent.QuestionsFinished,
      DashboardEventType.Business,
      {
        questionsAnswered: answeredQuestionsCount,
        totalQuestions: questionsCount,
      }
    );
    if (!recommendationExpanded) {
      setRecommendationExpanded(true);
    }
  };

  const onRecommendationFinished = () => {
    setWrapUpExpanded(true);
  };

  return (
    <>
      <ol className="connected-list">
        <li>
          <ExpandableComponent
            heading={scanInstructionsHeading}
            isExpanded={scanInstructionsExpanded}
          >
            <ScanInstructionsComponent
              setResultsPageState={setResultsPageState}
            />
          </ExpandableComponent>
        </li>
        <li>
          <ExpandableComponent
            heading={wiredQuestionSetHeading}
            isExpanded={wiredQuestionSetExpanded}
          >
            <WiredQuestionSet
              onQuestionsFinished={onQuestionsFinished}
              setIsExpanded={setWiredQuestionSetExpanded}
            />
          </ExpandableComponent>
        </li>
        <li>
          <ExpandableComponent
            heading={recommendationHeading}
            isExpanded={recommendationExpanded}
          >
            <WifiWizardRecommendationWrapper
              numSolutionsDisplayed={3}
              onFeedbackSubmitted={onRecommendationFinished}
              isExpanded={recommendationExpanded}
            />
          </ExpandableComponent>
        </li>
        <li>
          <ExpandableComponent
            heading={wrapUpHeading}
            isExpanded={wrapUpExpanded}
          >
            <SessionWrapUp />
          </ExpandableComponent>
        </li>
      </ol>
    </>
  );
};
