import React, { SVGProps } from 'react';
import { useTranslation } from 'react-i18next';
import {
  GoodBrowsingIcon,
  GoodGamingIcon,
  GoodStreamingIcon,
  GoodUltraHdIcon,
  GoodVideoChatIcon,
  PoorBrowsingIcon,
  PoorGamingIcon,
  PoorStreamingIcon,
  PoorUltraHdIcon,
  PoorVideoChatIcon,
} from './streaming-icons';
import { StreamingCapabilityType } from './StreamingQualityV2';

type IconDictionary = {
  [K in StreamingCapabilityType]: {
    goodIcon: (props: SVGProps<SVGSVGElement>) => JSX.Element;
    poorIcon: (props: SVGProps<SVGSVGElement>) => JSX.Element;
    props: React.HTMLAttributes<HTMLDivElement>;
  };
};

const iconDict: IconDictionary = {
  browsing: {
    goodIcon: GoodBrowsingIcon,
    poorIcon: PoorBrowsingIcon,
    props: {
      style: { width: '61px' },
    },
  },
  gaming: {
    goodIcon: GoodGamingIcon,
    poorIcon: PoorGamingIcon,
    props: {
      style: { width: '61px' },
    },
  },
  streaming: {
    goodIcon: GoodStreamingIcon,
    poorIcon: PoorStreamingIcon,
    props: {
      style: { width: '67px' },
    },
  },
  video_chat: {
    goodIcon: GoodVideoChatIcon,
    poorIcon: PoorVideoChatIcon,
    props: {
      style: { width: '75px' },
    },
  },
  ultra_hd: {
    goodIcon: GoodUltraHdIcon,
    poorIcon: PoorUltraHdIcon,
    props: {
      style: { width: '61px' },
    },
  },
};

interface StreamingQualityIconWrapperProps {
  streamingType: StreamingCapabilityType;
  isGood: boolean;
}

export const StreamingQualityIconWrapper = ({
  streamingType,
  isGood,
}: StreamingQualityIconWrapperProps) => {
  const { t } = useTranslation();

  const Icon = isGood
    ? iconDict[streamingType].goodIcon
    : iconDict[streamingType].poorIcon;

  return (
    <div className="text-center" {...iconDict[streamingType].props}>
      <div className="quality-image-wrapper">
        <Icon className="device-icons" />
      </div>
      <p className="text">{t(streamingType.toString())}</p>
    </div>
  );
};
