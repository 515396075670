import React from 'react';

export const ThumbsDownNoFill = () => (
  <svg
    width="21"
    height="18"
    viewBox="0 0 21 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20 0.000999451H16C15.447 0.000999451 15 0.448999 15 1.001V1.523L11.371 0.0719995C11.254 0.0249996 11.127 0.000999451 11 0.000999451H3.00003C2.51703 0.000999451 2.10303 0.347 2.01603 0.822L0.016027 11.822C-0.036973 12.114 0.043027 12.414 0.233027 12.641C0.423027 12.868 0.703027 13.001 1.00003 13.001H7.00003V17.001C7.00003 17.553 7.44703 18.001 8.00003 18.001H11C11.4 18.001 11.762 17.763 11.919 17.395L14.659 11.001H15C15 11.553 15.447 12.001 16 12.001H20C20.553 12.001 21 11.553 21 11.001V1.001C21 0.448999 20.553 0.000999451 20 0.000999451ZM14 9.001C13.6 9.001 13.238 9.239 13.081 9.606L10.341 16.001H9.00003V12.001C9.00003 11.449 8.55303 11.001 8.00003 11.001H2.19803L3.83403 2.001H10.808L15 3.679V9.001H14ZM19 10.001H17V2.001H19V10.001Z"
      fill="#8223D2"
    />
  </svg>
);
