import React from 'react';

export const GoogleLogo = () => (
  <svg
    width="64"
    height="21"
    className="device-icons"
    viewBox="0 0 64 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.88215 0H8.45128C10.4271 0.0429528 12.3707 0.83758 13.7667 2.24428C13.2513 2.77046 12.7251 3.27515 12.2204 3.80132C11.4365 3.0926 10.4915 2.54495 9.46067 2.35167C7.93584 2.02952 6.2929 2.31945 5.01505 3.18925C3.61909 4.10199 2.67412 5.64829 2.4701 7.30198C2.2446 8.93418 2.70634 10.6523 3.76942 11.9194C4.78955 13.1543 6.34659 13.9274 7.95732 13.9811C9.46067 14.067 11.0284 13.6053 12.1237 12.553C12.9828 11.812 13.3801 10.6845 13.509 9.58921C11.7264 9.58921 9.94389 9.59995 8.16135 9.58921V7.37714H15.6673C16.0539 9.75029 15.4955 12.4241 13.6915 14.1207C12.4888 15.3234 10.8244 16.0321 9.12778 16.1717C7.48484 16.3328 5.7882 16.0214 4.32781 15.2268C2.57748 14.2925 1.18151 12.714 0.49427 10.8563C-0.150022 9.14895 -0.160761 7.21607 0.440579 5.49796C0.988227 3.93018 2.04057 2.54495 3.39358 1.57852C4.69291 0.622816 6.27142 0.0966438 7.88215 0Z"
      fill="black"
    />
    <path
      d="M50.9423 0.558411H53.2403V15.8926C52.4779 15.8926 51.7047 15.9033 50.9423 15.8818C50.9531 10.7812 50.9423 5.66979 50.9423 0.558411V0.558411Z"
      fill="black"
    />
    <path
      d="M20.7787 5.87382C22.1962 5.60537 23.7317 5.90604 24.9022 6.75435C25.9653 7.50603 26.7062 8.68723 26.9317 9.97581C27.2217 11.4684 26.8566 13.1006 25.8901 14.2818C24.8485 15.6026 23.1411 16.3114 21.4767 16.204C19.9519 16.1181 18.47 15.3557 17.568 14.11C16.5479 12.7355 16.3009 10.8456 16.8593 9.23488C17.4177 7.50603 18.9962 6.18523 20.7787 5.87382V5.87382ZM21.1009 7.91408C20.521 8.06441 19.9841 8.3973 19.5975 8.86978C18.5559 10.1154 18.6204 12.1449 19.7693 13.3047C20.4244 13.9704 21.4123 14.2818 22.325 14.0993C23.1734 13.9489 23.9143 13.3798 24.3223 12.6281C25.0311 11.3503 24.827 9.58924 23.7425 8.59059C23.0445 7.94629 22.0244 7.67784 21.1009 7.91408Z"
      fill="black"
    />
    <path
      d="M32.1612 5.87382C33.7827 5.56241 35.5545 6.01341 36.7679 7.15166C38.7437 8.92347 38.9585 12.2416 37.2726 14.2818C36.2525 15.5704 34.5988 16.2791 32.9666 16.204C31.4096 16.161 29.874 15.3879 28.9505 14.11C27.9089 12.7033 27.6834 10.7704 28.274 9.12749C28.8646 7.45233 30.4109 6.17449 32.1612 5.87382V5.87382ZM32.4834 7.91408C31.9035 8.06441 31.3666 8.39729 30.98 8.85904C29.9492 10.0832 29.9921 12.0805 31.0982 13.251C31.7532 13.9489 32.7733 14.2926 33.7183 14.0993C34.5559 13.9382 35.3075 13.3798 35.7156 12.6281C36.4136 11.3396 36.2095 9.5785 35.1142 8.57984C34.4163 7.93555 33.3961 7.67783 32.4834 7.91408Z"
      fill="black"
    />
    <path
      d="M41.8149 6.4966C43.0498 5.72345 44.6927 5.50869 46.0243 6.17445C46.443 6.357 46.7867 6.66841 47.1195 6.97982C47.1303 6.68989 47.1195 6.38922 47.1303 6.08855C47.8497 6.09929 48.5692 6.08855 49.2994 6.09929V15.5704C49.2887 16.9986 48.9236 18.5127 47.8927 19.5543C46.7652 20.7032 45.0363 21.0576 43.4793 20.8214C41.8149 20.5744 40.3652 19.361 39.7209 17.8254C40.3652 17.514 41.0417 17.267 41.7075 16.9771C42.0833 17.8576 42.8457 18.6093 43.8014 18.7811C44.7571 18.9529 45.8632 18.7167 46.486 17.922C47.1518 17.1059 47.1518 15.9892 47.1195 14.9905C46.6256 15.4737 46.0565 15.9033 45.3692 16.0643C43.8766 16.4831 42.2337 15.9677 41.0847 14.9583C39.925 13.9489 39.2377 12.3919 39.3021 10.8456C39.3344 9.09525 40.3223 7.40935 41.8149 6.4966V6.4966ZM44.0377 7.87109C43.3827 7.97847 42.7706 8.34357 42.3518 8.84827C41.3424 10.0509 41.3424 11.9731 42.3625 13.1543C42.9424 13.8523 43.8766 14.2388 44.7786 14.1422C45.6269 14.0563 46.4108 13.5194 46.8296 12.7784C47.5383 11.5221 47.4202 9.81471 46.4645 8.71941C45.8739 8.0429 44.929 7.71002 44.0377 7.87109V7.87109Z"
      fill="black"
    />
    <path
      d="M56 7.04424C57.2886 5.84156 59.2752 5.43351 60.9289 6.06706C62.4966 6.65766 63.4953 8.15027 64 9.68584C61.6698 10.6523 59.3504 11.608 57.0202 12.5744C57.3423 13.1865 57.8363 13.7449 58.502 13.9704C59.4363 14.3033 60.553 14.1851 61.3369 13.5623C61.6483 13.3261 61.8953 13.0147 62.1315 12.714C62.7221 13.1113 63.3127 13.4979 63.9033 13.8952C63.0658 15.1516 61.6591 16.0321 60.145 16.1717C58.4698 16.3757 56.6873 15.7314 55.6027 14.4214C53.8202 12.3596 53.992 8.90195 56 7.04424V7.04424ZM57.149 9.03081C56.7839 9.55698 56.6336 10.2013 56.6443 10.8348C58.2014 10.1905 59.7584 9.54624 61.3154 8.89121C61.0577 8.28987 60.4349 7.92477 59.8014 7.82813C58.7812 7.64558 57.7181 8.19323 57.149 9.03081V9.03081Z"
      fill="black"
    />
  </svg>
);
