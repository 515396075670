import { logger } from '../';
import { AnalyticsInterface } from './analytic.interface';
import { DashboardEventType, UserAnalyticEvent } from './analytics-constants';
import { BaseAnalyticWriter } from './baseAnalyticWriter';

const appConfig = require('appConfig');

export class AnalyticServicePayload {
  private eventType: string;
  private metaData: object;
  private consumers: string[];

  constructor(eventType: string, metaData: object, consumers: string[]) {
    this.eventType = eventType;
    this.metaData = metaData;
    this.consumers = consumers;
  }
}
class NetworkScanAnalyticWriter
  extends BaseAnalyticWriter
  implements AnalyticsInterface {
  private consumers: string[] = ['atlas', 'mixpanel'];

  public trackEvent(
    eventName: UserAnalyticEvent,
    eventType: DashboardEventType,
    properties: object
  ): void {
    properties = {
      ...properties,
      ...this.setupEventSpecificProps(eventName, eventType),
    };
    this.sendToAnalyticService(eventType, properties);
  }

  private sendToAnalyticService(eventType: string, properties: object) {
    const analyticServicePayload = new AnalyticServicePayload(
      eventType,
      properties,
      this.consumers
    );
    fetch(appConfig.analyticsApiUrl, {
      method: 'post',
      headers: {
        Accept: 'application/json, text/plain, */*',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(analyticServicePayload),
    }).catch((error) => {
      logger.error('Expert Dashboard analytic post failure', error);
    });
  }
}

const networkScanAnalyticWriter = new NetworkScanAnalyticWriter();

export { networkScanAnalyticWriter };
