import React, { SVGProps } from 'react';

export const PoorStreamingIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="36"
      height="34"
      viewBox="0 0 36 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M11.995 12.003C6.481 12.003 1.995 16.489 1.995 22.003C1.995 27.516 6.481 32.003 11.995 32.003C17.509 32.003 21.995 27.516 21.995 22.003C21.995 16.489 17.509 12.003 11.995 12.003ZM8.995 27.003V18.003L17.995 22.503L8.995 27.003Z"
        fill="black"
      />
      <path
        className="override-icon-poor"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26 20C31.5228 20 36 15.5228 36 10C36 4.47715 31.5228 0 26 0C20.4772 0 16 4.47715 16 10C16 15.5228 20.4772 20 26 20Z"
      />
      <path
        className="override-color"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.8333 4.16667H25.1667V5.83333H26.8333V4.16667ZM26.8333 7.49998H25.1667V15H26.8333V7.49998Z"
      />
    </svg>
  );
};
