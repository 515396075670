import React, { ReactNode } from 'react';

interface DropdownProps {
  displayValue: string;
  children: ReactNode;
}
export const Dropdown = ({ displayValue, children }: DropdownProps) => {
  return (
    <div className="dropdown">
      <span className="dropdown-display">
        {displayValue}
        <span className="dropdown-arrow" />
      </span>
      <div className="dropdown-content">{children}</div>
    </div>
  );
};
