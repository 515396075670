export enum UserAnalyticEvent {
  NextSolution = 'Next_solution',
  PreviousSolution = 'Previous_solution',
  EndSessionClick = 'End_session_button',
  ResolutionWorked = 'Resolution_worked',
  ResolutionFailed = 'Resolution_failed',
  ResolutionMaybe = 'Resolution_maybe',
  AsurionLoginButtonClick = 'Asurion_login_click',
  BeginPollingButton = 'Begin_polling_button',
  CloseModal = 'Close_modal',
  EnterPinBackButton = 'Enter_Pin_back_button',
  EnterPinButton = 'Enter_Pin',
  FullstorySessionCreated = 'Fullstory_Session_Created',
  HomeGraphApiError = 'Homegraph_Api_Error',
  HomeGraphApiViewResults = 'HomeGraph_Api_View_Results',
  HomeGraphDataReceived = 'HomeGraph_Api_Data_Retrieved',
  InitiateScanButton = 'Initiate_scan_button',
  InitiateScanRequestFailure = 'Initiate_scan_request_failed',
  InitiateScanRequestSuccess = 'Initiate_scan_request_success',
  MissingParams = 'Params_are_missing',
  MultipleIdsFound = 'Multiple_client_ids_found',
  NetworkDiscoveryError = 'Network_discovery_error',
  NoDataFound = 'No_data_found',
  PageChangeEvent = 'Page_Change_Event',
  PinRequestFailure = 'Request_ClientId_by_Pin_Failure',
  PinRequestSuccess = 'Request_ClientId_by_Pin_Success',
  RecommendationServiceSuccess = 'Request_Recommendations_Success',
  RecommendationServiceFailure = 'Request_Recommendations_Failure',
  ScanRunningBackButton = 'Scan_running_back_button',
  ScanUnsuccessfulRefreshButton = 'Scan_unsuccessful_refresh_button',
  ConfirmPartnerSelection = 'Confirm_partner_selection_button',
  SessionListDropdownButton = 'Session_list_dropdown_button',
  SessionListDropdownButtonItem = 'Session_list_dropdown_button_item',
  TipDropdownExpanded = 'Tip_Dropdown_Expanded',
  ToggleDeviceViewButton = 'Toggle_device_view_button',
  UnableToConnectToServer = 'ConnectionErrorReturnLater',
  UserLoginError = 'User_Login_Error',
  UserUpdated = 'User_Updated',
  CustomerQuestionSubmitted = 'Customer_Question_Submitted',
  NextCustomerQuestion = 'Next_Customer_Question',
  PrevCustomerQuestion = 'Previous_Customer_Question',

  QuestionAnswered = 'Question_Answered',
  QuestionsFinished = 'Questions_Finished',
  RecommendationFeedback = 'Recommendation_Feedback',
}

export enum DashboardEventType {
  Click = 'Home_Network_Click',
  Error = 'Home_Network_Error',
  View = 'Home_Network_View',
  Business = 'Home_Network_Business_Event',
  FullStory = 'Home_Network_FullStory_Event',
}
