import { defaultTweekRepository } from '../tweek';

export const shouldUseHomeMgmt = (): boolean => {
  // TODO: Better way to get from tweek?
  const tweekResponse = defaultTweekRepository.getCached<boolean>(
    'network_scan/expert_dashboard/scan_session/use_mgmt_api'
  );

  return tweekResponse?.value ?? false;
};
