import React, { SVGProps } from 'react';

export const BadCheckIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12.042 2C6.51901 2 2.04201 6.477 2.04201 12C2.04201 17.523 6.51901 22 12.042 22C17.565 22 22.042 17.523 22.042 12C22.042 6.477 17.564 2 12.042 2ZM16.749 15.293L15.335 16.707L12.042 13.415L8.74901 16.707L7.33501 15.293L10.627 12L7.33401 8.707L8.74801 7.294L12.041 10.587L15.334 7.294L16.748 8.707L13.456 12L16.749 15.293Z"
        fill="#DB1B1B"
      />
    </svg>
  );
};
