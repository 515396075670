import { DtrumApi } from 'dynatrace';

let dynatrace: DtrumApi;

export const DTIdentifyUser = (username: string) => {
  if (username) {
    // eslint-disable-next-line no-unused-expressions
    dynatrace?.identifyUser(username);
  }
};

export const getDynatrace = () => {
  dynatrace = window['dtrum'];
};

getDynatrace();
