import React from 'react';
import {
  DisplayString,
  PartnerKeyProperties,
  PartnerKeys,
} from '../../constants';
import {
  ContainerSteps,
  PreScanContentContainer,
  RoutePaths,
} from '../../components';
import { useEffectOnce, useRxApi } from '../../hooks';
import { themeApi } from '../../page-api';
import { ScanInstructionProps } from '../contracts';
import { map } from 'rxjs/operators';

const AttProTechSettings = require('../../img/default/att/pre-scan-2.png');
const AttProTechSettings_Dark = require('../../img/default/att/pre-scan-2-dark.png');
const AttProTechStreamingSupport = require('../../img/default/att/pre-scan-3.png');

export const AttScanInstructionsContent = ({
  navigationProps$,
  navigationButtonEnabled$,
}: ScanInstructionProps) => {
  const { result: isDarkMode } = useRxApi(
    themeApi.currentTheme$.pipe(map(() => themeApi.isDarkMode)),
    {
      initialValue: false,
    }
  );

  useEffectOnce(() => {
    navigationButtonEnabled$.next(true);
    navigationProps$.next({
      pathname: RoutePaths.Scanning,
    });
  });

  const contentSteps: ContainerSteps[] = [
    {
      contentHtml: `Confirm the <strong>${
        PartnerKeyProperties[PartnerKeys.att].appName
      }</strong> app has been downloaded.`,
    },
    {
      contentHtml: `Navigate to <strong> Settings </strong> and tap <strong>${DisplayString.AttAppSettingsButtonTitle}</strong>.`,
      imageSrc: isDarkMode ? AttProTechSettings_Dark : AttProTechSettings,
      imageAltText: `Arrow pointing to ${DisplayString.AttAppSettingsButtonTitle}`,
    },
    {
      contentHtml: `Tap ${DisplayString.AttAppConfirmScan}`,
      imageSrc: AttProTechStreamingSupport,
      imageAltText: `Arrow pointing to ${DisplayString.AttAppConfirmScan}`,
    },
  ];

  return <PreScanContentContainer steps={contentSteps} />;
};
