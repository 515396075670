import React from 'react';
import { useAuthentication } from '../AuthenticationProvider';
import { getUserDisplayName } from '../user-formatter';
import { Dropdown } from '../../components';
import { Logout } from './Logout';

interface UserProps {}

export const User = ({}: UserProps) => {
  const { user } = useAuthentication();
  const userDisplay = getUserDisplayName(user);
  if (!userDisplay) return null;
  return (
    <Dropdown displayValue={userDisplay}>
      <Logout className="item" />
    </Dropdown>
  );
};
