import React from 'react';

export const Chevron = () => {
  return (
    <svg
      className="a-chevron"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.41 8L12 13.0001L16.59 8L18 9.41L12 15.41L6 9.41L7.41 8Z"
      />
    </svg>
  );
};
