import {
  documentToHtmlString,
  Options,
} from '@contentful/rich-text-html-renderer';
import { Document } from '@contentful/rich-text-types';

/**
 * This class houses utilities, you can view the onboarding package to see what you could put into the
 * options object below. Useful for converting things that are of type INLINE.
 *
 * @export
 * @class BaseContentfulTransformer
 */
export class BaseContentfulTransformer {
  private options: Partial<Options> = {};

  constructor() {
    this.options = {
      renderNode: {},
    };
  }

  /**
   * To make a display friendly value, this will take in a Document type from
   * Contentful and will give you back a string
   *
   * @param {Document} content Must be of type Document, this will come from Contentful.
   * @returns {string} Display friendly value to use for html.
   * @memberof BaseContentfulTransformer
   */
  public getHtmlText(content: Document): string {
    return documentToHtmlString(content, this.options);
  }
}
