import { IClassifiedDevice } from '../../services';
import { IFormattedClassifiedDevice } from './contracts';

export const NO_DATA = 'N/A';
export const NO_VALUE = 'NO_VALUE';
export const noValueFormatter = (value: string | null): string => {
  if (!value) {
    return NO_DATA;
  }
  return value === NO_VALUE ? NO_DATA : value;
};

export const nameFormatter = (dev) => {
  const kind = dev.kind
    ? dev.kind.slice(0, 1).toUpperCase() + dev.kind.slice(1, dev.kind.length)
    : null;
  if (dev.name && dev.name !== 'Unknown') {
    return dev.name;
  } else if (dev.make && kind && dev.make !== 'Unknown') {
    if (
      kind.toLowerCase() === 'router' &&
      dev.model &&
      dev.model !== 'Unknown'
    ) {
      return `${dev.make} ${dev.model}`;
    } else {
      return `${dev.make} ${kind}`;
    }
  } else if (kind) {
    return `Connected ${kind}`;
  } else {
    return 'Connected Device';
  }
};

export const commonNameFormatter = (dev) => {
  const kind = dev.kind ? dev.kind : null;
  if (
    dev.make &&
    dev.model &&
    dev.make !== 'Unknown' &&
    dev.model !== 'Unknown'
  ) {
    return `${dev.make} ${dev.model}`;
  } else if (kind === 'router') {
    return '';
  } else if (dev.make && dev.make !== 'Unknown') {
    return dev.make;
  } else if (dev.ip) {
    return dev.ip;
  } else {
    return '';
  }
};

/**
 * This will take a signal strength and convert it to a number between 0 and 1
 *
 * ! rssi stands for Received Signal Strength Indicator
 * * For more reference you can view this link. https://networkengineering.stackexchange.com/a/1676
 *
 * @param {IClassifiedDevice} device
 * @returns
 */
export const getRssiValue = (device: IClassifiedDevice) => {
  if (
    !device.operatingParameters ||
    Number(device.operatingParameters.signalStrength) === 0
  ) {
    return 0;
  } else if (Number(device.operatingParameters.signalStrength) < -85) {
    return 0.01;
  } else if (Number(device.operatingParameters.signalStrength) < -80)
    return 0.17;
  else if (Number(device.operatingParameters.signalStrength) < -75) {
    return 0.33;
  } else if (Number(device.operatingParameters.signalStrength) < -70) {
    return 0.5;
  } else if (Number(device.operatingParameters.signalStrength) < -67) {
    return 0.66;
  } else if (Number(device.operatingParameters.signalStrength) < -30) {
    return 0.83;
  } else {
    return 1;
  }
};

export const classifiedDeviceFormatter = (
  device: IClassifiedDevice
): IFormattedClassifiedDevice => {
  return {
    ...device,
    nameCommon: commonNameFormatter(device),
    name: nameFormatter(device),
    rssi:
      device.operatingParameters && device.operatingParameters.signalStrength
        ? getRssiValue(device)
        : null,
  };
};
