import {
  useHistory,
  useLocation,
  useParams,
  useRouteMatch,
} from 'react-router-dom';

import queryString from 'query-string';
import { useMemo } from 'react';
import { LocationDescriptorObject } from 'history';
import { Subject } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import { analytics, DashboardEventType, UserAnalyticEvent } from '../utils';

const routerEventSubject = new Subject<LocationDescriptorObject<any>>();
routerEventSubject
  .asObservable()
  .pipe(
    distinctUntilChanged((prev, current) => prev.pathname === current.pathname)
  )
  .subscribe({
    next: (value) => {
      const currentAnalytics = analytics.currentAnalyticProperties;
      if (currentAnalytics.pageId !== value.pathname) {
        analytics.updateProperties({ pageId: value.pathname });
        value.pathname &&
          analytics.dispatch(
            UserAnalyticEvent.PageChangeEvent,
            DashboardEventType.View
          );
      }
    },
  });

/**
 * useRouter is a wrapper around methods that are exposed by `react-router-dom` to allow for a one stop shop of data grabbing
 * - `push` is a custom method you can use to handle routing.
 */
export const useRouter = <TState extends any>() => {
  const params = useParams();
  const location = useLocation<TState>();
  const history = useHistory<any>();
  const match = useRouteMatch();

  return useMemo(() => {
    const push = (
      locationDescriptorObject: LocationDescriptorObject<TState>
    ) => {
      routerEventSubject.next(locationDescriptorObject);
      history.push({
        search: location.search ?? '', // this keeps the queryParams across all the routing changes
        ...locationDescriptorObject,
      });
    };
    return {
      push,
      replace: history.replace,
      pathname: location.pathname,
      query: {
        ...queryString.parse(location.search), // Convert string to object
        ...params,
      },
      match,
      location,
      history,
      state: location.state,
    };
  }, [history, location, params, match]);
};
