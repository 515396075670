import Auth from '@aws-amplify/auth';

const appConfig = require('appConfig');

Auth.configure({
  region: appConfig.aws.region,
  userPoolId: appConfig.aws.userPoolId,
  userPoolWebClientId: appConfig.aws.userPoolWebClientId,
  oauth: {
    domain: appConfig.aws.oauth.domain,
    scope: appConfig.aws.oauth.scope,
    redirectSignIn: `${window.location.protocol}//${window.location.host}/${appConfig.aws.oauth.redirectSignInRoute}`,
    redirectSignOut: `${window.location.protocol}//${window.location.host}/${appConfig.aws.oauth.redirectSignOutRoute}`,
    responseType: appConfig.aws.oauth.responseType,
    options: {
      AdvancedSecurityDataCollectionFlag: false,
      ...appConfig.aws.oauth.options,
    },
  },
});

export default Auth;
