import React from 'react';
import { colors } from '../../../styles';
import { Tooltip } from '../../CommonComponents';
import { getIconSrcByType } from './display-icons';
import { IFormattedClassifiedDevice } from '../../../utils';

const wiredIcon = require('../../../img/default/devices-icons/wired.svg');
const wifiIcon = require('../../../img/default/devices-icons/wifi.svg');
const group2gIcon = require('../../../img/default/devices-icons/wifi-2g.svg');
const group5gIcon = require('../../../img/default/devices-icons/wifi-5g.svg');

interface DeviceBlockProps {
  device: IFormattedClassifiedDevice;
  routerType?: string;
  band?: string | number;
  ssid?: string;
  uid?: string;
  columnNumber?: number;
  columnData?: boolean;
}

export const DeviceBlock = ({
  device,
  routerType,
  band,
  ssid,
}: DeviceBlockProps) => {
  const { rssi } = device;
  let iconSrc = getIconSrcByType(device.kind);
  let iconSize = device.kind === 'router' ? 23 : 22;
  const nameToolTipLength = device.name.toUpperCase() === device.name ? 12 : 16;
  const nameCommonToolTipLength =
    device.nameCommon.toUpperCase() === device.nameCommon ? 15 : 18;
  if (routerType && routerType !== 'wired' && device.kind === 'router') {
    if (Number(band) === 5) {
      iconSize = 26;
      iconSrc = group5gIcon;
    } else if (Number(band) === 2.4) {
      iconSize = 26;
      iconSrc = group2gIcon;
    } else {
      iconSize = 24;
      iconSrc = wifiIcon;
    }
  }
  return (
    <div className="category-block row">
      <div className="items-group">
        <div className="inner-box row">
          <div
            className="left-section"
            style={{ marginTop: band && band != 'null' ? 10 : '' }}
          >
            <img
              src={iconSrc}
              className="category-icons"
              width={iconSize * 1.7}
              height={iconSize * 1.7}
            />
          </div>
          <div
            className={`right-section ${
              device.kind === 'router' ? 'router' : 'connected-devices'
            }`}
          >
            <div className="name">
              <Tooltip
                message={device.name}
                position="top"
                showTooltip={device.name.length > nameToolTipLength}
              >
                {ssid
                  ? ssid
                  : device.name.length > nameToolTipLength
                  ? `${device.name.slice(0, nameToolTipLength)}...`
                  : device.name}
              </Tooltip>
            </div>
            <div className="name-common">
              <Tooltip
                message={device.nameCommon}
                position="top"
                showTooltip={device.nameCommon.length > nameCommonToolTipLength}
              >
                {device.nameCommon.length > nameCommonToolTipLength
                  ? `${device.nameCommon.slice(0, nameCommonToolTipLength)}...`
                  : device.nameCommon}
              </Tooltip>
            </div>
            <div className="icons-box">
              <div className="wired-icon">
                {device.operatingParameters &&
                  device.operatingParameters.wired && (
                    <img
                      src={wiredIcon}
                      className="category-icons"
                      width={iconSize * 1.2}
                      height={iconSize * 1.2}
                    />
                  )}
              </div>
              {rssi ? (
                <div className="rssi-wrapper row">
                  <div
                    className="rssi-signal"
                    style={{
                      height: 9.5,
                      backgroundColor:
                        rssi < 0.3
                          ? colors.red
                          : rssi < 0.67
                          ? colors.yellow
                          : rssi < 0.8
                          ? colors.lime
                          : colors.green,
                    }}
                  >
                    {' '}
                  </div>
                  <div
                    className="rssi-signal"
                    style={{
                      height: 12.3,
                      backgroundColor:
                        rssi < 0.3
                          ? colors.babyBlue
                          : rssi < 0.67
                          ? colors.yellow
                          : rssi < 0.8
                          ? colors.lime
                          : colors.green,
                    }}
                  >
                    {' '}
                  </div>
                  <div
                    className="rssi-signal"
                    style={{
                      height: 15.2,
                      backgroundColor:
                        rssi < 0.67
                          ? colors.babyBlue
                          : rssi < 0.8
                          ? colors.lime
                          : colors.green,
                    }}
                  >
                    {' '}
                  </div>
                  <div
                    className="rssi-signal"
                    style={{
                      height: 18,
                      backgroundColor:
                        rssi < 0.8 ? colors.babyBlue : colors.green,
                    }}
                  >
                    {' '}
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
