import React from 'react';
import { BehaviorSubject } from 'rxjs';
import {
  delay,
  first,
  repeat,
  skipWhile,
  switchMap,
  tap,
} from 'rxjs/operators';
import {
  BroadbandInformationWrapper,
  NetworkDevicesWrapper,
  NetworkDiagnosisWrapper,
} from '..';
import { PartnerKeyProperties } from '../../../constants';
import {
  StoredQueryParams,
  useEffectOnce,
  useRxApi,
  useSessionStorage,
} from '../../../hooks';
import { listSessionApi, sendHomeGraphBusinessEvent } from '../../../page-api';
import {
  contentCacheApi,
  IHomeGraphResponse,
  networkScanApi,
} from '../../../services';
import { ISessionInterface } from '../../../services/contracts/client-session-response.interface';
import { getAuthToken } from '../../../sso';
import { SubheaderV2 } from '../Subheader';
import { StreamingQualityV2 } from '../NetworkQuality/StreamingQualityV2';
import { InternetServicesV2 } from '../NetworkQuality/InternetServicesV2';
import { ResultsPageState } from '../../../pages/index';

const TIME_TO_WAIT_BETWEEN_CALLS = 10000;
const NUMBER_OF_CALLS_TO_MAKE = 14;

const isPollingHomeGraph$ = new BehaviorSubject<boolean>(true);

interface ResultsPageScanSectionProps {
  setResultsPageState: (resultsPageState: ResultsPageState) => void;
}

export const ResultsPageScanSection = ({
  setResultsPageState,
}: ResultsPageScanSectionProps) => {
  const [params] = useSessionStorage<StoredQueryParams>('queryParams');
  const { partner } = params;

  const { result: isPollingHomeGraph } = useRxApi(isPollingHomeGraph$, {
    initialValue: true,
  });

  useEffectOnce(() => {
    // Contentful calls take milliseconds so calling these will have no impact on the page.
    contentCacheApi.loadContentfulDataForDashboardResults(
      PartnerKeyProperties[partner]
    );
  });
  const clientId = params && params.clientId;
  useRxApi(
    listSessionApi.currentOpenSession$.pipe(
      skipWhile((openSession) => openSession === null),
      switchMap((openSession: ISessionInterface) =>
        networkScanApi
          .getHomeGraphData({
            clientId: clientId!,
            authToken: getAuthToken(),
            sessionId: openSession.session_id,
            carrier: partner,
            databaseTable: openSession.table,
          })
          .pipe(
            tap((homeGraphResponse) => {
              isPollingHomeGraph$.next(true);
              sendHomeGraphBusinessEvent(
                openSession.session_id,
                homeGraphResponse
              );
            }),
            delay(TIME_TO_WAIT_BETWEEN_CALLS),
            repeat(NUMBER_OF_CALLS_TO_MAKE),
            tap(() => {
              isPollingHomeGraph$.next(false);
            })
          )
      )
    ),
    {
      initialValue: {} as IHomeGraphResponse,
    }
  );
  return (
    <>
      <SubheaderV2 setResultsPageState={setResultsPageState} />
      <BroadbandInformationWrapper isLoading={isPollingHomeGraph} />
      <NetworkDevicesWrapper isLoading={isPollingHomeGraph} />
      <StreamingQualityV2 isLoading={isPollingHomeGraph} />
      <InternetServicesV2 isLoading={isPollingHomeGraph} />
      <NetworkDiagnosisWrapper isLoading={isPollingHomeGraph} />
    </>
  );
};
