import { LogLevel } from './logLevels';
import { logHandler } from './logHandler';

let defaultContext: object = {};

const printLoggerErrorInfo = (message: string, data: any, err: Error) =>
  console.log(
    `Error occurred while trying to log message [${message}] with data [${data}].\nError [${err.message}]`
  );

const doLog = (level: LogLevel, message: string, error: any, data?: any) =>
  (logHandler(level, message, error, {
    ...defaultContext,
    ...data,
  }) as any).catch((err: Error) => printLoggerErrorInfo(message, error, err));

type Write = (message: string, data?: object) => void;
type WriteError = (message: string, error: Error, data?: any) => void;

interface Logger {
  error: WriteError;
  warn: Write;
  debug: Write;
  info: Write;
  updateContext: (updater: (context: object) => object) => void;
}

export const logger: Logger = {
  /** log an error message
   * @memberof logging.logger
   * @param {string} message the message to log
   * @param {object} data the metadata to add to the message
   */
  error(logMessage: string, error: Error, data: any) {
    doLog('error', logMessage, error, data);
  },
  /** log a warning message
   * @memberof logging.logger
   * @param {string} message the message to log
   * @param {object} data the metadata to add to the message
   */
  warn(message: string, data: any) {
    doLog('warn', message, null, data);
  },
  /** log an info message
   * @memberof logging.logger
   * @param {string} message the message to log
   * @param {object} data the metadata to add to the message
   */
  info(message: string, data: any) {
    doLog('info', message, null, data);
  },
  /** log a debug message
   * @memberof logging.logger
   * @param {string} message the message to log
   * @param {object} data the metadata to add to the message
   */
  debug(message: string, data: any) {
    doLog('debug', message, null, data);
  },
  updateContext(updater: (context: object) => object) {
    defaultContext = updater(defaultContext);
  },
};

export const init = (context: object) => {
  defaultContext = context;
};
