import React, { SVGProps } from 'react';

export const Computer = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="34px"
      height="24px"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 34 24"
      {...props}
    >
      <path
        fill="#9100DA"
        fillRule="evenodd"
        d="M30.73 0H3.27C1.437 0 0 1.427 0 3.243v14.92c0 1.815 1.438 3.242 3.27 3.242h27.46c1.832 0 3.27-1.427 3.27-3.243V3.243C34 1.427 32.562 0 30.73 0zm1.962 18.162c0 1.103-.85 1.946-1.961 1.946H3.269c-1.111 0-1.961-.843-1.961-1.946V3.243c0-1.102.85-1.946 1.961-1.946h27.462c1.111 0 1.961.844 1.961 1.946v14.92zm.654 4.54H.654c-.392 0-.654.26-.654.65 0 .389.262.648.654.648h32.692c.392 0 .654-.26.654-.649s-.262-.648-.654-.648zM3.4 8.498a.596.596 0 0 0 .458-.194l4.38-4.346a.624.624 0 0 0 0-.908.636.636 0 0 0-.915 0l-4.38 4.346a.624.624 0 0 0 0 .908c.13.13.26.194.457.194zm7.715-5.319l-8.107 7.979a.624.624 0 0 0 0 .908.71.71 0 0 0 .457.194.71.71 0 0 0 .458-.194l8.108-7.979a.624.624 0 0 0 0-.908.636.636 0 0 0-.916 0z"
      />
    </svg>
  );
};
