import { getBrowserInfo } from './BrowserInfoHelper';
import { getEnvironment } from '../utils';
import { DashboardEventType, UserAnalyticEvent } from './analytics-constants';
import { AnalyticsInterface } from './analytic.interface';

export class Analytics {
  private writers: AnalyticsInterface[];
  private analyticProperties: Record<string, any>;
  private analyticCache: ((props) => void)[] = [];
  private canSendAnalytics = false;

  constructor(...writers: AnalyticsInterface[]) {
    this.writers = writers;
    this.analyticProperties = getBrowserInfo();
    this.updateProperties({ env: getEnvironment() });
  }

  get currentAnalyticProperties(): Record<string, any> {
    return this.analyticProperties;
  }

  public updateProperties = (newProps: Record<string, any>) => {
    this.analyticProperties = { ...this.analyticProperties, ...newProps };
    const { partner, expertId, userId, sessionId } = this.analyticProperties;

    this.canSendAnalytics = partner && expertId && userId && sessionId;
    if (
      partner &&
      expertId &&
      userId &&
      sessionId &&
      this.analyticCache.length
    ) {
      this.analyticCache.forEach((cacheFunction) => {
        cacheFunction(this.analyticProperties);
      });
      this.analyticCache = [];
    }
  };

  public dispatch = (
    eventName: UserAnalyticEvent,
    eventType: DashboardEventType,
    customProperties?: Record<string, any>
  ) => {
    this.writers.forEach((writer) => {
      const joinedProps = { ...this.analyticProperties, ...customProperties };
      if (!this.canSendAnalytics) {
        this.analyticCache.push((props) =>
          writer.trackEvent(eventName, eventType, props)
        );
        return;
      }
      writer.trackEvent(eventName, eventType, joinedProps);
    });
  };

  public initWriters(id: string, properties?: object): void {
    this.writers.forEach((writer) => writer.setup?.(id, properties));
  }
}
