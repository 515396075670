import { useRouter } from './useRouter';
import { useEffect } from 'react';

/**
 * This hooks is used to verify the URL params, since we can't hook into an event from Amplify.
 * @param errorCb Method to be called when an error happens, it will send the string message back that amplify puts in the URL
 */
export const useAuthenticatedRouting = (errorCb: (error: string) => void) => {
  const router = useRouter();

  useEffect(() => {
    if (router.query && router.query.hasOwnProperty('error_description')) {
      errorCb(router.query['error_description']);
    }
  }, [errorCb, router.query]);
};
