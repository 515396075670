import React from 'react';
import { App } from './App';
import { PartnerProvider } from '../partner';

export default function Root() {
  return (
    <PartnerProvider>
      <App />
    </PartnerProvider>
  );
}
