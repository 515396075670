import React, { useCallback, useEffect, useState } from 'react';
import anime from 'animejs';
import * as uuid from 'uuid';

const createUniqueTarget = (classIdentifier, stringId, useHash = false) => {
  return `${useHash ? '#' : ''}${classIdentifier}-${stringId}`;
};

export const ScanFailure = () => {
  const [uniqueHash] = useState(uuid.v4());
  const createUniqueName = useCallback(
    (stringId, useHash = false) => {
      return createUniqueTarget(`a-${uniqueHash}`, stringId, useHash);
    },
    [uniqueHash]
  );

  const getTimeline = useCallback(() => {
    anime
      .timeline({
        targets: `.${createUniqueName('bounce')}`,
        easing: 'easeInOutSine',
        duration: 2500,
        direction: 'alternate',
        autoplay: true,
        loop: true,
        transformOrigin: ['50% 50% 0px', '50% 50% 0px'],
      })
      .add(
        {
          translateY: ['0px', '-10px'],
          easing: 'easeInSine',
          duration: 300,
        },
        0
      )
      .add(
        {
          rotate: [0, -6, 0, 6],
          translateY: ['-10px', '-20px'],
          easing: 'easeOutSine',
          duration: 300,
        },
        300
      );
  }, [createUniqueName]);

  useEffect(() => {
    getTimeline();
  }, [getTimeline]);

  return (
    <svg
      width="240"
      height="194"
      viewBox="0 0 240 194"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id={createUniqueName('Scan')}>
        <g id={createUniqueName('Wifi Unfilled')}>
          <path
            id={createUniqueName('Fill 1')}
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10.7312 97.1069C8.53058 97.1069 6.31468 96.398 4.44542 94.9329C-0.00582999 91.4465 -0.797299 84.9992 2.67842 80.5321C31.1406 43.9576 74.0282 22.9802 120.347 22.9802C166.539 22.9802 209.356 43.8663 237.82 80.2828C241.305 84.7407 240.528 91.1901 236.084 94.6866C231.641 98.1832 225.212 97.4055 221.727 92.9445C197.166 61.5215 160.216 43.5 120.347 43.5C80.3712 43.5 43.3573 61.6005 18.7982 93.161C16.7817 95.7516 13.7723 97.1069 10.7312 97.1069Z"
            fill="#E6E6EB"
          />
          <path
            id={createUniqueName('Fill 4')}
            fillRule="evenodd"
            clipRule="evenodd"
            d="M43.0897 123.081C40.9127 123.081 38.7203 122.387 36.8612 120.953C32.3855 117.497 31.548 111.056 34.992 106.564C55.5191 79.7901 86.6297 64.4341 120.347 64.4341C153.993 64.4341 185.063 79.7377 205.592 106.423C209.044 110.908 208.216 117.352 203.746 120.814C199.277 124.275 192.854 123.447 189.403 118.961C172.776 97.3488 147.606 84.9539 120.347 84.9539C93.03 84.9539 67.8277 97.3909 51.2018 119.076C49.1883 121.703 46.1554 123.081 43.0897 123.081Z"
            fill="#E6E6EB"
          />
          <path
            id={createUniqueName('Fill 6')}
            fillRule="evenodd"
            clipRule="evenodd"
            d="M75.3158 149.257C73.2134 149.257 71.0936 148.608 69.2703 147.265C64.7189 143.91 63.7393 137.488 67.082 132.923C79.4735 115.995 99.3861 105.888 120.347 105.888C141.074 105.888 160.853 115.815 173.256 132.444C176.64 136.98 175.718 143.41 171.197 146.804C166.673 150.201 160.265 149.274 156.883 144.739C148.194 133.089 134.876 126.408 120.347 126.408C105.869 126.408 92.1197 133.384 83.5648 145.069C81.5616 147.807 78.4592 149.257 75.3158 149.257Z"
            fill="#E6E6EB"
          />
        </g>
        <g id={createUniqueName('Fill levels')}>
          <g id={createUniqueName('Medium')}>
            <path
              className={`${createUniqueName('pointer')} pointer`}
              id={createUniqueName('Pointer')}
              fillRule="evenodd"
              clipRule="evenodd"
              d="M120.463 190.913C124.7 190.913 128.132 187.588 128.132 183.486C128.132 180.932 125.844 170.997 121.267 153.68C121.122 153.132 120.676 152.915 120.25 152.915C119.824 152.915 119.377 153.33 119.286 153.693C114.958 171.002 112.794 180.933 112.794 183.486C112.794 187.588 116.228 190.913 120.463 190.913"
              fill="#000000"
            />
          </g>
        </g>
        <path
          className={`${createUniqueName('bounce')} ${createUniqueName(
            'pointer'
          )} pointer`}
          id={createUniqueName('!')}
          d="M126.496 132.275L133.068 37.3384C133.572 30.0557 127.8 23.8706 120.5 23.8706C113.2 23.8706 107.428 30.0557 107.932 37.3384L114.504 132.275C114.722 135.426 117.341 137.871 120.5 137.871C123.659 137.871 126.278 135.426 126.496 132.275Z"
          fill="black"
        />
      </g>
    </svg>
  );
};
