import { prepareKey } from 'react-tweek';
import { Context, createTweekClient } from 'tweek-client';
import { BehaviorSubject } from 'rxjs';
import deepEqual from 'deep-equal';
import { TweekRepository } from 'tweek-local-cache';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const appConfig = require('appConfig');

prepareKey('network_scan/_');

const tweekClient = createTweekClient({
  baseServiceUrl: appConfig.tweekUrl,
});

export const defaultTweekRepository: TweekRepository = new TweekRepository({
  client: tweekClient,
  context: { network_scan: { hostname: window.location.hostname } },
});

const tweekRepositorySubject = new BehaviorSubject<TweekRepository>(
  defaultTweekRepository
);

export const tweekRepository$ = tweekRepositorySubject.asObservable();

// Hacking around the type system to preserve existing context
export const updateTweekContext = (extraContext: {}) => {
  tweekRepositorySubject.value.updateContext((currentContext: Context) => {
    return deepEqual(extraContext, currentContext)
      ? null
      : {
          ...currentContext,
          network_scan: {
            ...(currentContext.network_scan as {}),
            ...extraContext,
          },
        };
  });
};
