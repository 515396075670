import React from 'react';
import { useTranslation } from 'react-i18next';
import { merge, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { useRxApi } from '../../../hooks';
import { LoadingSpinner } from '../../../icons';
import { homeGraphApi } from '../../../page-api';
import { IExpertTip } from '../../../services';
import { H1 } from '../../CommonComponents';
import { ScanComponent, withLoadingSkeleton } from '../index';
import { TipWrapper } from './Tip';

interface NetworkDiagnosisWrapperProps {
  isLoading?: boolean;
}

export const NetworkDiagnosisWrapper = ({
  isLoading,
}: NetworkDiagnosisWrapperProps) => {
  const { result: expertTips } = useRxApi(homeGraphApi.expertTips$, {
    initialValue: [],
  });

  const hasResults$ = homeGraphApi.expertTips$.pipe(
    map((result) => !!result && result.length > 0)
  );

  const isFirstLoadFinished$ = merge(hasResults$, of(!isLoading));

  return (
    <ScanComponent className="network-diagnosis">
      <NetworkDiagnosisHeading
        isLoading={isLoading}
        totalTips={expertTips.length}
      />
      {withLoadingSkeleton(
        <NetworkDiagnosis expertTips={expertTips} />,
        isFirstLoadFinished$
      )}
    </ScanComponent>
  );
};

interface NetworkDiagnosisProps {
  expertTips: IExpertTip[];
}

export const NetworkDiagnosis = ({ expertTips }: NetworkDiagnosisProps) => {
  const { t: translateText } = useTranslation();

  if (expertTips.length > 0) {
    return (
      <div>
        {expertTips.map((expertTip) => (
          <TipWrapper expertTip={expertTip} key={expertTip.description} />
        ))}
      </div>
    );
  } else {
    return (
      <div className="no-network-issues">
        {translateText('no_issues_were_found')}.
      </div>
    );
  }
};

interface NetworkDiagnosisHeadingProps {
  isLoading?: boolean;
  totalTips: number;
}

export const NetworkDiagnosisHeading = ({
  isLoading,
  totalTips,
}: NetworkDiagnosisHeadingProps) => {
  const { t: translateText } = useTranslation();

  return (
    <H1 className="scan-subheading">
      {translateText('network_diagnosis')}
      <span className="issues-found">
        {totalTips} {`${translateText('issue')}${totalTips > 0 ? 's' : ''}`}
      </span>
      {isLoading && <LoadingSpinner />}
    </H1>
  );
};
