import React, { SVGProps } from 'react';

export const AsurionLogo = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="154"
      height="40"
      viewBox="0 0 117 28"
      className="asurion-logo"
      {...props}
    >
      <path
        fill="#000"
        fillRule="nonzero"
        d="M50.211 8.136v10.573c0 2.816-1.434 4.835-4.197 4.835s-4.145-1.434-4.145-4.835V8.136h-4.197v11.477c0 5.1 2.603 7.863 7.332 7.863 2.657 0 4.357-1.275 5.26-2.39v2.178h4.198V8.136h-4.25zm37.46-.213c-5.42 0-9.778 4.41-9.778 9.777 0 5.42 4.411 9.776 9.777 9.776 5.42 0 9.777-4.41 9.777-9.776.053-5.42-4.357-9.777-9.777-9.777zm5.632 9.777a5.579 5.579 0 1 1-11.159 0 5.579 5.579 0 1 1 11.159 0zM67.905 7.923c-1.541 0-3.826.32-5.58 2.232v-2.02h-4.197v19.129h4.198V16.212a4.093 4.093 0 0 1 4.09-4.091c.532 0 1.01.106 1.489.265V7.923zM33.262 25.617c-1.223 1.222-2.816 1.86-4.57 1.86-1.7 0-3.347-.691-4.57-1.86-1.221-1.222-1.86-2.816-1.86-4.57h3.986c0 .638.266 1.275.69 1.7.479.479 1.063.691 1.7.691.639 0 1.276-.265 1.701-.69a2.422 2.422 0 0 0 .053-3.401c-.584-.478-1.01-.531-1.753-.69-1.01-.213-2.338-.479-3.72-1.86-2.018-2.02-2.018-5.367 0-7.386 2.02-2.019 5.367-2.019 7.386 0 .213.213.372.425.585.69l-2.657 2.657c-.425-.478-.797-.743-1.328-.797-.425-.106-.85 0-1.116.32-.213.212-.372.53-.372.85 0 .318.106.637.372.85.531.53.903.637 1.7.743.903.16 2.179.425 3.613 1.647l.106.107c2.55 2.55 2.55 6.641.054 9.139zm-17.906 1.647h4.197V17.7c0-5.42-4.41-9.777-9.776-9.777C4.41 7.923 0 12.28 0 17.7c0 5.42 4.41 9.776 9.777 9.776 1.86 0 3.931-.531 5.579-2.39v2.178zm-5.58-3.985a5.578 5.578 0 0 1-5.578-5.58 5.578 5.578 0 0 1 5.579-5.578 5.578 5.578 0 0 1 5.579 5.579 5.578 5.578 0 0 1-5.58 5.579zm98.829-11.158a4.173 4.173 0 0 0-4.197 4.197v10.946h-4.198V16.318c0-4.622 3.772-8.395 8.395-8.395 4.622 0 8.395 3.773 8.395 8.395v10.946h-4.198V16.318a4.173 4.173 0 0 0-4.197-4.197zM70.986 8.136h4.198v19.128h-4.198V8.136zm4.942-4.995a2.857 2.857 0 0 1-2.87 2.87 2.857 2.857 0 0 1-2.869-2.87A2.857 2.857 0 0 1 73.06.272a2.857 2.857 0 0 1 2.869 2.87z"
      />
    </svg>
  );
};
