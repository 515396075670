import React, { SVGProps } from 'react';

export const Speaker = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="26px"
      height="35px"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 26 35"
      {...props}
    >
      <path
        fill="#9100DA"
        fillRule="evenodd"
        d="M23.947 0H2.053C.889 0 0 .89 0 2.053v30.105c0 1.163.89 2.053 2.053 2.053h21.894c1.164 0 2.053-.89 2.053-2.053V2.053C26 .889 25.11 0 23.947 0zm.685 32.158c0 .41-.274.684-.685.684H2.053a.686.686 0 0 1-.685-.684V2.053c0-.41.274-.685.685-.685h21.894c.41 0 .685.274.685.685v30.105zM13 14.368c-4.516 0-8.21 3.695-8.21 8.21 0 4.517 3.694 8.211 8.21 8.211 4.516 0 8.21-3.694 8.21-8.21 0-4.516-3.694-8.21-8.21-8.21zm0 15.053c-3.763 0-6.842-3.079-6.842-6.842 0-3.763 3.079-6.842 6.842-6.842 3.763 0 6.842 3.079 6.842 6.842 0 3.763-3.079 6.842-6.842 6.842zm5.816-26.684a4.439 4.439 0 0 0-4.448 4.447 4.439 4.439 0 0 0 4.448 4.448 4.439 4.439 0 0 0 4.447-4.448 4.439 4.439 0 0 0-4.447-4.447zm0 7.526a3.066 3.066 0 0 1-3.08-3.079 3.066 3.066 0 0 1 3.08-3.079 3.066 3.066 0 0 1 3.079 3.08 3.066 3.066 0 0 1-3.08 3.078z"
      />
    </svg>
  );
};
