import React, { SVGProps } from 'react';

export const SmokeDetector = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="31px"
      height="31px"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 31 31"
      {...props}
    >
      <path
        fill="#9100DA"
        fillRule="evenodd"
        d="M14 26.582l-7-4.848V9.265l7-4.848v22.165zm-9-7.758c0 .613-.448 1.109-1 1.109H3c-.552 0-1-.496-1-1.109v-6.65c0-.611.448-1.108 1-1.108h1c.552 0 1 .497 1 1.109v6.65zM14 2.2L5 8.85H2c-1.104 0-2 .993-2 2.216v8.867c0 1.223.896 2.216 2 2.216h3l9 6.65c1.104 0 2-.993 2-2.217V4.417c0-1.224-.896-2.217-2-2.217zm11 13.3c0-3.905-2.612-7.105-6-7.647v2.217c2.282.513 4 2.748 4 5.43 0 2.68-1.718 4.916-4 5.429v2.217c3.388-.542 6-3.742 6-7.647zM19 0v2.275C24.872 3.53 29 8.836 29 15.5c0 6.626-4 11.758-10 13.225V31c6.776-1.1 12-7.584 12-15.5C31 7.582 25.776 1.097 19 0z"
      />
    </svg>
  );
};
