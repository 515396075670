import React from 'react';
import { default as ReactFullStory } from 'react-fullstory';
import { fullStoryConstants } from './constants';
import {
  StoredQueryParams,
  useComponentWillMount,
  useSessionStorage,
} from '../../hooks';
import { setIdentityForFullStory } from './fullstory-utils';
import { isProduction } from '../utils';

export const FullStory = () => {
  const [params] = useSessionStorage<StoredQueryParams>('queryParams');
  useComponentWillMount(() => {
    if (params?.clientId) {
      setIdentityForFullStory(params.clientId, params.socoSupportSessionId);
    }
  });

  return isProduction() ? (
    <ReactFullStory org={fullStoryConstants.orgKey} />
  ) : null;
};
