import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRxApi } from '../../../hooks';
import { homeGraphApi } from '../../../page-api';
import { ThroughputComponent } from './ThroughputComponent';

export const NoData = 'N/A';

export const UploadDownloadSpeedsWrapper = () => {
  const { result: internetHealth } = useRxApi(homeGraphApi.internetHealth$, {
    initialValue: {},
  });

  const { downloadThroughput, uploadThroughput } = internetHealth;

  return (
    <UploadDownloadSpeeds
      downloadThroughput={downloadThroughput}
      uploadThroughput={uploadThroughput}
    />
  );
};

interface UploadDownloadSpeedsProps {
  downloadThroughput: string | null | undefined;
  uploadThroughput: string | null | undefined;
}

export const UploadDownloadSpeeds = ({
  downloadThroughput,
  uploadThroughput,
}: UploadDownloadSpeedsProps) => {
  const { t: translate } = useTranslation();

  const uploadDownloadWrapperStyle = {
    display: 'flex',
    flexFlow: 'row wrap',
  };

  return (
    <div className="upload-download-speeds">
      <ThroughputComponent
        uploadOrDownload="download"
        throughputSpeed={downloadThroughput}
      />
      <ThroughputComponent
        uploadOrDownload="upload"
        throughputSpeed={uploadThroughput}
      />
    </div>
  );
};
