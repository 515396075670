import React, { SVGProps } from 'react';

export const getWifiClassStrengthByRssi = (
  rssi: number | undefined
): string | null => {
  if (!rssi) return '';
  if (rssi > 0.83) return 'best-wifi';
  else if (rssi >= 0.66) return 'better-wifi';
  else if (rssi >= 0.33) return 'average-wifi';
  else if (rssi >= 0.01) return 'weak-wifi';
  else return '';
};

interface WifiGrayProps extends SVGProps<SVGSVGElement> {
  /**
   * rssi stands for Received Signal Strength Indicator
   * For more reference you can view this link. https://networkengineering.stackexchange.com/a/1676
   *
   * @type {number}
   * @memberof WifiGrayProps
   */
  rssi?: number;
}

export const WifiGray = ({ rssi, className, ...props }: WifiGrayProps) => {
  const classes = [
    'wifi-gray',
    className,
    getWifiClassStrengthByRssi(rssi),
  ].join(' ');
  return (
    <svg
      className={classes}
      width="26"
      height="20"
      viewBox="0 0 26 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.1258 6.155C21.2592 3.37833 16.8968 1.69083 12.5756 1.69083C8.25432 1.69083 3.91797 3.40417 1.05055 6.18083L0 4.9775C3.27825 1.89417 7.70264 0 12.5756 0C17.4485 0 21.8721 1.895 25.1503 4.97833L24.1258 6.155V6.155Z"
        fill="var(--color-top-wave)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.5756 4.99915C16.0719 4.99915 19.408 6.48248 21.6525 8.80248L20.5575 9.97665C18.7373 7.95498 15.5219 6.67498 12.5748 6.67498C9.62773 6.67498 6.41236 7.95498 4.59213 9.97665L3.49799 8.80248C5.74246 6.48248 9.0794 4.99915 12.5756 4.99915V4.99915Z"
        fill="var(--color-middle-wave)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.5756 10C14.5425 10.0042 17.0486 10.9775 18.2039 12.5667L17.1525 13.7259C16.2848 12.5525 14.1552 11.6592 12.5756 11.6592C10.996 11.6592 8.89235 12.5525 8.02374 13.7259L6.9212 12.5667C8.07656 10.9784 10.6598 9.99337 12.5756 10V10Z"
        fill="var(--color-bottom-wave)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.5756 15.0033C13.9657 15.0033 15.0926 16.1216 15.0926 17.5016C15.0926 18.88 13.9657 20 12.5756 20C11.1855 20 10.0587 18.88 10.0587 17.5016C10.0587 16.1216 11.1855 15.0033 12.5756 15.0033V15.0033ZM12.5756 16.6666C13.0384 16.6666 13.414 17.0391 13.414 17.5C13.414 17.9608 13.0384 18.3333 12.5756 18.3333C12.112 18.3333 11.7372 17.9608 11.7372 17.5C11.7372 17.0391 12.112 16.6666 12.5756 16.6666V16.6666Z"
        fill="var(--color-base-circle)"
      />
    </svg>
  );
};
