import React from 'react';
import { useTranslation } from 'react-i18next';
import { LanguageSelector, ThemeToggle } from '../../CommonComponents';
import { AsurionLogo } from '../../../icons';
import { User } from '../../../sso';

export const HeaderV2 = () => {
  const { t: translateText } = useTranslation();
  const isDevEnv = false;
  return (
    <div className="static-header v2">
      <div className="left-container">
        <AsurionLogo />
        <span className="dashboard-name">
          {translateText('expert_dashboard')}
        </span>
      </div>
      <div className="right-container">
        {isDevEnv && <LanguageSelector />}
        <ThemeToggle />
        <User />
      </div>
    </div>
  );
};
