import { logger } from '@home-mgmt-shared/logger';
import React, { useState } from 'react';
import { first } from 'rxjs/operators';
import {
  BaseButton,
  Header,
  PinContentContainer,
  RoutePaths,
  WarningBanner,
} from '../components';
import { DisplayString } from '../constants';
import { StoredQueryParams, useRouter, useSessionStorage } from '../hooks';
import { ArrowHorizontalLeft } from '../icons';
import { networkScanApi } from '../services';
import { UserAnalyticEvent } from '../utils';

export const PinPage = () => {
  const router = useRouter();

  const [params, setParams] = useSessionStorage<StoredQueryParams>(
    'queryParams'
  );

  const [displayWarning, setDisplayWarning] = useState(false);

  const closeWarningBanner = () => {
    setDisplayWarning(false);
  };

  const onBackButtonClick = () => {
    router.history.goBack();
  };

  const getIdByPin = (pinNumber: number) => {
    setDisplayWarning(false);
    networkScanApi
      .getDataByPin(pinNumber)
      .pipe(first())
      .subscribe({
        next: (pinResponse) => {
          setParams({ ...params, clientId: pinResponse?.id });
          router.push({
            pathname: RoutePaths.Scanning,
            search: `?clientId=${pinResponse?.id}`,
          });
        },
        error: (e) => {
          logger.error('Error in retrieving clientId by Pin', e);
          setDisplayWarning(true);
        },
      });
  };

  return (
    <div className="pin-page">
      <Header />
      <div className="container back-button-container">
        <BaseButton
          customStyle="invert-button"
          onClick={onBackButtonClick}
          analyticName={UserAnalyticEvent.EnterPinBackButton}
        >
          <span>
            <ArrowHorizontalLeft className="back-arrow path-fill-white" />
            <span>Back</span>
          </span>
        </BaseButton>
      </div>
      {displayWarning
        ? WarningBanner({
            parentClass: 'pin-page',
            displayMessage: DisplayString.SomethingWentWrong,
            onClick: closeWarningBanner,
          })
        : ''}
      <PinContentContainer onPinSubmit={getIdByPin} />
    </div>
  );
};
