import React, { SVGProps } from 'react';

export const PoorCheckIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20Z"
        fill="#EDB800"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.8332 4.16669H9.16651V5.83335H10.8332V4.16669ZM10.8332 7.5H9.1665V15H10.8332V7.5Z"
        fill="white"
      />
    </svg>
  );
};
