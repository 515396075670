import React from 'react';
import { useTranslation } from 'react-i18next';
import { merge, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { useRxApi } from '../../../hooks';
import { homeGraphApi } from '../../../page-api';
import { H1 } from '../../index';
import { ScanComponent, withLoadingSkeleton } from '../index';
import {
  AmazonLogo,
  AttTvLogo,
  FacebookLogo,
  GoogleLogo,
  HboMaxLogo,
  HuluLogo,
  NetflixLogo,
  YoutubeLogo,
} from './internet-services-logos';
import { BadCheckIcon, CheckIcon, PoorCheckIcon } from './streaming-icons';

const logoMap: Record<string, React.FC<any>> = {
  amazon: AmazonLogo,
  facebook: FacebookLogo,
  google: GoogleLogo,
  hulu: HuluLogo,
  netflix: NetflixLogo,
  youtube: YoutubeLogo,
  hbo: HboMaxLogo,
  'play.hbomax': HboMaxLogo,
  'att.tv': AttTvLogo,
};

export const getLogoElement = (logoName: string): JSX.Element => {
  const Element: any | null = logoMap[logoName];
  if (Element) return <Element />;
  return <p className="text">{logoName}</p>;
};

interface InternetServicesV2Props {
  isLoading?: boolean;
}

export const InternetServicesV2 = ({ isLoading }: InternetServicesV2Props) => {
  const { result: services } = useRxApi(homeGraphApi.siteScores$, {
    initialValue: {},
  });

  const hasResults$ = homeGraphApi.siteScores$.pipe(
    map((result) => !!result && Object.keys(result).length > 0)
  );

  const isFirstLoadFinished$ = merge(hasResults$, of(!isLoading));

  return (
    <ScanComponent className="internet-services-V2">
      <InternetServicesHeading />
      {withLoadingSkeleton(
        <InternetServices services={services} />,
        isFirstLoadFinished$
      )}
    </ScanComponent>
  );
};

export const InternetServices = ({ services }: { services: object }) => {
  const getCheckIcon = (value: number) => {
    if (value > 1) {
      return <CheckIcon />;
    } else if (value == 1) {
      return <PoorCheckIcon />;
    } else {
      return <BadCheckIcon />;
    }
  };

  return (
    <div className="internet-services-column">
      {Object.keys(services).map((value, index) => {
        const ServiceIcon = getLogoElement(
          value.replace('www.', '').replace('.com', '')
        );

        return (
          <div className="holder" key={index}>
            {getCheckIcon(+services[value])}
            {ServiceIcon}
          </div>
        );
      })}
    </div>
  );
};

export const InternetServicesHeading = () => {
  const { t } = useTranslation();
  return <H1 className="scan-subheading">{t('status_of_popular_service')}</H1>;
};
