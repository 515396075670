import { ContentfulClientApi, createClient, EntryCollection } from 'contentful';
import { from, Observable, of } from 'rxjs';
import { ContentfulGuideName, ContentfulModel } from '../enums';
import { ContainerFields, IContentfulExpertTip } from '../contracts';
import { flatMap, map } from 'rxjs/operators';
import { BaseContentHandler } from './BaseContentHandler';
import { TransformerHandler } from '../transformer';
import { PartnerKeys } from '../../../constants';

const appConfig = require('appConfig');

export class ContentHandler extends BaseContentHandler {
  private client: ContentfulClientApi;

  constructor() {
    super(new TransformerHandler());

    this.client = createClient({
      space: appConfig.contentfulSpaceId,
      accessToken: appConfig.contentfulAccessToken,
    });
  }

  /**
   * A method to get a list full of expert tips defaulted to the all partner config.
   *
   * @param {PartnerKeys} The name of the partner to get tips for
   * @returns {Observable<Record<string, IContentfulExpertTip>>}
   * @memberof ContentHandler
   */
  public getExpertTips(
    partner: PartnerKeys
  ): Observable<Record<string, IContentfulExpertTip>> {
    return this.getEntries<ContainerFields<IContentfulExpertTip>>(
      partner,
      ContentfulGuideName.ExpertTips
    ).pipe(
      // * If no items come back, check to see the `ALL` key to keep the content coming from the same place.
      // * This will save us from one partner having implemented tips and one not.
      flatMap((response) =>
        response.items.length === 0
          ? this.getEntries<ContainerFields<IContentfulExpertTip>>(
              PartnerKeys.all,
              ContentfulGuideName.ExpertTips
            )
          : of(response)
      ),
      // * convert collection that contains only ExpertTips to an object with
      // * tipId as the key and then the object as the value.
      map((entryCollection) => {
        return this.transformContentInContainer<IContentfulExpertTip>(
          entryCollection
        ).reduce((accumulator, value) => {
          value.content.forEach((tip) => {
            accumulator[tip.id] = tip;
          });
          return accumulator;
        }, {});
      })
    );
  }

  /**
   * Convenience handler around getting a collection of entries back
   *
   * @private
   * @template TContentfulData What format you expect the data to be in
   * @param {PartnerKeys} partner The partner you want to retrieve data for
   * @param {ContentfulGuideName} guideName The name of the guideName for your query.
   * @returns {Observable<EntryCollection<TContentfulData>>}
   * @memberof ContentHandler
   */
  private getEntries<TContentfulData>(
    partner: PartnerKeys,
    guideName: ContentfulGuideName
  ): Observable<EntryCollection<TContentfulData>> {
    return from(
      this.client.getEntries<TContentfulData>(
        this.getContentQuery(partner, guideName)
      )
    );
  }

  /**
   * A base contentful query.
   * ! This will assume all things you want to retrieve are of the type Container
   *
   * @private
   * @param {string} partnerName The partner name to retrieve
   * @param {ContentfulGuideName} guideName Guide name from contentful
   * @returns {Record<string, string | number>}
   * @memberof ContentHandler
   */
  private getContentQuery(
    partnerName: string,
    guideName: ContentfulGuideName
  ): Record<string, string | number> {
    return {
      content_type: ContentfulModel.Container,
      include: 10,
      'fields.tag.sys.contentType.sys.id': ContentfulModel.Tag,
      'fields.tag.fields.guideName': guideName,
      'fields.tag.fields.partner': partnerName,
    };
  }
}
