import React, { useState } from 'react';
import i18n from '../../i18n/i18n';
import { DisplayString } from '../../constants';

export const LanguageSelector = () => {
  const [lang, setLang] = useState(
    localStorage.getItem('asurion-dashboard-language') || 'en'
  );

  const handleOptionClick = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const targetValue = event.target.value;
    i18n.changeLanguage(targetValue);
    localStorage.setItem('asurion-dashboard-language', targetValue);
    setLang(targetValue);
  };

  return (
    <div className="language-selector">
      <select
        className="form-control"
        onChange={handleOptionClick}
        value={lang}
      >
        <option value="en">{DisplayString.English}</option>
        <option value="es">{DisplayString.Spanish}</option>
      </select>
    </div>
  );
};
