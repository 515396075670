import { LogLevel } from './logLevels';
import { logToConsole } from './logConsole';
import { logToLoggingApi } from './loggingApi';
/**
 * support values are: error, warn, info, debug
 * @memberof logging
 * @enum {number}
 */
export const LOG_LEVELS: { [key in LogLevel]: number } = {
  error: 1,
  warn: 2,
  info: 3,
  debug: 4,
};

const _maxLogLevel: LogLevel = 'info';

export const logHandler = (
  levelName: LogLevel,
  message: string,
  error: any,
  extraData: any
) => {
  const _loggers = [logToConsole, logToLoggingApi];
  try {
    if (LOG_LEVELS[_maxLogLevel] < LOG_LEVELS[levelName]) {
      return Promise.resolve();
    }
    return Promise.all(
      _loggers.map((log) => log(levelName, message, error, extraData))
    );
  } catch (err) {
    console.warn('failed to log', err.message);
    return;
  }
};
