import { EntryCollection } from 'contentful';
import { ContainerFields } from '../contracts';
import { ContentfulModel } from '../enums';
import { TransformerHandler } from '../transformer';

export class BaseContentHandler {
  private transformerHandler: TransformerHandler;

  constructor(transformerHandler: TransformerHandler) {
    this.transformerHandler = transformerHandler;
  }

  protected transformContentInContainer<TContentType = any>(
    content: EntryCollection<ContainerFields<TContentType>>
  ): ContainerFields<TContentType>[] {
    return content.items.map((item) => {
      const { fields } = item;
      return {
        contentfulDisplayName: fields.contentfulDisplayName,
        content: this.mapContentTypeToItems(fields.content),
      };
    });
  }

  /**
   * Takes a type and will attempt to parse out the data.
   *
   * @protected
   * @param {any[]} item
   * @returns {(Record<string, string> | undefined)}
   * @memberof BaseContentHandler
   */
  protected mapContentTypeToItems(items: any[]): any[] {
    return items.map((item) => {
      const { contentType } = item.sys;
      const contentTypeName = contentType.sys.id as ContentfulModel;
      const content = item.fields;
      return this.transformerHandler.transformContent(contentTypeName, content);
    });
  }
}
