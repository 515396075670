import React, { useMemo } from 'react';
import { InitiateScanButton, RoutePaths } from '../../components';
import { useRouter } from '../../hooks';
import { of } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { InitiateScanButtonProps } from '../contracts';

export const AttInitiateScanButton = ({
  clientId,
  buttonText,
  variant,
}: InitiateScanButtonProps) => {
  const router = useRouter();
  const triggerScanAction$ = useMemo(
    () =>
      of({}).pipe(
        finalize(() => router.push({ pathname: RoutePaths.ScanInstructions }))
      ),
    [router]
  );
  return (
    <InitiateScanButton
      buttonText={buttonText}
      triggerScan$={triggerScanAction$}
      clientId={clientId}
      variant={variant}
    />
  );
};
