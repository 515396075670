import { DashboardEventType, UserAnalyticEvent } from './analytics-constants';

export abstract class BaseAnalyticWriter {
  protected setupEventSpecificProps(
    eventId: UserAnalyticEvent,
    eventType: DashboardEventType
  ): object {
    switch (eventType) {
      case DashboardEventType.Click:
        return {
          action: 'click',
          clickId: eventId,
        };
      case DashboardEventType.View:
        return {
          action: 'page',
          //pageId is set in the `useRouter`
        };
      case DashboardEventType.Error:
        return {
          action: 'error',
          errorId: eventId,
        };
      default:
        return {
          action: 'unknownEvent',
          eventId: eventId,
        };
    }
  }
}
