import React, { DetailedHTMLProps, HTMLAttributes } from 'react';
import Auth from '../auth';
export const Logout = (
  props: DetailedHTMLProps<HTMLAttributes<HTMLSpanElement>, HTMLSpanElement>
) => {
  const onLogoutClick = () => {
    Auth.signOut();
  };
  return (
    <span {...props} onClick={onLogoutClick}>
      Logout
    </span>
  );
};
