import React, {
  createContext,
  Dispatch,
  FunctionComponent,
  SetStateAction,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { DTIdentifyUser } from '../utils/dynatrace';
import { analytics } from '../utils';
export type AuthenticationContent = {
  isLoggedIn: boolean;
  user?: any;
  userAuthorizedPartners: string[];
  setUser: Dispatch<SetStateAction<any | undefined>>;
  setUserAuthorizedPartners: Dispatch<SetStateAction<string[]>>;
};
export const AuthenticationContext = createContext<AuthenticationContent>({
  isLoggedIn: false,
  userAuthorizedPartners: [],
  setUserAuthorizedPartners: () => {},
  setUser: () => {},
});
AuthenticationContext.displayName = 'Authentication';
export const useAuthentication = () => useContext(AuthenticationContext);

export const AuthenticationProvider: FunctionComponent = ({ children }) => {
  const [user, setUser] = useState<any>();
  const [userAuthorizedPartners, setUserAuthorizedPartners] = useState<
    string[]
  >([]);
  const value = useMemo(
    (): AuthenticationContent => ({
      isLoggedIn: Boolean(user),
      user,
      setUser,
      userAuthorizedPartners,
      setUserAuthorizedPartners,
    }),
    [userAuthorizedPartners, user]
  );

  useEffect(() => {
    const email = user?.attributes?.email ?? '';
    if (email) {
      DTIdentifyUser(email);
      analytics.updateProperties({ expertId: email });
    }
  }, [user]);

  return (
    <AuthenticationContext.Provider value={value}>
      {children}
    </AuthenticationContext.Provider>
  );
};
