import React from 'react';
import { useTranslation } from 'react-i18next';
import { ArrowDown } from '../../../icons/ArrowDown';
import { ArrowUp } from '../../../icons/ArrowUp';
import { noValueFormatter } from '../../../utils';

const arrowImg = require('../../../img/default/arrow.png');
const arrowImgWhite = require('../../../img/default/arrow-white.png');

interface ThroughputComponentProps {
  uploadOrDownload: string;
  throughputSpeed: string | null | undefined;
}

export const ThroughputComponent = ({
  uploadOrDownload,
  throughputSpeed,
}: ThroughputComponentProps) => {
  const { t: translate } = useTranslation();

  const wrapperStyle = {
    display: 'grid',
    gridTemplateAreas: `'arrow title title' 'arrow throughput .' 'arrow throughput unit'`,
    gridAutoColumns: 'min-content',
    alignItems: 'end',
  };

  const arrowStyle = {
    gridArea: 'arrow',
    marginRight: '5px',
    width: '20px',
    height: '50px',
  };

  const titleStyle = {
    gridArea: 'title',
    margin: '0',
    lineHeight: '1',
    fontSize: `${7 / 8}em`,
  };

  const throughputStyle = {
    gridArea: 'throughput',
    fontSize: '2.5em',
    margin: '0',
    lineHeight: '1',
  };

  const unitStyle = {
    gridArea: 'unit',
    margin: '0',
    lineHeight: '1',
    marginLeft: '5px',
    fontSize: `${7 / 8}em`,
    color: 'var(--dark-grey)',
  };

  const Arrow = () =>
    uploadOrDownload === 'upload' ? (
      <ArrowUp className="upload-download-arrow" style={arrowStyle} />
    ) : (
      <ArrowDown className="upload-download-arrow" style={arrowStyle} />
    );

  return (
    <div style={wrapperStyle}>
      <Arrow />
      <p style={titleStyle}>{translate(uploadOrDownload)}</p>
      <p style={throughputStyle}>
        {noValueFormatter(!!throughputSpeed ? throughputSpeed : null)}
      </p>
      <p style={unitStyle}>{translate('mbps')}</p>
    </div>
  );
};
