import { isProduction } from '../utils';
import { Analytics } from './Analytics';
import { AnalyticsInterface } from './analytic.interface';
import { consoleAnalyticWriter } from './consoleWriter';
import { networkScanAnalyticWriter } from './NetworkScanAnalyticWriter';

//hook in new analytic writers here
const writers: AnalyticsInterface[] = [networkScanAnalyticWriter];

if (!isProduction()) {
  writers.push(consoleAnalyticWriter);
}
export const analytics = new Analytics(...writers);
