export const getUserDisplayName = (user: any | undefined): string | null => {
  if (!user?.attributes) return null;
  const { attributes } = user;
  const { family_name, given_name } = attributes;
  return family_name && given_name ? `${family_name}, ${given_name}` : null;
};

export const getUserEmail = (user: any | undefined): string | null => {
  if (!user?.attributes) return '';
  const { attributes } = user;
  const { email } = attributes;
  return email ? `${email}` : null;
};
