import { BehaviorSubject } from 'rxjs';
import {
  IClassifiedDevice,
  IHomeGraph,
  NullishString,
  RecordStringBooleans,
  UnknownArray,
  UnknownObject,
} from '../services';

type ValueOf<T> = T[keyof T];

type HomeGraphRecord$ = Record<
  keyof IHomeGraph,
  BehaviorSubject<ValueOf<IHomeGraph>>
>;

export class HomeGraphApiCache {
  /**
   * * A HomeGraph comes with no identifier, so they're keyed here by sessionId
   */
  protected sessionId: string | null = null;

  private homeGraphKeyMap: Record<string, IHomeGraph> = {};

  /**
   * Function to either return you a cached record or create a new one and return it
   *
   * @protected
   * @param {string} sessionId
   * @returns {IHomeGraph}
   * @memberof HomeGraphApiCache
   */
  protected getCachedData(sessionId: string): IHomeGraph {
    this.sessionId = sessionId;
    const cachedHomeGraphData = this.homeGraphKeyMap[sessionId];
    if (cachedHomeGraphData) return cachedHomeGraphData;
    return (this.homeGraphKeyMap[sessionId] = this.getNewStubHomeGraph());
  }

  /**
   * Sets a record into the cache for a given sessionId
   *
   * @protected
   * @param {string} sessionId
   * @param {IHomeGraph} cacheData
   * @memberof HomeGraphApiCache
   */
  protected setCachedData(sessionId: string, cacheData: IHomeGraph): void {
    this.homeGraphKeyMap[sessionId] = cacheData;
  }

  /**
   * Returns a template homegraph response but each of the values are BehaviorSubjects
   *
   * @private
   * @returns {HomeGraphRecord$}
   * @memberof HomeGraphApiCache
   */
  protected getNewHomeGraphRecord$(): HomeGraphRecord$ {
    return {
      appTips: new BehaviorSubject<UnknownArray>([]),
      broadbandParameters: new BehaviorSubject<UnknownObject>({}),
      capabilities: new BehaviorSubject<RecordStringBooleans>({}),
      devicesClassified: new BehaviorSubject<IClassifiedDevice[]>([]),
      dnsResolver: new BehaviorSubject<NullishString>(undefined),
      expertTips: new BehaviorSubject<UnknownArray>([]),
      internetHealth: new BehaviorSubject<UnknownObject>({}),
      ispData: new BehaviorSubject<NullishString>(undefined),
      siteScores: new BehaviorSubject<UnknownObject>({}),
    };
  }

  /**
   * Returns a template homegraph response
   *
   * @private
   * @returns {HomeGraphRecord}
   * @memberof HomeGraphApiCache
   */
  protected getNewStubHomeGraph(): IHomeGraph {
    return {
      appTips: [],
      broadbandParameters: {},
      capabilities: {},
      devicesClassified: [],
      dnsResolver: undefined,
      expertTips: [],
      internetHealth: {},
      ispData: undefined,
      siteScores: {},
    };
  }
}
