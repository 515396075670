import * as Bowser from 'bowser';

const browser = Bowser.getParser(window.navigator.userAgent);
let browserInfo = {};

export const getBrowserInfo = (): Record<string, string> => {
  return browserInfo;
};

export const setBrowserInfo = (): void => {
  browserInfo = {
    browserName: browser.getBrowserName(),
    browserVersion: browser.getBrowserVersion(),
    operatingSystem: browser.getOS().name,
    operatingSystemVersion: browser.getOSVersion(),
    platformType: browser.getPlatformType(),
  };
};

setBrowserInfo();
