import React from 'react';
import { Header, NoData } from '../components';
import { useRouter } from '../hooks';

export interface NoMatchingRouteState {
  message: string;
}

export const NoMatchingRoutePage = () => {
  const router = useRouter<NoMatchingRouteState>();
  const { message } = router.location.state;
  return (
    <div className="parent-container">
      <Header />
      <NoData msg={message} />
    </div>
  );
};
