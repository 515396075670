import { Button } from '@npmsoluto/atomic-ui-library-react';

import React, {
  ButtonHTMLAttributes,
  DetailedHTMLProps,
  ReactNode,
  useCallback,
} from 'react';

import {
  analytics,
  DashboardEventType,
  UserAnalyticEvent,
} from '../../../utils';

export type ButtonVariant = 'primary' | 'secondary' | 'primary-ghost';

export interface IBaseButtonProps
  extends DetailedHTMLProps<
    ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  children: ReactNode;
  onClick?: () => void;
  customStyle?: string;
  analyticName: UserAnalyticEvent;
  variant?: 'primary' | 'secondary' | 'primary-ghost';
}

export const BaseButton = ({
  children,
  variant = 'primary',
  onClick,
  disabled = undefined,
  className,
  customStyle,
  analyticName,
  ...props
}: IBaseButtonProps) => {
  const buttonClassName = [variant, customStyle, className, 'base-button'].join(
    ' '
  );

  const onClickAnalytics = useCallback(() => {
    analytics.dispatch(analyticName, DashboardEventType.Click);
    if (onClick) onClick();
  }, [analyticName, onClick]);

  return (
    <>
      <Button
        disabled={disabled}
        class={buttonClassName}
        onClick={onClickAnalytics}
        {...props}
      >
        {children}
      </Button>
    </>
  );
};
