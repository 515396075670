import React, { useCallback } from 'react';

interface IMGProps extends React.ImgHTMLAttributes<HTMLImageElement> {
  onImgClick?: () => void;
  size?: number;
  height?: string | number;
  width?: string | number;
  // ! Allows for any but should probably add them into the above as used for tracking
  [key: string]: any;
}

export const BaseImage = ({
  onImgClick,
  size,
  width,
  height,
  alt,
  ...rest
}: IMGProps) => {
  if (size) {
    width = `${1.5 * size}px`;
    height = `${1.5 * size}px`;
  }
  const onImageClick = useCallback(() => {
    onImgClick?.();
  }, [onImgClick]);
  return (
    <img
      onClick={onImageClick}
      width={width}
      height={height}
      {...rest}
      alt={alt}
    />
  );
};
